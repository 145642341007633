import React from "react";

const { useLocation } = require("react-router-dom");

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export { useQuery }