import React, { forwardRef, useEffect } from "react";
import classNames from "classnames";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  CardFooter,
  Label
} from "reactstrap";
import api from "../../../../api/index";
import { useRouteMatch } from "react-router-dom";
import cep from "cep-promise";
import InputMask from "react-input-mask";

const EditPartner = forwardRef((props, ref) => {
  const [corporateName, setCorporateName] = React.useState("");
  const [fantasyName, setFantasyName] = React.useState("");
  const [documentNumber, setDocumentNumber] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [complement, setComplement] = React.useState("");
  const [neighborhood, setNeighborhood] = React.useState("");
  const [state, setState] = React.useState("");
  const [city, setCity] = React.useState("");

  const { params } = useRouteMatch()

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return true;
    },
    state: {
      corporateName,
      fantasyName,
      documentNumber,
      phoneNumber,
      website,
      zipCode,
      street,
      number,
      complement,
      neighborhood,
      state,
      city,
    },
  }));


  const getData = async () => {
    const { data } = await api.get(`/partners/${params.partnerId}`);
    setCorporateName(data.corporateName);
    setFantasyName(data.fantasyName);
    setDocumentNumber(data.documentNumber);
    setPhoneNumber(data.phoneNumber);
    setWebsite(data.website);
    setZipCode(data.zipCode);
    setStreet(data.street);
    setNumber(data.number);
    setComplement(data.complement);
    setNeighborhood(data.neighborhood);
    setState(data.state);
    setCity(data.city);
  };

  async function handleFindAdrress(number) {
    if(number.length > 7) {
      try {
        let { street, neighborhood, city, state, cep: numberCep } = await cep(number);
        setZipCode(numberCep);
        setStreet(street);
        setNeighborhood(neighborhood);
        setCity(city);
        setState(state);
      } catch(error) {
        if (error instanceof Error) {
          console.log(error)
        }
      }
    }
  }
  useEffect(() => {
    getData();
  }, [])


  return (
      <>
        <Row className="justify-content-center mt-2">
          <Col sm="5">
            <FormGroup>
              <Label for="corporateName">Razão Social</Label>
              <Input
                name="corporateName"
                id="corporateName"
                placeholder="Informe a Razão Social..."
                type="text"
                value={corporateName}
                onChange={(e) => setCorporateName(e.target.value)}
              />
            </FormGroup>

          </Col>
          <Col sm="5">
            <FormGroup>
              <Label for="fantasyName">Nome Fantasia</Label>
              <Input
                name="fantasyName"
                placeholder="Informe o Nome Fantasia..."
                id="fantasyName"
                type="text"
                value={fantasyName}
                onChange={(e) => setFantasyName(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="5">
            <FormGroup>
              <Label for="documentNumber">CNPJ</Label>
              <Input
                name="documentNumber"
                placeholder="Informe o CNPJ..."
                id="documentNumber"
                mask="99.999.999/9999-99"
                tag={InputMask}
                type="text"
                value={documentNumber}
                onChange={(e) => setDocumentNumber(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="5">
            <FormGroup>
              <Label for="phoneNumber">Telefone</Label>
              <Input
                name="phoneNumber"
                placeholder="Informe o Telefone..."
                id="phoneNumber"
                mask="(99) 99999-9999"
                tag={InputMask}
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col sm="4">
            <FormGroup>
              <Label for="website">Website</Label>
              <Input
                name="website"
                placeholder="Informe o Website..."
                id="website"
                type="text"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup>
              <Label for="zipCode">CEP</Label>
              <Input
                name="zipCode"
                id="zipCode"
                placeholder="Informe o CEP..."
                type="text"
                value={zipCode}
                onChange={(e) => handleFindAdrress(e.target.value)}
              />
            </FormGroup>

          </Col>
          <Col sm="4">
            <FormGroup>
              <Label for="street">Rua</Label>
              <Input
                name="street"
                id="street"
                placeholder="Informe a Rua..."
                type="text"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </FormGroup>
          
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col sm="4">
            <FormGroup>
              <Label for="complement">Complemento</Label>
              <Input
                name="complement"
                id="complement"
                placeholder="Informe o Complemento..."
                type="text"
                value={complement}
                onChange={(e) => setComplement(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup>
              <Label for="number">Número</Label>
              <Input
                name="number"
                placeholder="Informe o Número..."
                id="number"
                type="text"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup>
              <Label for="neighborhood">Bairro</Label>
              <Input
                name="neighborhood"
                placeholder="Informe o Bairro..."
                id="neighborhood"
                type="text"
                value={neighborhood}
                onChange={(e) => setNeighborhood(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col sm="4">
            <FormGroup>
              <Label for="city">Cidade</Label>
              <Input
                name="city"
                placeholder="Informe a Cidade..."
                id="city"
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup>
              <Label for="state">Estado</Label>
              <Input
                name="state"
                placeholder="Informe o Estado"
                id="state"
                type="text"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </FormGroup>

          </Col>
        </Row>
      </>
    )
})

export default EditPartner;