import moment from "moment";
import { useAuth } from "./AuthContext";

const { createContext, useContext, useState, useEffect, useRef } = require("react");

const NotificationContext = createContext(0)

const NotificationProvider = (props) => {
    const [reports, setReports] = useState(JSON.parse(localStorage.getItem('reports')) || [])
    const actualMoment = moment().subtract(3, 'hours')

    const { user } = useAuth()

    function attReports(report, notification) {
        const reportJson = JSON.parse(report)
        setReports(state => {
            const reportsUpdated = [...state, reportJson] 
            localStorage.setItem('reports', JSON.stringify(reportsUpdated))
            return reportsUpdated
        })

        let options = {
            place: "tr",
            message: "O relatório já está disponível para download.",
            type: "success",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 4
          };

        setTimeout(notification.notificationAlert(options), 2500)
    }

     const validationDiff = () => {
        if (reports.length > 0 && user) {
            const data = reports.filter((item) => {
                const diff = actualMoment.diff(moment(item.createdAt), 'hours')
                const maxLimitDiffOnAbsHours = Math.abs(diff) <= 1
                if (maxLimitDiffOnAbsHours) { 
                    return item
                }
                
                return null;
            
            })
            setReports(data)
            localStorage.setItem('reports', JSON.stringify(data))
            
        }

    }

     useEffect(() => { 
        validationDiff()
    }, [])

    return (
        <NotificationContext.Provider value={{
            reports,
            setReports,
            attReports,
            reportsLength: reports.length
        }}>
            {props.children}
        </NotificationContext.Provider>
    )

}

function useNotification() {
    const context = useContext(NotificationContext);
    if (!context) {
      throw new Error('useContext must be used within an NotificationProvider.');
    }
    return context;
}


export { NotificationProvider, useNotification }	