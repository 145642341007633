import React from "react"

export const UseScreenWidth = () => {
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth)

    const handleWindowSizeChange = () => {
        setScreenWidth(window.innerWidth)
    }
  
    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)  
    }, [])

    return {
        screenWidth
    }
  
}