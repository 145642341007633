export const translateMediaOrigin = (mediaOrigin) => {
    const mediasOrigin = {
        Action: 'Ação',
        TeleMarketing: 'TeleMarketing',
        SocialMedias: 'Redes Sociais',
        Sites: 'Sites',
        MarketingMail: 'E-mail Marketing',
        TV: 'TV',
        Radio: 'Rádio',
        Outdoor: 'Outdoor',
        DigitalMedias: 'Mídias Digitais',
        Outhers: 'Outros',
    }

    return mediasOrigin[mediaOrigin]
      
}