import api from "../../../api/index";
import cep from "cep-promise";
import React, { useEffect } from "react";
import Datetime from "react-datetime";
import { Button, Card, CardBody, CardFooter, Col, FormGroup, Input, Label, Row } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom";
import 'moment/locale/pt-br';
import ImageUpload from "components/CustomUpload/ImageUpload";
import moment from "moment";
import { Dropzone, FileItem } from "@dropzone-ui/react";
import InputMask from "react-input-mask";
import Select from 'react-select';

const CreateUser = () => {
  const [name, setName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [documentNumber, setDocumentNumber] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [role, setRole] = React.useState("");
  const [dateBirth, setDateBirth] = React.useState(new Date());
  const [schooling, setSchooling] = React.useState("");
  const [partnerId, setPartnerId] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [number, setNumber] = React.useState("");
  // const [complement, setComplement] = React.useState("");
  const [neighborhood, setNeighborhood] = React.useState("");
  const [state, setState] = React.useState("");
  const [city, setCity] = React.useState("");
  const [avatarUrl, setAvatarUrl] = React.useState("");
  const [data, setData] = React.useState([]);
  const notificationAlertRef = React.useRef(null);
  const history = useHistory();
  const [files, setFiles] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [partnerSelected, setPartnerSelected] = React.useState(null);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const updateFiles = (incommingFiles) => {
    //do something with the files
    setFiles(incommingFiles);
    //even your own upload implementation
  };
  const removeFile = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };

  const findPartner = (partnerId) => {
    const item = data.find(item => item.id == partnerId);
    setPartnerSelected(item.typePartner);
  }
  async function handleFindAdrress(number) {
    if(number.length > 7) {
      try {
        let { street, neighborhood, city, state, cep: numberCep } = await cep(number);
        setZipCode(numberCep);
        setStreet(street);
        setNeighborhood(neighborhood);
        setCity(city);
        setState(state);
      } catch(error) {
        if (error instanceof Error) {
          console.log(error)
        }
      }
    }
  }

  async function handleFindPartner() {
    const { data: partners } = await api.get("/partners");

    partners.forEach((partner) => {
      data.push(
        {
          label: partner.corporateName,
          value: partner._id
        }
      );
    });
    setData(data);
  }

  const handleSubmit = async () => {
    let options = {};
    setIsDisabled(true)
    try {
      const { data, status } = await api.post(`/users`, {
        name,
        lastName,
        email,
        documentNumber,
        phoneNumber,
        role,
        dateBirth,
        schooling,
        partner: partnerId,
        zipCode,
        street,
        number,
        neighborhood,
        state,
        city,
        avatarUrl
      });

  
      options = {
        place: "tr",
        message: "Usuário registrado com sucesso!",
        type: "success",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7
      };
      
    } catch(err) {
      options = {
        place: "tr",
        message: err.response.data.errors[0],
        type: "danger",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7
      };
    } finally {
      setTimeout(() => {
        setIsDisabled(false)
      }, 2000)
    }

    notificationAlertRef.current.notificationAlert(options);  
    if (options.type === 'success') {
      setTimeout(() => {
        history.push("/admin/users");
      }, 2000)
    }

  }
  
  const myUploader = (data) => {
    setAvatarUrl(data.Location);
  }


  useEffect(() => {
    handleFindPartner();
  }, [])

  useEffect(() => {
    const fetch = async () => {
      const response = await api.get("/roles");
      setRoles(response.data)
    }

    fetch()
  }, [])


  const optionsSchooling = [
    { value: 'CompleteHighSchool', label: 'Ensino Médio Completo' },
    { value: 'IncompleteHighSchool', label: 'Ensino Médio Incompleto' },
    { value: 'CompleteHigherEducation', label: 'Ensino Superior Completo' },
    { value: 'IncompleteHigherEducation', label: 'Ensino Superior Incompleto' },
    { value: 'CompletePrimaryEducation', label: 'Ensino Fundamental Completo' },
    { value: 'IncompletePrimaryEducation', label: 'Ensino Fundamental Incompleto' },
  ]

  return (
    <>
      <div className="content" style={{ width: "100%"}}>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
        <Card className="col-auto - variable width content" >
          <CardBody>
            <Row>
              <Col sm="4">
                <FormGroup>
                  <Label for="name">Nome</Label>
                  <Input 
                    name="name"
                    placeholder="Informe seu nome..."
                    id="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormGroup>
                
              </Col>
              <Col sm="4">
                  <FormGroup>
                    <Label for="lastName">Sobrenome</Label>
                    <Input 
                      name="lastName"
                      placeholder="Informe seu sobrenome..."
                      id="lastName"
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </FormGroup>
              </Col>
              <Col sm="4">
                <FormGroup>
                  <Label for="email">E-mail</Label>
                  <Input 
                    name="email"
                    placeholder="Informe seu e-mail..."
                    id="email"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label for="document-number">CPF</Label>
                  <Input 
                    name="documentNumber"
                    placeholder="Informe o CPF..."
                    id="document-number"
                    type="text"
                    value={documentNumber}
                    mask="999.999.999-99"
                    tag={InputMask}
                    onChange={(e) => setDocumentNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="date-birth">Data de nascimento</Label>
                  <Datetime
                    timeFormat={false}
                    locale="pt-br"
                    dateFormat="DD/MM/YYYY"
                    input={true}
                    id="date-birth"
                    inputProps={{placeholder:"Clique para informar sua data de nascimento..."}}
                    onChange={(date) => setDateBirth(moment(date._d).format("YYYY-MM-DD"))}

                  />
                </FormGroup>
              </Col>
            </Row>
            <Row
            >
              <Col sm="6" md={4}>
                <FormGroup>
                  <Label for="role">Cargo</Label>
                  <Input 
                    name="role"
                    id="cargo"
                    type="select"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option>
                      Selecione o cargo...
                    </option>
                    {
                      roles.map(({_id, translateRole}, index) => (
                        <option key={index} value={_id}>
                          {translateRole}
                        </option>
                      ))
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="6" md={4}>
                <FormGroup>
                  <Label for="escolaridade">Escolaridade</Label>
                  <Input 
                    name="schooling"
                    id="escolaridade"
                    placeholder="Escolaridade"
                    type="select"
                    value={schooling}
                    onChange={(e) => setSchooling(e.target.value)}
                  >
                    <option>
                      Selecione a escolaridade...
                    </option>
                    {
                      optionsSchooling.map(({value, label}) => (
                        <option value={value}>
                          {label}
                        </option>
                      ))
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="12"  md={4} style={{ fontSize: 12}}>
                <FormGroup>
                  <Label for="partnerId">Empresa</Label>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    placeholder="Selecione a empresa associada..."
                    name="partnerId"
                    isSearchable
                    required
                    closeMenuOnSelect
                    options={data}
                    onChange={(partner) => setPartnerId(partner.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <FormGroup>
                  <Label for="zip-code">CEP</Label>
                  <Input 
                    name="zipCode"
                    placeholder="Informe o CEP..."
                    id="zip-code"
                    type="text"
                    onChange={(e) => handleFindAdrress(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="4" md="5">
                <FormGroup>
                  <Label for="street">Rua</Label>
                  <Input 
                    name="street"
                    placeholder="Informe a Rua..."
                    id="street"
                    value={street}
                    type="text"
                    onChange={(e) => setStreet(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="4" md="3">
                <FormGroup>
                  <Label for="number">Número</Label>
                  <Input 
                    name="number"
                    placeholder="Informe o Número..."
                    type="text"
                    value={number}
                    id="number"
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="4">
                <FormGroup>
                  <Label for="neighborhood">Bairro</Label>
                  <Input 
                    name="neighborhood"
                    placeholder="Informe o Bairro..."
                    id="neighborhood"
                    type="text"
                    value={neighborhood}
                    onChange={(e) => setNeighborhood(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="4">
                <FormGroup>
                  <Label for="telephone">Telefone</Label>
                  <Input 
                    name="phoneNumber"
                    placeholder="Informe o Telefone..."
                    id="telephone"
                    value={phoneNumber}
                    mask="(99) 99999-9999"
                    tag={InputMask}
                    type="text"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="4">
                <FormGroup>
                  <Label for="city">Cidade</Label>
                  <Input 
                    name="city"
                    placeholder="Informe a Cidade..."
                    id="city"
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="4" className="mb-4">
                <FormGroup>
                  <Label for="state">Estado</Label>
                  <Input 
                    name="state"
                    placeholder="Informe o Estado..."
                    id="state"
                    type="text"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="text-center">
              <Col sm="12">
                <ImageUpload
                  avatar       
                  changeBtnClasses="btn-simple"
                  addBtnClasses="btn-simple"
                  removeBtnClasses="btn-simple"
                  uploadHandler={myUploader}
                />
              </Col>
            </Row>
            { role != "ADMIN" && partnerSelected == "REAL_STATE_HOUSE" ? 
              <Row className="text-center mt-5">
                <h4 className="ml-3">Envie seus documentos para aprovação: </h4>
                <Col sm="12">
                  <Dropzone
                    style={{ minWidth: "505px" }}
                    onChange={updateFiles}
                    value={files}
                    localization="PT-pt"
                    accept="application/pdf, image/*"
                  >
                    {files.length > 0 &&
                      files.map((file) => (
                        <FileItem {...file} onDelete={removeFile} key={file.id} info preview />
                      ))}
                  </Dropzone>
                </Col>
              </Row>
             : null }
          </CardBody>
          <CardFooter>
            <Button className="btn btn-fill" disabled={isDisabled} onClick={() => handleSubmit()}>{isDisabled ? "Carregando..." : "Salvar"}</Button>
          </CardFooter>
        </Card>
      </div>
    </>
  )
}

export default CreateUser;