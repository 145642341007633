import React from "react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useForm } from "react-hook-form";
import { Button } from "reactstrap";
import api from "api";
import moment from "moment";
import { useAuth } from "context/AuthContext";

export const HistoryForm = ({ clientData, addHistoricalCard }) => {

    const [data, setData] = React.useState("")
    const { handleSubmit, register, setValue } = useForm({
        resetOptions: {
            keepIsSubmitSuccessful: true
        },
        defaultValues: {
            description: ''
        }
    })


    const { user } = useAuth();

    const handleChange = (event, editor) => {
        setData(editor.getData())
    }

    const handleSubmitHistorical = async (opt, e) => {
        const createdAtToIso = moment(clientData.createdAt, 'DD/MM/YYYY')
        const { data } = await api.post(`historical`, {
            text: clientData.status === 'Proposal' ? `${user.name} fez uma proposta: ${opt.description}` : `${user.name} adicionou uma nova observação: ${opt.description}`,
            client: {
                ...clientData,
                createdAt: createdAtToIso.toDate().toISOString()
            }
        })

        addHistoricalCard(data)

        setData("")

    }

    const handleClick = () => {
        setValue('description', data)
    }

    return (
        <div style={{ marginTop: 10 }} className="px-2">    
            <h4>Observação</h4>
            <form onSubmit={handleSubmit((data, e)=> handleSubmitHistorical(data, e))}>
                <div>
                    <CKEditor
                        editor={ClassicEditor}
                        data={data}
                        {...register('description')}
                        config={{
                            toolbar: {
                                items: [
                                    'undo', 'redo',
                                    '|', 'heading',
                                    '|', 'bold', 'italic',
                                    '|', 'insertImage',
                                    '|', 'bulletedList', 'numberedList', 'outdent', 'indent'
                                ]
                            }
                        }}
                        onChange={(event, editor) => handleChange(event, editor)}
                    />
                </div>
                <Button
                    type="submit"
                    color="success"
                    style={{
                        fontWeight: 'bold',
                        marginTop: 15
                    }}
                    onClick={handleClick}

                >
                    Adicionar Nota
                </Button>
            </form>
        </div>
    )
}
