import Enterprises from "views/pages/Enterprises";
import Partners from "views/pages/Partners";
import Stands from "views/pages/Stands";

export const marketingRoutes = [
    {
        path: "/partners",
        name: "Parceiros",
        component: Partners,
        icon: "fa fa-briefcase",
        layout: "/admin",
      },
      {
        path: "/enterprises",
        name: "Empreendimentos",
        icon: "fa fa-building",
        component: Enterprises,
        layout: "/admin",
      },
      {
        path: "/stands",
        name: "Stands",
        icon: "tim-icons icon-badge",
        component: Stands,
        layout: "/admin"
      },
]