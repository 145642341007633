import React, { forwardRef, useEffect } from "react";
import cep from "cep-promise";
// reactstrap components
import {
  Input,
  Row,
  Col,
  FormGroup,
  Label
} from "reactstrap";
import api from "../../../../api";
import InputMask from "react-input-mask";
import Select from "react-select";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";

const EditEnterprise = forwardRef((props, ref) => {
    const [name, setName] = React.useState("");
    const [nameSPE, setNameSPE] = React.useState("");
    const [documentNumber, setDocumentNumber] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [website, setWebsite] = React.useState("");
    const [zipCode, setZipCode] = React.useState("");
    const [street, setStreet] = React.useState("");
    const [number, setNumber] = React.useState("");
    const [complement, setComplement] = React.useState("");
    const [neighborhood, setNeighborhood] = React.useState("");
    const [state, setState] = React.useState("");
    const [city, setCity] = React.useState("");
    const [type, setType] = React.useState("");
    const [incorporators, setIncorporators] = React.useState([]);
    const [realStates, setRealStates] = React.useState([]);
    const [posManagers, setPosManagers] = React.useState([]);
    const [coordinatorsProducts, setCoordinatorsProducts] = React.useState([]);
    const [incorporatorsOption, setIncorporatorsOption] = React.useState([]);
    const [realStatesOption, setRealStatesOption] = React.useState([]);
    const [posManagersOption, setPosManagersOption] = React.useState([]);
    const [coordinatorsProductsOption, setCoordinatorsProductsOption] = React.useState([]);

    const [units, setUnits] = React.useState([]);
    const [towers, setTowers] = React.useState([]);

    const { params } = useRouteMatch()

    const getData = async () => {
      const { data } = await api.get(`/enterprises/${params.enterpriseId}`);
      setIncorporators(data.incorporators);
      setRealStates(data.realStates);
      setPosManagers(data.posManagers);
      setCoordinatorsProducts(data.coordinatorsProducts);
      setName(data.name);
      setNameSPE(data.nameSPE);
      setDocumentNumber(data.documentNumber);
      setPhoneNumber(data.phoneNumber);
      setWebsite(data.website);
      setZipCode(data.zipCode);
      setStreet(data.street);
      setNumber(data.number);
      setComplement(data.complement);
      setNeighborhood(data.neighborhood);
      setState(data.state);
      setCity(data.city);
      setType(data.type);
      setUnits(data.units);
      setTowers(data.towers);
    };
  
    
    React.useImperativeHandle(ref, () => ({
      isValidated: () => isValidated(),
      state: {
        incorporators,
        realStates,
        posManagers,
        coordinatorsProducts,
        name,
        nameSPE,
        documentNumber,
        phoneNumber,
        website,
        zipCode,
        street,
        number,
        complement,
        neighborhood,
        state,
        city,
        type,
        units,
        towers
      },
    }));
    
    function isValidated() {
      if(name !== "" && nameSPE !== "" && documentNumber !== "") {
        return true;
      }
      return false;
    }
    
    async function handleFindAdrress(number) {
      if(number.length > 7) {
        try {
          let { street, neighborhood, city, state, cep: numberCep } = await cep(number);
          setZipCode(numberCep);
          setStreet(street);
          setNeighborhood(neighborhood);
          setCity(city);
          setState(state);
        } catch(error) {
          if (error instanceof Error) {
            console.log(error)
          }
        }
      }
    }

    const handleIncorporators = () => {
      api.get(`partners/find/incorporators`)
      .then((response) => {
          const options = response.data.map((incorporator) => (
              {
                label: incorporator.corporateName,
                value: incorporator._id
              }
            )
          );

          setIncorporatorsOption(options)
        })
    }

    const handleRealState = () => {
      api.get(`partners/find/realstate`)
        .then((response) => {
          const options = response.data.map((realState) => (
              {
                label: realState.corporateName,
                value: realState._id
              }
            )
          );


          setRealStatesOption(options)
        })
    }
    
    const handlePosManagers = (inputValue, callback) => {
      api.get(`users/find/pos-managers`)
        .then((response) => {
            const options = response.data.map((posManager) => (
                {
                  label: posManager.name,
                  value: posManager._id
                }
              )
            );


          setPosManagersOption(options)
        })
    }

    const handleCoordinatorsProducts = () => {
      api.get(`users/find/coordinators-products`)
          .then((response) => {
            const options = response.data.map((coordinator) => (
                {
                  label: coordinator.name,
                  value: coordinator._id
                }
              )
            );


            setCoordinatorsProductsOption(options)
          }
        )
    }

    useEffect(() => {
      getData();
      handleIncorporators();
      handleRealState();
      handlePosManagers();
      handleCoordinatorsProducts()
    }, [])
    return (
      <>
        <Row className="justify-content-center mt-2">
            <Col sm="5">
                <FormGroup>
                    <Label for="type">Incorporadores</Label>
                    <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Selecione os incorporadores..."
                        name="developer"
                        isSearchable
                        closeMenuOnSelect={false}
                        isMulti
                        required
                        options={incorporatorsOption}
                        isLoading={!incorporatorsOption.length}
                        value={incorporatorsOption.filter(incorporatorOption => (incorporators.includes(incorporatorOption.value)))}
                        onChange={(incorporators) => setIncorporators(incorporators.map((incorporator) => incorporator.value))}
                    />
                </FormGroup>
            </Col>
            <Col sm="5">
                <FormGroup>
                    <Label for="type">Imobiliárias</Label>
                    <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Selecione os imobiliárias..."
                        name="realState"
                        isSearchable
                        closeMenuOnSelect={false}
                        isMulti
                        required
                        options={realStatesOption}
                        isLoading={!realStatesOption.length}
                        value={realStatesOption.filter(realStateOption => (realStates.includes(realStateOption.value)))}
                        onChange={(realStates) => setRealStates(realStates.map((realState) => realState.value))}
                    />
                </FormGroup>
            </Col>
            <Col sm="5">
                <FormGroup>
                    <Label for="type">Gestores PDV</Label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Selecione os Gestores PDV..."
                        name="pointOfSaleManagers"
                        isSearchable
                        closeMenuOnSelect={false}
                        isMulti
                        required
                        options={posManagersOption}
                        isLoading={!posManagersOption.length}
                        value={posManagersOption.filter(posManagerOption => (posManagers.includes(posManagerOption.value)))}
                        onChange={(posManagers) => setPosManagers(posManagers.map((posManager) => posManager.value))}
                      />
                </FormGroup>
            </Col>
            <Col sm="5">
                <FormGroup>
                    <Label for="type">Coordenadores de Produto</Label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Selecione os Coordenadores de Produto..."
                        name="productCoordinators"
                        isSearchable
                        closeMenuOnSelect={false}
                        isMulti
                        required
                        options={coordinatorsProductsOption}
                        isLoading={!coordinatorsProductsOption.length}
                        value={coordinatorsProductsOption.filter(coordinatorProductsOption => (coordinatorsProducts.includes(coordinatorProductsOption.value)))}
                        onChange={(coordinatorsProducts) => setCoordinatorsProducts(coordinatorsProducts.map((coordinatorProducts) => coordinatorProducts.value))}
                      />
                </FormGroup>
            </Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col sm="5">
            <FormGroup>
              <Label for="name">Nome</Label>
              <Input
                name="name"
                id="name"
                placeholder="Informe o Nome..."
                value={name}                        
                type="text"
                required
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>

          </Col>
          <Col sm="5">
            <FormGroup>
              <Label for="nameSPE">Nome SPE</Label>
              <Input
                name="nameSPE"
                placeholder="Informe o Nome SPE..."
                id="nameSPE"
                type="text"
                required
                value={nameSPE}                        
                onChange={(e) => setNameSPE(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="5">
            <FormGroup>
              <Label for="documentNumber">CNPJ</Label>
              <Input
                name="documentNumber"
                placeholder="Informe o CNPJ..."
                id="documentNumber"
                type="text"
                mask="99.999.999/9999-99"
                tag={InputMask}
                value={documentNumber}                        
                required
                onChange={(e) => setDocumentNumber(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="5">
            <FormGroup>
              <Label for="phoneNumber">Telefone</Label>
              <Input
                name="phoneNumber"
                placeholder="Informe o Telefone..."
                id="phoneNumber"
                mask="(99) 99999-9999"
                tag={InputMask}
                value={phoneNumber}                        
                type="text"
                required
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col sm="5">
            <FormGroup>
              <Label for="website">Website</Label>
              <Input
                name="website"
                placeholder="Informe o Website..."
                id="website"
                type="text"
                value={website}                        
                onChange={(e) => setWebsite(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="5">
            <FormGroup>
              <Label for="zipCode">CEP</Label>
              <Input
                name="zipCode"
                id="zipCode"
                placeholder="Informe o CEP..."
                type="text"
                required
                value={zipCode}                        
                onChange={(e) => handleFindAdrress(e.target.value)}
              />
            </FormGroup>

          </Col>
          <Col sm="5">
            <FormGroup>
              <Label for="street">Rua</Label>
              <Input
                name="street"
                id="street"
                placeholder="Informe a Rua..."
                type="text"
                value={street}
                required
                onChange={(e) => setStreet(e.target.value)}
              />
            </FormGroup>
           
          </Col>
          <Col sm="5">
            <FormGroup>
              <Label for="complement">Complemento</Label>
              <Input
                name="complement"
                id="complement"
                placeholder="Informe o Complemento..."
                type="text"
                value={complement}
                onChange={(e) => setComplement(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center mt-2">
            <Col sm="3">
                <FormGroup>
                <Label for="number">Número</Label>
                <Input
                    name="number"
                    placeholder="Informe o Número..."
                    id="number"
                    value={number}
                    type="text"
                    onChange={(e) => setNumber(e.target.value)}
                />
                </FormGroup>
            </Col>
            <Col sm="4">
                <FormGroup>
                <Label for="neighborhood">Bairro</Label>
                <Input
                    name="neighborhood"
                    placeholder="Informe o Bairro..."
                    id="neighborhood"
                    type="text"
                    value={neighborhood}
                    required
                    onChange={(e) => setNeighborhood(e.target.value)}
                />
                </FormGroup>
            </Col>
            <Col sm="3">
                <FormGroup>
                <Label for="city">Cidade</Label>
                <Input
                    name="city"
                    placeholder="Informe a Cidade..."
                    id="city"
                    type="text"
                    value={city}
                    required
                    onChange={(e) => setCity(e.target.value)}
                />
                </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col sm="3">
            <FormGroup>
              <Label for="state">Estado</Label>
              <Input
                name="state"
                placeholder="Informe o Estado"
                id="state"
                type="text"
                value={state}
                required
                onChange={(e) => setState(e.target.value)}
              />
            </FormGroup>
 
          </Col>
          <Col sm="3">
            <FormGroup>
              <Label for="type">Tipo</Label>
              <Input 
                name="type"
                id="type"
                type="select"
                value={type}
                required
                onChange={(e) => setType(e.target.value)}
              >
                <option>
                  Selecione o tipo...
                </option>
                <option value="HORIZONTAL">
                  Horizontal
                </option>
                <option value="VERTICAL">
                  Vertical
                </option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
});

export default EditEnterprise;
