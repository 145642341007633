import { createContext, useCallback, useContext, useEffect, useState } from "react";
import api from '../api';

const AuthContext = createContext({});

function AuthProvider({ children }){
  const [credentialsData, setCredentialsData] = useState(() => {
    const token = localStorage.getItem('token')
    const refreshToken = localStorage.getItem('refreshToken')
    const user = localStorage.getItem('currentUser')
    return { token, refreshToken , user: JSON.parse(user)}
  })
 
  
  useEffect(() => {
    if(credentialsData.token)
      api.defaults.headers.authorization = `Bearer ${credentialsData.token}`;
  },[credentialsData])

  const signIn = useCallback(async ({email, password}) => {
    try {
      const { data: {user, token, refreshToken} } = await api.post('/auth/login', {email, password})
      localStorage.setItem('token', token)
      localStorage.setItem('refreshToken', refreshToken)
      localStorage.setItem('currentUser', JSON.stringify(user))
  
      setCredentialsData({user, token, refreshToken})
    } catch (error) {
      throw error
    }
  },[])

  const signOut = useCallback(() => {
    localStorage.removeItem('currentUser')
    localStorage.removeItem('token') 
    localStorage.removeItem('refreshToken')
    localStorage.removeItem("reports");
    setCredentialsData({})
  },[])

  return (
    <AuthContext.Provider value={{
      user: credentialsData.user, 
      token: credentialsData.token, 
      refreshToken: credentialsData.refreshToken, 
      signIn, 
      signOut}}>
      {children}
    </AuthContext.Provider>
  );
} 

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }
  return context;
}

export { AuthProvider, useAuth }
