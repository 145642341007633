import FifthDaysEntryPeriod from "../entry-period/fifth-days-entry-period.js";
import SevenDaysEntryPeriod from "../entry-period/seven-days-entry-period.js"
import ThisMonthEntryPeriod from "../entry-period/this-month-entry-period.js";

export function CreatePeriodFactory(period) {
   

        switch (period) {
            case "Ultimos 7 dias":
                return new SevenDaysEntryPeriod().makePeriod()
            case "Ultimos 15 dias":
                return new FifthDaysEntryPeriod().makePeriod()
            case "Este mês":
                return new ThisMonthEntryPeriod().makePeriod()
            default: 
                break;
        }
}