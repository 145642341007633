import React, { useCallback } from "react";
import { CardTimeLine } from "./CardTimeLine";
import { diff } from "deep-object-diff";
import { translateStatus } from "variables/translateStatus";
import { translateMediaOrigin } from "variables/translateMediaOrigin";

export const HistoricalTimeLine = (props) => {

    const {
        cards,
        setCards,
        historical,
        isLoading
    } = props;


    const translateProperty = (value, historical, newValue) => {
        const { clientBeforeModified, updatedAt, createdBy, client } = historical;

        const Feedbacks = {
            owner: {
                text: `${createdBy?.name} mudou o dono do cliente "${client?.name}" de "${clientBeforeModified?.owner?.name}" para "${client?.owner?.name}"`,
                updatedAt
            },
            averageTicket: {
                text: `${createdBy?.name} atualizou o ticket medio do cliente "${client?.name}" de "R$${clientBeforeModified?.averageTicket},00" para "R$${client?.averageTicket},00"`,
                updatedAt
            },
            status: {
                text: `${createdBy?.name} mudou o status do cliente "${client?.name}" de "${translateStatus(clientBeforeModified?.status)}" para "${translateStatus(client?.status)}"`,
                updatedAt
            },
            documents: {
                text:
                    `${createdBy?.name} anexou um novo arquivo ao cliente "${client?.name}"`,
                document: `${client?.documents[client?.documents.length - 1]}`,
                updatedAt
            },
            phoneNumbers: {
                text: `${createdBy?.name} mudou o status do cliente "${client?.name}" de "${clientBeforeModified?.status}" para "${client?.status}"`,
                    updatedAt
            },
            deletedAt: {
                text: `${createdBy?.name} descartou o cliente "${client?.name}"`,
                updatedAt
            },
            businessClosedAt: {
                text: `${createdBy?.name} fechou negócio com o cliente "${client?.name}" com o ticket médio custando R$${client?.averageTicket},00`,
                updatedAt
            },
            value: {
                text: `${createdBy?.name} adicionou um novo telefone ao cliente "${client?.name}": ${newValue}`,
                updatedAt
            },
            text: {
                text: newValue,
                updatedAt
            },
            mediaOrigin: {
                text:`${createdBy?.name} atualizou a mídia origem do cliente "${client?.name}": ${translateMediaOrigin(client?.mediaOrigin)}`,
                updatedAt
            },
            note: {
                text:`${createdBy?.name} atualizou a observação do cliente "${client?.name}": ${client?.note}`,
                updatedAt
            },
            default: {
                text: `${createdBy?.name} cadastrou o cliente "${client?.name}" com a observação "${client?.note}"`,
                updatedAt
            }
        }

        return Feedbacks[value] || Feedbacks.default
    }

    const buildHistorical = useCallback(() => {
        const response = historical.map((item) => {
            const  { clientDiff, updatedClient } = generateClientDiff(item)

            let property = updatedClient[0]


            if (updatedClient[0] === 'enterprise') {
                property = updatedClient[2]
            }
            
            
            if (!item.clientBeforeModified && !item.text) {
                const objDiff = translateProperty(property, item)
                return objDiff
            }
            
            if (updatedClient[0] === 'deletedAt' && clientDiff.deletedAt === undefined) {
                property = updatedClient[1]
            }
            
            let objDiff
            const hasOwner = updatedClient.includes('owner')
            const hasStatus = updatedClient.includes('status')
            const hasAverageTicket = updatedClient.includes('averageTicket')
            if (hasOwner) {
                property = 'owner'
            }
            
            if (hasStatus) {
                property = 'status'
            }   

            if (hasAverageTicket) {
                property = 'averageTicket'
            }
            
            objDiff = translateProperty(property, item)
            
            if (updatedClient[0] === 'phoneNumbers') {
                const phoneNumbersDiff = diff(item.clientBeforeModified.phoneNumbers, item.client.phoneNumbers)
                const updatedPhone = Object.keys(phoneNumbersDiff[item.client.phoneNumbers.length - 1])

                const objDiff = translateProperty(updatedPhone[1], item, phoneNumbersDiff[item.client.phoneNumbers.length - 1].value)
                return objDiff
            }

            if (item.text) {
                const objDiff = translateProperty('text', item, item.text)
                return objDiff
            }

            return objDiff

        })

        return response
    }, [historical])

    const generateClientDiff = (item) => {
        const clientDiff = diff(item.clientBeforeModified, item.client)
        if ((Object.keys(clientDiff).length === 1 && Object.keys(clientDiff)[0] === 'updatedAt')) return null
        const updatedClient = Object.keys(clientDiff)

        return { clientDiff, updatedClient }
    }

    React.useEffect(() => {
        if (historical) {
           const response = buildHistorical()
            setCards(response)
        }

    }, [historical, setCards, buildHistorical])

    return (
        <div style={{ overflowY: "scroll", maxHeight: 400 }}>
            {
                isLoading && (<p>Carregando cards da timeline...</p>)
            }
            {
                cards && !isLoading && cards.map((item, index) => (
                    <CardTimeLine key={index} content={item} />
                ))
            }
        </div>
    )
}