import { Card } from "reactstrap";
import { translateCaption } from "variables/translateCaptions";

export const Captions = ({ captions }) => {

    return (
        <>
            {
                captions.length > 0 && captions[0].value.length > 0 && (
                    <>
                        <h3>Filtrado por: </h3>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8, justifyContent: 'space-between' }}>
                            {
                                captions.map(({ key, value }, index) => (
                                    <div style={{  minHeight: 140, width: "28%" }} key={index}>
                                        {
                                            value.length > 0 && (
                                                <Card style={{ marginBottom: 10, width: '100%'}}>
                                                    {
                                                        value.length > 0 && (
                                                            <div
                                                                style={{
                                                                    marginBottom: 10,
                                                                    display: 'flex',
                                                                    alignItems: 'baseline',
                                                                    flexWrap: 'wrap',
                                                                    border: '1px dotted #1EF7DC',
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    padding: '5px 10px',
                                                                    borderRadius: 5
                                                                }}
                                                            >
                                                                <div>
                                                                    <h4 style={{ margin: '4px auto', fontWeight: 'bold' }}>{translateCaption(key)}:</h4>
                                                                    <ul style={{ listStyle: 'square' }}>
                                                                        {
                                                                            value.map((item, index) => (
                                                                                <li key={index}>
                                                                                    <span style={{ color: '#ddd', display: 'flex', flexWrap: 'wrap' }}>{item}</span>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </Card>
                                            )
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </>
                )
            }
        </>
    )
}