import api from "../../../../api/index";
import React, { useEffect } from "react";
import 'moment/locale/pt-br';
import { useRouteMatch } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, Col, FormGroup, Input, Label, Row } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CurrencyInput from "react-currency-input";
import { useAuth } from "context/AuthContext";
import { ClientFormWithoutAdmin } from "../../CustomerManagement/components/ClientFormWithoutAdmin";
import { PhoneNumberInput } from "views/pages/CustomerManagement/components/PhoneNumberInput";
import { PhoneNumberInputAdmin } from "views/components/PhoneNumberInputAdmin";

const mediasOrigin = [
  { label: "Ação", value: "Action" },
  { label: "TeleMarketing", value: "TeleMarketing" },
  { label: "Redes Sociais", value: "SocialMedias" },
  { label: "Sites", value: "Sites" },
  { label: "E-mail Marketing", value: "MarketingMail" },
  { label: "Tv", value: "TV" },
  { label: "Rádio", value: "Radio" },
  { label: "Outdoor", value: "Outdoor" },
  { label: "Mídias Digitais", value: "DigitalMedias" },
  { label: "Outros", value: "Others" },
]

const EditClient = React.forwardRef((props, ref) => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [averageTicket, setAverageTicket] = React.useState("");
  const [ticket, setTicket] = React.useState("");
  const [note, setNote] = React.useState("");
  const [enterprises, setEnterprises] = React.useState([]);
  const [stands, setStands] = React.useState([]);
  const [enterprise, setEnterprise] = React.useState(null);
  const [stand, setStand] = React.useState(null);
  const [processingType, setProcessingType] = React.useState({});
  const [mediaOrigin, setMediaOrigin] = React.useState("");
  const [phoneNumbers, setPhoneNumbers] = React.useState([])
  const [isDisabled, setIsDisabled] = React.useState(false)


  const notificationAlertRef = React.useRef(null);

  const { params } = useRouteMatch();

  const history = useHistory()

  const { user } = useAuth();

  const onCreateNewPhoneNumber = (type, value) => {
    setPhoneNumbers([...phoneNumbers, { type, value }])
    setIsDisabled(false)
  }

  const getData = async () => {
    const { data } = await api.get(`/clients/${params.clientId}`);
    setName(data.name);
    setEmail(data.email);
    setPhoneNumber(data.phoneNumber);
    setPhoneNumbers(data.phoneNumbers);
    setAverageTicket(data.averageTicket)
    setTicket(data.averageTicket)
    setNote(data.note)
    setStand(data.stand?._id)
    setEnterprise(data.enterprise?._id)
    setMediaOrigin(data.mediaOrigin)
    setProcessingType({ enterprise: data.enterprise?._id } || { stand: data.stand?._id })

    return data
  };

  const handleSubmit = async () => {
    const averageTicketOnNumber = Number(String(averageTicket).replace(",", ".").split('.').join(""))
    const { data, status } = await api.patch(`/clients/${params.clientId}`, {
      name,
      email,
      phoneNumbers,
      ...processingType,
      mediaOrigin,
      averageTicket: averageTicketOnNumber,
      note,
    });

    let options = {};

    if (status !== 201) {
      options = {
        place: "tr",
        message: data,
        type: "danger",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7
      };
    }

    options = {
      place: "tr",
      message: "Cliente modificado com sucesso!",
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };

    notificationAlertRef.current.notificationAlert(options);
    setTimeout(() => {
      history.goBack();
    }, 2000)
  }

  const getProcessingType = async (isStand) => {
    if (isStand) {
      const { data } = await api.get(`/stands`);
      setStands(data)
    }

    if (!isStand) {
      const { data } = await api.get(`/enterprises`);
      setEnterprises(data)
    }

  };


  useEffect(() => {
    const fetch = async () => {
      const data = await getData();
      await getProcessingType(!!data.stand)
    }

    fetch()
  }, [])

  const onChangePhoneNumbers = (value, index) => {
    let items = [...phoneNumbers]

    items[index].value = value

    setPhoneNumbers(items)
  }


  const translateType = (type) => {
    if (type === "Work") {
      return "Trabalho"
    }

    if (type === "Personal") {
      return "Pessoal"
    }

    return null
  }

  React.useEffect(() => {
    if (ticket.length > 2) {
      setAverageTicket(ticket.substring(0, ticket.length - 2).replace(",", "").replace("R$", "").replace('.', ''))
    }
  }, [ticket])


  return (
    <>
      <div className="content" style={{ width: "100%" }}>
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        {
          user.role.name === "ADMIN" ? (
            <Card className="col-auto - variable width content" >
              <CardBody>
                <Row>
                  <Col sm="5">
                    <FormGroup>
                      <Label for="name">Nome</Label>
                      <Input
                        name="name"
                        placeholder="Informe seu nome..."
                        id="name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="5">
                    <FormGroup>
                      <Label for="email">E-mail</Label>
                      <Input
                        name="email"
                        placeholder="Informe seu e-mail..."
                        id="email"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="2">
                    <FormGroup>
                      <Label for="averageTicket">Ticket Médio</Label>
                      <CurrencyInput
                        name="averageTicket"
                        placeholder="Ticket Médio"
                        className="form-control"
                        id="averageTicket"
                        inputType="text"
                        prefix="R$"
                        decimalSeparator=","
                        thousandSeparator="."
                        value={ticket}
                        onChangeEvent={(e) => setTicket(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      {
                        stand ? (
                          <>
                            <Label for="stand">Stand</Label>
                            <Input
                              name="stand"
                              id="stand"
                              type="select"
                              value={stand}
                              onChange={(e) => setProcessingType({ stand: e.target.value })}
                            >
                              {
                                stands.map(({ name, _id }, index) => (
                                  <option key={index} value={_id}>
                                    {name}
                                  </option>
                                ))
                              }
                            </Input>
                          </>
                        ) : (
                          <>
                            <Label for="enterprise">Empreendimento</Label>
                            <Input
                              name="enterprise"
                              id="enterprise"
                              type="select"
                              value={processingType.enterprise}
                              onChange={(e) => setProcessingType({ enterprise: e.target.value })}
                            >
                              {
                                enterprises.map(({ name, _id }, index) => (
                                  <option key={index} value={_id}>
                                    {name}
                                  </option>
                                ))
                              }
                            </Input>
                          </>
                        )
                      }
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label for="mediaOrigin">Mídia Origem</Label>
                      <Input
                        name="mediaOrigin"
                        id="mediaOrigin"
                        placeholder="Mídia Origem"
                        type="select"
                        value={mediaOrigin}
                        onChange={(e) => setMediaOrigin(e.target.value)}
                      >
                        {
                          mediasOrigin.map(({ label, value }, index) => (
                            <option key={index} value={value}>{label}</option>
                          ))
                        }
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    <FormGroup>
                      <Label for="note">Observação</Label>
                      <textarea
                        name="note"
                        className="form-control"
                        placeholder="Observação"
                        id="note"
                        type="text"
                        rows={3}
                        cols={30}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    <FormGroup>
                      <Label for="telephone">Telefones</Label>
                      {
                        phoneNumbers && phoneNumbers.map((phoneNumber, index) => (
                          <div key={index} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <PhoneNumberInputAdmin phoneNumberValue={phoneNumber.value} onChange={onChangePhoneNumbers} index={index} />
                            <span>{translateType(phoneNumber.type)}</span>
                          </div>
                        ))
                      }
                    </FormGroup>
                    {
                      isDisabled && (
                        <FormGroup>
                          <PhoneNumberInput onCreateNewPhoneNumber={onCreateNewPhoneNumber} />
                        </FormGroup>
                      )
                    }
                    <Button
                      className="btn btn-info mt-3"
                      disabled={isDisabled}
                      onClick={() => setIsDisabled(true)}
                    >
                      <i className="tim-icons icon-simple-add" />
                    </Button>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button className="btn btn-fill" onClick={handleSubmit}>Salvar</Button>
              </CardFooter>
            </Card>

          ) : (
            <ClientFormWithoutAdmin phoneNumbersList={phoneNumbers} clientId={params.clientId} averageTicketValue={averageTicket} />
          )
        }
      </div>
    </>
  )
})

export default EditClient;