import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ActivityForm } from "./components/ActivityForm";
import { ClientData } from "./components/ClientData";
import { Header } from "./components/Header";
import { HistoryForm } from "./components/HistoryForm";
import { useGlobalComponentState } from "./hooks/useGlobalComponentState";
import React from "react";
import { useClientData } from "./hooks/useClientData";
import { HistoricalTimeLine } from "./components/HistoricalTimeLine";
import api from "api";
import { ActivityTimeLine } from "./components/ActivityTimeLine";
import { Button } from "reactstrap";

export const CustomerManagementPage = () => {

    const { clientId } = useParams();

    const [cards, setCards] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false);
    const [historical, setHistorical] = React.useState([]);
    const [activities, setActivities] = React.useState([]);
    const [skip, setSkip] = React.useState(0)

    const getActivities = React.useCallback(async (id) => {
        try {
            setIsLoading(true)
            const { data } = await api.get(`activity?client._id=${id ?? clientId}&sort=-scheduledAt`)
            setActivities(data)

            return data
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }, [clientId])

    const getHistorical = React.useCallback(async (id) => {
        try {
            setIsLoading(true)
            const { data } = await api.get(`historical?client._id=${id ?? clientId}&sort=-createdAt&limit=10&skip=${skip}`)
            setHistorical(data)

            return data
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }, [clientId, skip])

    const {
        isHistory,
        isActivity,
        onChangeHistory,
        onChangeActivity
    } = useGlobalComponentState()

    const addHistoricalCard = (newHistorical) => {
        try {
            setIsLoading(true)
            setCards([newHistorical, ...cards])
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const addActivity = (newActivity) => {
        try {
            setIsLoading(true)
            setCards([newActivity, ...cards])
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const onGoPreviousPage = () => {
        if (skip === 0) return;
        setSkip(skip - 10)
    }

    const onGoNextPage = () => {
        setSkip(skip + 10)
    }

    const { clientData } = useClientData();

    React.useEffect(() => {
        if (isHistory) {
            getHistorical()
        }
    }, [getHistorical, isHistory, skip])

    React.useEffect(() => {
        if (isActivity) {
            getActivities()
        }
    }, [getActivities, isActivity])


    return (
        <div className="content" style={{ minHeight: '100vh' }}>
            <section
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 3fr",
                    gridTemplateRows: "20% 38% 30%",
                    columnGap: 10,
                    minHeight: '100vh'
                }}
            >
                <div style={{ gridRowStart: 1, gridRowEnd: 3 }}>
                    <ClientData addHistoricalCard={addHistoricalCard} />
                </div>
                <div
                    style={{
                        gridColumnStart: 2,
                        gridColumnEnd: 2,
                        gridRowStart: 1,
                        gridRowEnd: 1,
                    }}
                >
                    <Header
                        isHistory={isHistory}
                        isActivity={isActivity}
                        onChangeHistory={onChangeHistory}
                        onChangeActivity={onChangeActivity}
                        addHistoricalCard={addHistoricalCard}
                    />
                </div>
                <div
                    style={{
                        gridColumnStart: 2,
                        gridColumnEnd: 2,
                        gridRowStart: 2,
                        gridRowEnd: 2,
                    }}
                >
                    {
                        isHistory && (
                            <HistoryForm clientData={clientData} addHistoricalCard={addHistoricalCard} />
                        )
                    }
                    {
                        isActivity && (
                            <ActivityForm addActivity={addActivity} />
                        )
                    }
                </div>
                <div
                    style={{
                        gridColumnStart: 2,
                        gridColumnEnd: 2,
                        gridRowStart: 3,
                        gridRowEnd: 3,
                    }}
                >
                    {
                        isHistory && (
                            <>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                    <Button onClick={onGoPreviousPage} disabled={skip === 0}>
                                        <i className="fa-solid fa-angle-left" ></i>
                                    </Button>
                                    <Button onClick={onGoNextPage}>
                                        <i className="fa-solid fa-angle-right"></i>
                                    </Button>
                                </div>
                                <HistoricalTimeLine
                                    cards={cards}
                                    setCards={setCards}
                                    historical={historical}
                                    isLoading={isLoading}
                                />
                            </>
                        )
                    }
                    {
                        isActivity && (
                            <ActivityTimeLine
                                cards={cards}
                                setCards={setCards}
                                activities={activities}
                                isLoading={isLoading}
                                isActivity={isActivity}
                            />
                        )
                    }
                </div>
            </section>
        </div>
    )
} 