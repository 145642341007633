import api from "api";
import React from "react";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";

export const useBusinessClose = () => {
    const [ businessClosed, setBusinessClosed ] = React.useState([]);

    const { params } = useRouteMatch()

    const getBusinessClosed = React.useCallback(async (params = "") => {
        const { data } = await api.get(`/business/closed?${params}`)
        setBusinessClosed(data)
    }, [])

    React.useEffect(() => {
        getBusinessClosed()
    }, [getBusinessClosed])

    const handleCloseBusiness = async (totalValue) => {
        await api.patch(`business/${params.clientId}`, {
            closedAt: new Date(),
            closedValue: totalValue
        })
    }

    return  { handleCloseBusiness, businessClosed, getBusinessClosed }
}