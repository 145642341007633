import React from "react";
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap"
import { useBusinessDiscarded } from "../CustomerManagement/hooks/useBusinessDiscarded"
import { TableHeader } from "views/components/TableHeader";
import ReactTable from "components/ReactTable/ReactTable.js";
import { FilterTable } from "views/components/FilterTables";
import { useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { filterByUrlParams } from "variables/filter-by-url-params";
import classNames from "classnames";
import { ModalOwners } from "../CustomerManagement/components/ModalOwners";
import api from "api";
import { ModalClientsSend } from "../CustomerManagement/components/ModalClientsSend";
import { HasPermission } from "variables/permissions";

export const BusinessDiscarded = () => {
    const [modalOwners, setModalOwners] = React.useState(false);
    const [modalClientsSend, setModalClientsSend] = React.useState(false);
    const [owner, setOwner] = React.useState("");
    const [clientId, setClientId] = React.useState("");
    const [owners, setOwners] = React.useState([]);
    const { businessDiscarded, getBusinessDiscarded, getEnterprises, onNextPage, onPreviousPage, pagination } = useBusinessDiscarded();

    const { handleSubmit, register, setValue, getValues } = useForm();

    const onSubmit = async (e, otherParams) => {
        
        let params = filterByUrlParams(e);

        if (otherParams) {
            params = otherParams;
          }

        getBusinessDiscarded(params)
    }

    const onChangeText = (e, field) => {
        setValue(field, `*${e.target.value}`)
        const params = getValues();
        
        let objParams = ""; 

        objParams = translateClientProperty(params.client)

        if (e.target.value.length > 2 || e.target.value.length === 0) {
            onSubmit(params, objParams)
        }
    }

    const translateClientProperty = (client) => {
        let property = "";
    
        const params = getValues()
    
        if (client.name) {
          property += `client.name=${client.name}&`;
        }
    
        if (client.name === "") {
          onSubmit(params)
        }
        return property;
      };

    const translateEnterpriseProperty = (enterprise) => {
        let property = "";
    
        const params = getValues()
    
        if (enterprise._id) {
          property += `enterprise._id=${enterprise._id}&`;
        }
    
        if (enterprise._id === "") {
          onSubmit(params)
        }
        return property;
      };

      const onChangeSelect = (e, field, type) => {
        setValue(field, `${e.map(({ value }) => value)}`);
        
        const params = getValues();
      
        let objParams = ""; 
    
        if (type === "enterprise") {
          objParams = translateEnterpriseProperty(params.enterprise);
        }
        onSubmit(params, objParams);
      };

    const getOwners = async () => {
        const { data } = await api.get('/users/possible-owners');
        setOwners(data)
    };

    const onChangeOwners = async () => {
        await api.patch(`/clients/${clientId}`, {
            owner,
            deletedAt: null
        })
        setModalOwners(false)
        getBusinessDiscarded()
    }

    return (
        <>
            <div className="content">
                <Row className="mt-5">
                    <Col xs={12} md={12}>
                        <FilterTable onSubmit={handleSubmit((e) => onSubmit(e))}>
                            <FormGroup>
                                <Row>
                                    <Col sm="4">
                                        <Label for="name">Nome</Label>
                                        <Input
                                            type="text"
                                            name="name"
                                            placeholder="Nome"
                                            id="name"
                                            {...register('name')}
                                            onChange={(e, _) => onChangeText(e, 'client.name')}
                                        />
                                    </Col>
                                    <Col sm="4">
                                        <Label for="enterprise">Empreendimento</Label>
                                        <AsyncSelect
                                            classNamePrefix="react-select"
                                            className="react-select info"
                                            placeholder="Selecionar Empreendimento"
                                            name="enterprises"
                                            isSearchable
                                            isMulti
                                            closeMenuOnSelect={false}
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={(inputValue, callback) => getEnterprises(inputValue, callback)}
                                            {...register('enterprise')}
                                            onChange={(e, _) => onChangeSelect(e, 'enterprise._id', "enterprise")}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </FilterTable>
                        <Card>
                            <TableHeader
                                title="Clientes Descartados"
                                hasSendClient
                                permission="clients.discarded.send"
                                openModal={() => {
                                    setModalClientsSend(true)
                                    getOwners()
                                }}
                            />
                            <CardBody>
                                {
                                    businessDiscarded && (
                                        <ReactTable
                                            data={businessDiscarded}
                                            filterable
                                            columns={[
                                                {
                                                    Header: "Nome",
                                                    Cell: data => {
                                                        return (
                                                            <p>{data.row.original.client.name}</p>
                                                        )
                                                    }
                                                },
                                                {
                                                    Header: "Empreendimento/Stand",
                                                    Cell: data => {
                                                        return (
                                                            <p>{data.row.original.stand ? data.row.original.stand.name : data.row.original.enterprise?.name}</p>
                                                        )
                                                    }
                                                },
                                                {
                                                    Header: "Imobiliária",
                                                    Cell: data => {
                                                        return (
                                                            <p>{data.row.original.client.partner?.corporateName}</p>
                                                        )
                                                    }
                                                },
                                                {
                                                    Header: "Dono",
                                                    Cell: data => {
                                                        return (
                                                            <p>{data.row.original.client.owner?.name}</p>
                                                        )
                                                    }
                                                },
                                                {
                                                    Header: "Ações",
                                                    sortable: false,
                                                    filterable: false,
                                                    Cell: data => (
                                                        <div className="actions-right">
                                                           {
                                                            HasPermission("clients.discarded.send")
                                                           &&
                                                           (
                                                           <Button
                                                                color="primary"
                                                                onClick={() => {
                                                                    setClientId(data.row.original.client._id)
                                                                    setModalOwners(true)
                                                                    getOwners()
                                                                }}
                                                                size="sm"
                                                                className={classNames("btn-icon btn-link remove", {
                                                                    "btn-neutral": false
                                                                })}
                                                            >
                                                                <i className="tim-icons icon-send" />
                                                            </Button>
                                                            )
                                                           }
                                                        </div>
                                                    )
                                                }
                                            ]}
                                            defaultPageSize={10}
                                            className="-striped -highlight"
                                            paginationOptions={{
                                                onGoToNextPage: onNextPage,
                                                onGoToPreviousPage: onPreviousPage,
                                                canGoToNextPage: businessDiscarded.length === 10,
                                                canGoToPreviousPage: pagination !== 1,
                                              }}
                                        />
                                    )
                                }
                            </CardBody>
                        </Card>
                        <ModalOwners
                            isOpen={modalOwners}
                            onClose={() => setModalOwners(false)}
                            owner={owner}
                            onChangeOwner={(e) => setOwner(e.target.value)}
                            listOwners={owners}
                            onSubmit={onChangeOwners}
                        />
                        <ModalClientsSend
                            isOpen={modalClientsSend}
                            onClose={() => {
                                setModalClientsSend(false)
                                getBusinessDiscarded()
                            }}
                            owner={owner}
                            onChangeOwner={(e) => setOwner(e.target.value)}
                            listOwners={owners}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}