import OrgChart from "views/pages/OrgChart";
import Enterprises from "views/pages/Enterprises";
import ViewChart from "views/pages/OrgChart/view";
import Users from "views/pages/Users";
import CreateUser from "views/pages/Users/CreateUser";
import Clients from "views/pages/Clients";
import BusinessPanel from "views/pages/BusinessPanel";
import { BusinessClosed } from "views/pages/Clients/BusinessClosed";
import { BusinessDiscarded } from "views/pages/Clients/BusinessDiscarded";
import WizardClient from "views/pages/Clients/Create/WizardClient";
import { CustomerManagementPage } from "views/pages/CustomerManagement";

export const generalDirectorRoutes = [
    {
        collapse: true,
        name: "Usuários",
        icon: "tim-icons icon-single-02",
        state: "pagesCollapse",
        views: [
          {
            path: "/users",
            name: "Listar usuários",
            mini: "U",
            component: Users,
            layout: "/admin"
          },
        ] 
      },
      {
        path: "/user/new",
        name: "Criar Usuários",
        component: CreateUser,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/new",
        name: "Criar Usuários",
        component: CreateUser,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/enterprises",
        name: "Empreendimentos",
        icon: "fa fa-building",
        component: Enterprises,
        layout: "/admin",
      },
      {
        collapse: true,           
        name: "Clientes",
        icon: "tim-icons icon-badge",
        state: "pagesCollapse",
        views: [
          {
            path: "/clients",
            name: "Listagem de Clientes",
            mini: "C",
            component: Clients,
            layout: "/admin"
          },
          {
            path: "/businessPanel",
            name: "Painel de Negócios",
            mini: "PN",
            component: BusinessPanel,
            layout: "/admin"
          },
        ] 
      },
      {
        path: "/client/new",
        name: "Criar Cliente",
        component: WizardClient,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/customerManagement/:clientId",
        name: "Gestão de Cliente",
        component: CustomerManagementPage,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/charts",
        name: "Organagrama",
        icon: "tim-icons icon-vector",
        component: OrgChart,
        layout: "/admin",
      },
      {
        path: "/chart/:id/edit",
        name: "Visualizar Organagrama",
        component: ViewChart,
        layout: "/admin",
        invisible: true,
      },
]