import { Button, FormGroup, Input, Modal, ModalBody, ModalFooter } from "reactstrap"
import { translateStatus } from "variables/translateStatus";

export const ModalStatusClient = (props) => {

    const {
        isOpen,
        onClose,
        status,
        onChangeStatus,
        listStatus,
        onSubmit
    } = props;

    return (
        <Modal isOpen={isOpen} modalClassName="modal-black">
            <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                    <i className="tim-icons icon-simple-remove" onClick={onClose}></i>
                </button>
                <h5 className="modal-title">Selecione o estágio</h5>
            </div>
            <ModalBody>
                <FormGroup>
                    <Input
                        name="status"
                        id="cargo"
                        type="select"
                        value={status}
                        onChange={onChangeStatus}
                    >
                        <option>
                            Selecione o Status...
                        </option>
                        {
                            listStatus.map((status, index) => (
                                <option key={index} value={status}>{translateStatus(status)}</option>
                            ))
                        }
                    </Input>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onSubmit}>Ok</Button>
            </ModalFooter>
        </Modal>
    )
}