import React from "react";
import { useForm } from "react-hook-form";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody } from "reactstrap";
import DateTime from 'react-datetime'
import moment from "moment";
import NotificationAlert from "react-notification-alert";
import api from "api";


export const ModalScheduling = (props) => {
    const [scheduledAt, setScheduledAt] = React.useState("")
    const [typeOfServices, setTypeOfServices] = React.useState([]);
    const { handleSubmit, register, setValue } = useForm({
        mode: 'onSubmit',
    })

    const {
        isOpen,
        title,
        setToggleModal,
        clientId,
        returnCard
    } = props;

    const notificationAlertRef = React.useRef(null);

    const servicesType = [
        {
            label: "E-mail",
            value: "E-mail",
        },
        {
            label: "Ligar",
            value: "Call",
        },
        {
            label: "Agendado",
            value: "Scheduled"
        }
    ]

    const getTypeOfServices = async () => {
        const { data } = await api.get('/clients/type-service');
        setTypeOfServices(data)
    };

    const onSubmit = async (e) => {
        let options = {}
        const formatedDate = new Date(scheduledAt).toISOString()
        const { data, status } = await api.post('/activity', {
            type: e.type,
            scheduledAt: formatedDate,
            description: e.description,
            typeOfService: e.typeOfService,
            client: clientId
        })

        if (status !== 201) {
            options = {
                place: "tr",
                message: data,
                type: "danger",
                icon: "tim-icons icon-bell-55",
                autoDismiss: 7
            };
        }

        options = {
            place: "tr",
            message: "Atividade registrada com sucesso!",
            type: "success",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };

        notificationAlertRef.current.notificationAlert(options);

        setToggleModal()
    }

    React.useEffect(() => {
        setTimeout(getTypeOfServices, 3000)
    }, [])

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <Modal isOpen={isOpen} modalClassName="modal-black">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                        returnCard()
                        setToggleModal()
                    }}>
                        <i className="tim-icons icon-simple-remove"></i>
                    </button>
                    <h5 className="modal-title">{title}</h5>
                </div>
                <ModalBody className="modal-black">
                    <form onSubmit={handleSubmit((e) => onSubmit(e))}>
                        <FormGroup>
                           {
                                title !== "Outros Atendimentos" && (
                                    <Col style={{ marginBottom: 10, padding: 0 }}>
                                        <Label for="type" style={{ fontWeight: 'bold' }}>Tipo</Label>
                                        <Input
                                            type="select"
                                            name="type"
                                            id="type"
                                            {...register('type')}
                                            onFocus={(e) => e.target.style.border = '1px solid #D84FD3'}
                                            onChange={(e) => setValue("type", e.target.value)}
                                        >
                                            <option>Selecione o Tipo</option>
                                            {
                                                servicesType.map(({ label, value }, index) => (
                                                    <option key={index} value={value}>{label}</option>
                                                ))
                                            }
                                        </Input>
                                    </Col>
                                )
                           }
                            <Col style={{ marginBottom: 10, padding: 0 }}>
                                <Label for="date-hour" style={{ fontWeight: 'bold' }}>Data e hora</Label>
                                <DateTime
                                    locale="pt-br"
                                    dateFormat="DD/MM/YYYY"
                                    input={true}
                                    id="date-hour"
                                    isValidDate={(current, date) => moment(date).subtract(1, 'd').isSameOrBefore(moment(current))}
                                    {...register('scheduled_at')}
                                    inputProps={{ placeholder: "Clique para informar a data e hora marcada..." }}
                                    onChange={(date) => setScheduledAt(moment(date._d).format("YYYY-MM-DD HH:mm"))}
                                />
                            </Col>
                            <Col style={{ marginBottom: 10, padding: 0 }}>
                                <Label for="description" style={{ fontWeight: 'bold' }}>Descrição</Label>
                                <Input
                                    name="description"
                                    id="description"
                                    {...register('description')}
                                    placeholder="Descrição"
                                    onFocus={(e) => e.target.style.border = '1px solid #D84FD3'}
                                    onChange={(e) => setValue("description", e.target.value)}
                                />
                            </Col>
                            {
                                title === 'Outros Atendimentos' && (
                                    <Col style={{ marginBottom: 10, padding: 0 }}>
                                        <Label for="typeOfService" style={{ fontWeight: 'bold' }}>Tipo de Atendimento</Label>
                                        <Input
                                            type="select"
                                            name="typeOfService"
                                            id="typeOfService"
                                            {...register('typeOfService')}
                                            placeholder="Descrição"
                                            onFocus={(e) => e.target.style.border = '1px solid #D84FD3'}
                                            onChange={(e) => setValue("typeOfService", e.target.value)}
                                        >
                                            <option>Tipo de atendimento</option>
                                            {
                                                typeOfServices.map(({ key, value }, index) => (
                                                    <option key={index} value={key}>
                                                        {value}
                                                    </option>
                                                ))
                                            }
                                        </Input>
                                    </Col>
                                )
                            }
                        </FormGroup>

                        <Button
                            type="submit"
                            color="success"
                            style={{
                                fontWeight: 'bold',
                                marginTop: 15
                            }}

                        >
                            Adicionar Agendamento
                        </Button>
                    </form>
                </ModalBody>
            </Modal>
        </>
    )
}