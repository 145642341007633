import { useAuth } from "context/AuthContext";
import Dashboard from "views/Dashboard.js";
import Login from "views/pages/Login.js";
import Profile from "views/pages/Profile.js";
import SetPassword from "views/pages/SetPassword";
import UploadDocuments from "views/pages/UploadDocuments";
import routerStack from "./routes/index";
import Calendar from "views/Calendar";
import { Recovery } from "views/pages/Recovery";
import { ConfirmMail } from "views/pages/Recovery/confirmMail";
import { DashboardTemporary } from "views/DashboardTemporary";

const generalRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
    redirect: true,
  },
  {
    path: "/confirm-mail",
    name: "Confirmar Email",
    component: ConfirmMail,
    layout: "/auth",
    redirect: true,
  },
  {
    path: "/recovery",
    name: "Recovery",
    component: Recovery,
    layout: "/auth",
    redirect: true,
  },
  {
    path: "/set-password",
    name: "Setar Password",
    component: SetPassword,
    layout: "/auth",
    redirect: true,
  },
  {
    path: "/calendar",
    name: "Calendário",
    icon: "tim-icons icon-badge",
    component: Calendar,
    layout: "/admin",
  },
  {
    path: "/documentation/upload",
    name: "Envio de documentos",
    component: UploadDocuments,
    layout: "/auth",
    redirect: true,
  },
];

const privateRoutes = [
  {
    path: "/profile",
    name: "Perfil",
    component: Profile,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: process.env.REACT_APP_FEATURE_FLAG_DASHBOARD === "true" ? Dashboard : DashboardTemporary,
    layout: "/admin"
  },
];

const useMainRoutes = () => {
  const { user } = useAuth();

  const admin = [
    ...generalRoutes,
    ...privateRoutes,
    ...routerStack.adminPerformanceRoutes,
  ];

  const broker = [
    ...generalRoutes,
    ...privateRoutes,
    ...routerStack.brokerRoutes,
  ];

  const coordinatorProduct = [
    ...generalRoutes,
    ...privateRoutes,
    ...routerStack.coordinatorProductsRoutes,
  ];

  const managerPos = [
    ...generalRoutes,
    ...privateRoutes,
    ...routerStack.managerPosRoutes,
  ];

  const generalDirector = [
    ...generalRoutes,
    ...privateRoutes,
    ...routerStack.generalDirectorRoutes,
  ];

  const commercialDirector = [
    ...generalRoutes,
    ...privateRoutes,
    ...routerStack.commercialDirectorRoutes,
  ];

  const marketingDirector = [
    ...generalRoutes,
    ...privateRoutes,
    ...routerStack.marketingDirectorRoutes,
  ];

  const salesDirector = [
    ...generalRoutes,
    ...privateRoutes,
    ...routerStack.salesDirectorRoutes,
  ];

  const operationDirector = [
    ...generalRoutes,
    ...privateRoutes,
    ...routerStack.operationDirectorRoutes,
  ];

  const manager = [
    ...generalRoutes,
    ...privateRoutes,
    ...routerStack.managerRoutes,
  ];

  const vpCommercial = [
    ...generalRoutes,
    ...privateRoutes,
    ...routerStack.vpCommercialRoutes,
  ];

  const vpBusiness = [
    ...generalRoutes,
    ...privateRoutes,
    ...routerStack.vpBusinessRoutes,
  ];

  const incorporationService = [
    ...generalRoutes,
    ...privateRoutes,
    ...routerStack.incorporationServiceRoutes,
  ];

  const commercialDirectorIncorporatorRoutes = [
    ...generalRoutes,
    ...privateRoutes,
    ...routerStack.incorporationRoutes,
  ];

  const marketing = [
    ...generalRoutes,
    ...privateRoutes,
    ...routerStack.marketingRoutes,
  ];

  const operation = [
    ...generalRoutes,
    ...privateRoutes,
    ...routerStack.operationDirectorRoutes,
  ];

  switch (user?.role.name) {
    case "ADMIN":
      return admin;
    case "BROKER":
      return broker;
    case "MANAGER_POS":
      return managerPos;
    case "GENERAL_DIRECTOR":
      return generalDirector;
    case "COMMERCIAL_DIRECTOR":
      return commercialDirector;
    case "SALES_DIRECTOR":
      return salesDirector;
    case "MARKETING_DIRECTOR":
      return marketingDirector;
    case "OPERATION_DIRECTOR":
      return operationDirector;
    case "MANAGER":
      return manager;
    case "VP_COMMERCIAL":
      return vpCommercial;
    case "VP_BUSINESS":
      return vpBusiness;
    case "INCORPORATION_SERVICE":
      return incorporationService;
    case "COMMERCIAL_DIRECTOR_INCORPORATOR":
      return commercialDirectorIncorporatorRoutes;
    case "MARKETING":
      return marketing;
    case "OPERATION":
      return operation;
    case "COORDINATOR_PRODUCTS":
      return coordinatorProduct;
    default:
      return generalRoutes;
  }
};

export default useMainRoutes;
