import api from "api"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { Card, CardImg, Col, Row } from "reactstrap"
import PDFViewer from "views/components/PDFViewer"

export const Documents = () => {
    const [clientName, setClientName] = React.useState("")
    const [documents, setDocuments] = useState([])

    const { clientId } = useParams()

    const getDocuments = useCallback(async () => {
        const { data }= await api.get(`/clients/${clientId}`)
         if (data) {
            setDocuments(data.documents)
            setClientName(data.name)
         }
       

    }, [clientId])

    const isImage = (data) => {
        const extension = data.substr(data.lastIndexOf('.') + 1);
        switch (extension) {
            case "png":
            case "jpeg":
            case "jpg":
            case "gif":
                return true;
            default:
                return false;
        }
    }

    useEffect(() => {
        getDocuments()
    }, [getDocuments])

    return (
        <div className="content">
            <h1>Documentos enviados por {clientName}</h1>
            <Row>
                <Col sm="3" >
                    {documents && documents.map((document, index) => (

                        <>
                            {isImage(document) && <CardImg src={document} style={{ margin: '12px 0'}} />}
                            {!isImage(document) && <PDFViewer file={document} isInherit style={{ margin: '12px 0'}} />}
                        </>
                    ))
                    }
                </Col>
            </Row>
        </div>
    )
}