import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Row,
  Button
} from "reactstrap";
import TowerFormComponents from "views/components/TowerFormComponents";


const INITAL_TOWERS = 0
const EditTowers = forwardRef((props, ref) => {
    const type = props.wizardData?.Editar?.type;
    const [towers, setTowers] = useState([]);

    useImperativeHandle(ref, () => ({
      isValidated: () => {
        return true;
      },
      state: {
        towers
      },
    }));

    useEffect(() => {
      if(towers.length === 0)
        [...Array(INITAL_TOWERS)].forEach(() => handleCreateTower())
    },[])
    
    useEffect(() => {
      setTowers(props.wizardData?.Editar?.towers);
    },[props.wizardData?.Editar])

    const handleCreateTower = () => {
      setTowers(oldTowers => [...oldTowers, { id: Math.floor(Math.random() * (1 - 0 + 1) + 0) }])
    }

    const handleEditTower = (towerId, data) => {
      setTowers(oldTowers => [...oldTowers.map((tower) => {
        if(tower.id === towerId) {
          tower.name = data.name
          tower.qtyAptPerFloor = data.qtyAptPerFloor
          tower.qtyFlooring = data.qtyFlooring
        }
        return tower
      })])
    }
    
    return (
      <>
      {
        type === "VERTICAL" ? 
        towers?.map((tower, i) => (
          <>
            <h2>Torre {i + 1}</h2>
            <TowerFormComponents key={tower.id} tower={tower} onData={(data) => handleEditTower(tower.id, data) } operation="edit"/>
          </>

        )) : null
      }
      {
        type === "VERTICAL" ? 
        <Row className="justify-content-center mt-3">
          <Button className="btn btn-info" onClick={handleCreateTower}>
            <i className="tim-icons icon-simple-add"></i>
          </Button>
        </Row>
        : 
        <Row className="justify-content-center mt-2">
          <h2>Empreendimento do tipo horizontal não há torres, pule a etapa.</h2>
        </Row>
      }
      </>
    );
});

export default EditTowers;