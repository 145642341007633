import React from "react";
import { Button, Col, Input, Row } from "reactstrap"
import InputMask from "react-input-mask";

export const PhoneNumberInput = (props) => {
    const [phoneNumber, setPhoneNumber] = React.useState("")
    const [type, setType] = React.useState("")

    
    const translateType = (type) => {
        if (type === "Trabalho" || type === "trabalho") {
            return "Work"
        }

        if (type === "Pessoal" || type === "pessoal") {
            return "Personal" 
        }

        return null
      }

    const { onCreateNewPhoneNumber } = props;

    return (
      <>
        <Row>
            <Col sm="12">
                <Input
                    name="type"
                    style={{ fontWeight: "bold", maxWidth: 310, width: "100%", marginBottom: 4 }}
                    placeholder="Tipo (Pessoal ou Trabalho)"
                    id="type"
                    value={type}
                    type="text"
                    onChange={(e) => setType(e.target.value)}
                />
            </Col>
        </Row>
        <Row>
            <Col sm="12" style={{  display: "flex", alignItems: "center", gap: 6}}>
                <Input
                    name="phoneNumber"
                    style={{ fontWeight: "bold", maxWidth: 310, width: "100%" }}
                    placeholder="Informe o Telefone..."
                    id="telephone"
                    value={phoneNumber}
                    mask="(99) 99999-9999"
                    tag={InputMask}
                    type="text"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <Button size="sm" style={{ padding: "10px 16px"}} onClick={() => onCreateNewPhoneNumber(translateType(type), phoneNumber)}>Adicionar</Button>
            </Col>
        </Row>
      </>
    )
}