import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import OrgChart from "views/components/OrgChart/OrgChart.js";
import api from "../../../api/index";

const buildTree = (data) => {
  const map = {};
  const roots = [];

  data.forEach((item) => {
    map[item.id] = { ...item, children: [] };
  });

  data.forEach((item) => {
    if (item.pid) {
      if (map[item.pid]) {
        map[item.pid].children.push(map[item.id]);
      }
    } else {
      roots.push(map[item.id]);
    }
  });

  return roots
};

const renderTree = (node) => (
  <OrgChart.Node 
    key={node.id} 
    label={<div>{node.name}</div>}
    image={node.img}
    >
    {node.children.map((child) => renderTree(child))}
  </OrgChart.Node>
);

const ViewChart = (props) => {
  const { params } = useRouteMatch();
  const [org, setOrg] = React.useState([]);

  const orgTree = buildTree(org)

  const getData = async () => {
    const { data } = await api.get(`/charts/${params.id}`);
    setOrg(data.org);
  };

  useEffect(() => {
    getData();
  }, []);



  return (
    <>
      <div className="content">
        <OrgChart >
          {orgTree.map((root) => (
            <OrgChart.Node label={<div>{root.name}</div>}>
              {root.children.map((child) => renderTree(child))}
            </OrgChart.Node>
          ))}
        </OrgChart>
      </div>
    </>
  );
};

export default ViewChart;
