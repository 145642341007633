import moment from "moment";
import EntryPeriodStrategy from "./entry-period-strategy.js";

export default class SevenDaysEntryPeriod  {
    
    
    makePeriod() {
        const initialDate = moment(new Date()).subtract(7, "day").startOf("day").subtract(3, "h").toISOString()
        const endDate = moment(new Date()).endOf("day").subtract(3, "h").toISOString()

        return { initialDate, endDate }
    }
}