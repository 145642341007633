import { Button, Modal, ModalBody, ModalFooter, Label } from "reactstrap"
import DateTime from 'react-datetime'
import moment from "moment"

export const ActualPeriodModal = ({ isOpen, onClose, onSubmit, onChangeStartAt, onChangeEndAt }) => {
    return (
        <Modal isOpen={isOpen} modalClassName="modal-black">
            <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                <i className="tim-icons icon-simple-remove"></i>
            </button>
            <h5 className="modal-title">Selecione o período desejado</h5>
            </div>
            <ModalBody>
                <Label for="date-hour" style={{ fontWeight: 'bold' }}>Data e hora inicial</Label>
                <DateTime
                    locale="pt-br"
                    dateFormat="DD/MM/YYYY"
                    input={true}
                    id="date-hour"
                    inputProps={{placeholder:"Clique para informar a data inicial e hora marcada..."}}
                    onChange={(e) => onChangeStartAt(moment(e._d).toISOString())}
                />

                <Label for="date-hour" style={{ fontWeight: 'bold' }}>Data e hora final</Label>
                <DateTime
                    locale="pt-br"
                    dateFormat="DD/MM/YYYY"
                    input={true}
                    id="date-hour"
                    inputProps={{placeholder:"Clique para informar a data final e hora marcada..."}}
                    onChange={(e) => onChangeEndAt(moment(e._d).toISOString())}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => {
                    onSubmit()
                    onClose()
                }}>
                    Filtrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}