import React, { useEffect } from "react";
import 'moment/locale/pt-br';
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, Col, FormGroup, Input, Label, Row } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import api from "api";

const EditStand = () => {
  const [name, setName] = React.useState("");
  const [enterprises, setEnterprises ] = React.useState([]);
  const [enterprise, setEnterprise ] = React.useState("");

  const history = useHistory();

  const notificationAlertRef = React.useRef(null);

  const { params } = useRouteMatch()

  const getData = async () => {
    const { data } = await api.get(`/stands/${params.standId}`);
    setName(data.name);
    setEnterprise(data.enterprise._id)
  };

  const onSubmit = async () => {
    let options = {};
    try {
    const { data, status } = await api.patch(`/stands/${params.standId}`, {
      name,
      enterprise  
    });

    options = {
      place: "tr",
      message: "Stand modificado com sucesso!",
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };

  } catch (e) {
    options = {
      place: "tr",
      message: e.response.data.errors[0],
      type: "danger",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
  }

    notificationAlertRef.current.notificationAlert(options);  
    if (options.type === 'success') {
      setTimeout(() => {
        history.push("/admin/stands");
      }, 2000)
    }
  }

  
  const getEnterprises = async () => {
    const { data } = await api.get("/enterprises")
    setEnterprises(data);
  }

  useEffect(() => {
    getData();
    getEnterprises();
  }, [])

  return (
    <>
      <div className="content"  style={{ width: "100%"}}>
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Card>
            <CardBody>
                <Row>
                    <Col sm="5">
                    <FormGroup>
                        <Label for="name">Nome</Label>
                        <Input 
                            name="name"
                            placeholder="Informe seu nome..."
                            id="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </FormGroup>
                    </Col>
                    <Col sm="5">
                    <FormGroup>
                        <Label for="enterprises">Empreendimento</Label>
                        <Input 
                        name="enterprises"
                        id="enterprises"
                        type="select"
                        value={enterprise}
                        onChange={(e) => setEnterprise(e.target.value)}
                        >
                            {
                                enterprises && enterprises.map(({name, _id}, index) => (
                                <option key={index} value={_id}>
                                    {name}
                                </option>
                                ))
                            }
                        </Input>
                    </FormGroup>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter>
                <Button className="btn btn-fill" onClick={() => onSubmit()}>Salvar</Button>
            </CardFooter>
            </Card>
      </div>
    </>
  )
}

export default EditStand;