import React from "react";
import { Container } from "reactstrap";
import { useQuery } from "../../variables/query";
import { useHistory } from "react-router-dom";
import {
  Dropzone,
  FullScreen,
  FileMosaic
} from "@dropzone-ui/react";
import NotificationAlert from "react-notification-alert";

const UploadDocuments = () => {
  const [extFiles, setExtFiles] = React.useState([]);
  const [imageSrc, setImageSrc] = React.useState(undefined);
  const notificationAlertRef = React.useRef(null);
  const history = useHistory();
  const query = useQuery();

  const updateFiles = (incommingFiles) => {
    setExtFiles(incommingFiles);
  };

  const onDelete = (id) => {
    setExtFiles(extFiles.filter((x) => x.id !== id));
  };

  const handleSee = (imageSource) => {
    setImageSrc(imageSource);
  };

  const handleStart = (filesToUpload) => {
    console.log("advanced demo start upload", filesToUpload);
  };

  const handleFinish = (uploadedFiles) => {
    let options = {};
    options = {
      place: "tr",
      message: "Upload realizado com sucesso, você será contactado via e-mail com o status da sua solicitação!",
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 5
    };
    if(uploadedFiles[0].serverResponse.success === false) {
      options = {
        place: "tr",
        message: "Ocorreu um problema inesperado, contacte o nosso suporte",
        type: "danger",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 10
      };
    } 

    notificationAlertRef.current.notificationAlert(options);

    setTimeout(() => {
      history.push("/auth/login");
    }, 5000);
    console.log("advanced demo finish upload", uploadedFiles);
  };

  const handleAbort = (id) => {
    setExtFiles(
      extFiles.map((ef) => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: "aborted" };
        } else return { ...ef };
      })
    );
  };
  const handleCancel = (id) => {
    setExtFiles(
      extFiles.map((ef) => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: undefined };
        } else return { ...ef };
      })
    );
  };
  const SERVER_URL = process.env.REACT_APP_API_URL
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="content">
        <Container>
          <h4>Realize os uploads do seus documentos para aprovação:</h4>
          <Dropzone
              onChange={updateFiles}
              minHeight="250px"
              value={extFiles}
              accept="image/*, application/pdf"
              maxFiles={4}
              maxFileSize={2 * 1300 * 1300}
              label="Arraste e solte os arquivos aqui ou clique para navegar"
              uploadConfig={{
                cleanOnUpload: false,
                url: SERVER_URL + `/upload/documents?token=${query.get("token")}`,
              }}
              onUploadStart={handleStart}
              onUploadFinish={handleFinish}
              actionButtons={{
                position: "after",
                abortButton: {},
                deleteButton: {},
                uploadButton: {}
              }}
            >
              {extFiles.map((file) => (
                <FileMosaic
                  {...file}
                  key={file.id}
                  onDelete={onDelete}
                  onSee={handleSee}
                  onAbort={handleAbort}
                  onCancel={handleCancel}
                  resultOnTooltip
                  alwaysActive
                  preview
                  info
                  hdww
                />
              ))}
          </Dropzone>
          <FullScreen
            imgSource={imageSrc}
            openImage={imageSrc}
            onClose={(e) => handleSee(undefined)}
          />
        </Container>
      </div>
    </>
  )
}

export default UploadDocuments;