import React from "react"
import { Button, Col, FormGroup, Input, Label } from "reactstrap"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useForm } from "react-hook-form";
import api from "api";
import { useClientData } from "../hooks/useClientData";
import DateTime from 'react-datetime'
import moment from "moment";

const servicesType = [
    {
        label: "E-mail",
        value: "E-mail",
    },
    {
        label: "Ligar",
        value: "Call",
    },
    {
        label: "Agendado",
        value: "Scheduled"
    }
]

export const ActivityForm = ({ addActivity }) => {

    const { clientData } = useClientData()

    const [data, setData] = React.useState("")
    const [type, setType] = React.useState("")
    const [scheduledAt, setScheduledAt] = React.useState("")
    const { handleSubmit, register, setValue } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            description: '',
            type: '',
            scheduled_at: "",
        }
    })


    const handleChange = (event, editor) => {
        setData(editor.getData())
    }

    const handleClick = () => {
        setValue('description', data)
        setValue('type', type)
        setValue('scheduled_at', scheduledAt)
    }

    const onSubmit = async (e) => {
        const formatedDate = new Date(e.scheduled_at).toISOString()
        const { data } = await api.post('/activity', {
            type,
            scheduledAt: formatedDate,
            description: e.description,
            client: clientData._id
        })

        addActivity(data)

        setData("")
        setType("")
    }

    return (
        <form onSubmit={handleSubmit((e) => onSubmit(e))} className="px-2">
            <FormGroup style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Col sm="6" className="pl-0">
                    <Label for="type" style={{ fontWeight: 'bold' }}>Tipo</Label>
                    <Input      
                        type="select"
                        name="type"
                        id="type"
                        value={type}
                        {...register('type')}
                        onChange={(e) => setType(e.target.value)}
                        onFocus={(e) => e.target.style.border = '1px solid #D84FD3'}
                    >
                        <option>Selecione o Tipo</option>
                        {
                            servicesType.map(({ label, value }, index) => (
                                <option key={index} value={value}>{label}</option>
                            ))
                        }
                    </Input>
                </Col>
                <Col sm="6" className="p-0">
                    <Label for="date-hour" style={{ fontWeight: 'bold' }}>Data e hora</Label>
                    <DateTime
                        locale="pt-br"
                        dateFormat="DD/MM/YYYY"
                        input={true}
                        isValidDate={(current, date) => moment(date).subtract(1, 'd').isSameOrBefore(moment(current))}
                    
                        id="date-hour"
                        {...register('scheduled_at')}
                        inputProps={{placeholder:"Clique para informar a data e hora marcada..."}}
                        onChange={(date) => setScheduledAt(moment(date._d).format("YYYY-MM-DD HH:mm"))}
                  />
                </Col>
            </FormGroup>
            <h4>Descrição</h4>
            <div>
                <CKEditor
                    editor={ClassicEditor}
                    data={data}
                    {...register('description')}
                    config={{
                        toolbar: {
                            items: [
                                'undo', 'redo',
                                '|', 'heading',
                                '|', 'bold', 'italic',
                                '|', 'insertImage',
                                '|', 'bulletedList', 'numberedList', 'outdent', 'indent'
                            ]
                        }
                    }}
                    onChange={(event, editor) => handleChange(event, editor)}
                />
            </div>
            <Button
                type="submit"
                color="success"
                style={{
                    fontWeight: 'bold',
                    marginTop: 15
                }}
                onClick={handleClick}

            >
                Adicionar Atividade
            </Button>
        </form>
    )
}
