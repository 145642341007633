import React from "react";
import CurrencyInput from "react-currency-input";
import { Button, FormGroup, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useBusinessClose } from "../hooks/useCloseBusiness";
import { useClientData } from "../hooks/useClientData";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "context/AuthContext";

export const ModalBusiness = (props) => {
    const [ totalValue, setTotalValue] = React.useState("")
    const [ totalValueFormatted, setTotalValueFormatted ] = React.useState("");
    const { user } = useAuth();

    const { handleCloseBusiness } = useBusinessClose();

    const { clientData } = useClientData();

    const history = useHistory();

    const { 
        isOpen,
        setToggleModal,
        addHistoricalCard
    } = props; 

    React.useEffect(() => {
        if (totalValueFormatted.length > 2) {
          setTotalValue(totalValueFormatted.substring(0, totalValueFormatted.length - 2).replace(",", "").replace("R$", "").replace(".", ""))
        }
    }, [totalValueFormatted])

    const onSubmit = async () => {  
        const valuesFormattedOnArray = totalValueFormatted.replace("R$", "").replace(",", ".").split('.')
        const totalValueOnNumber = Number(valuesFormattedOnArray.join(""))  
        
        await handleCloseBusiness(totalValueOnNumber)
        addHistoricalCard({
            text: `${user.name} fechou negócio com o cliente "${clientData?.name}" com o ticket médio custando R$${totalValue},00`,
            updatedAt: new Date().toISOString()
        })
        setToggleModal()
        setTotalValue("")

        setTimeout(() => history.push("/admin/businessPanel"), 1500)
        
    }

    return (
        <Modal isOpen={isOpen} modalClassName="modal-black">
            <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                <i className="tim-icons icon-simple-remove"onClick={setToggleModal}></i>
            </button>
            <h5 className="modal-title">Valor total</h5>
            </div>
            <ModalBody>
                <FormGroup>
                    <CurrencyInput 
                        name="totalValue"
                        placeholder="Valor total"
                        className="form-control"
                        id="totalValue"
                        inputType="text"
                        prefix="R$"
                        decimalSeparator=","
                        thousandSeparator="."
                        value={totalValueFormatted}
                        onChangeEvent={(e) => setTotalValueFormatted(e.target.value)}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onSubmit}>Confirmar</Button>
            </ModalFooter>
        </Modal>
    )
}