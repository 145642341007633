import React from "react"
import { Card } from "reactstrap"
import { ButtonComponent } from "./Button"
import { IconsSection } from "./IconsSection";

export const Header = (props) => {
    const {
        isHistory,
        isActivity,
        onChangeHistory,
        onChangeActivity,
        addHistoricalCard
    } = props;

    return (
        <Card
            style={{ 
                margin: "0 5px",
                padding: 10,
                display: "flex", 
                justifyContent: "space-around", 
                alignItems: "center", 
                flexDirection: "row"
            }}
        >
            <div>
                <ButtonComponent 
                    value="Histórico" 
                    active={isHistory} 
                    onChange={onChangeHistory}
                />
                <ButtonComponent 
                    value="Atividade" 
                    active={isActivity}
                    onChange={onChangeActivity}
                />
            </div>
            <IconsSection addHistoricalCard={addHistoricalCard} />
        </Card>
    )
}