import { FormControl, MenuItem, Select } from "@mui/material";
import api from "api";
import moment from "moment";
import React from "react";
import DateTime from 'react-datetime';
import { useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter } from "reactstrap";
import { HasPermission } from "variables/permissions";
import { UseScreenWidth } from "variables/screen-width";
import { mediasOrigin } from "views/pages/Clients/Create/CreateClient";
import EntryPeriodStrategy from "./strategies/entry-period/entry-period-strategy";


export const ModalReport = (props) => {

  const {
    isOpen,
    setToggleModal,
    notificationAlertRef
  } = props;

  const { screenWidth } = UseScreenWidth()

  const { register, setValue, getValues, handleSubmit } = useForm();
  const [startDate, setStartDate] = React.useState(new Date().toISOString())
  const [endDate, setEndDate] = React.useState(new Date().toISOString())
  const [otherPeriod, setOtherPeriod] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(false);

  const adjustWidthByPermissionAndScreenSize = (profile) => {
    return screenWidth > 450 && HasPermission(`performance.report.filter.${profile}`)
  }

  const handleChangePeriod = (value) => {
    setPeriod(value)
  }

  const setPeriod = (value) => {
    if (value === "Período Específico") {
      setOtherPeriod(true)
      return
    }

    const { initialDate, endDate } = EntryPeriodStrategy(value)
    setStartDate(initialDate)
    setEndDate(endDate)
  }

  const periodOptions = [
    {
      value: "Ultimos 7 dias",
    },
    {
      value: "Ultimos 15 dias",
    },
    {
      value: "Este mês",

    },
    {
      value: "Período Específico",
    },
  ];

  const getData = async (params) => {

    const enterprises = params.enterprises ? params.enterprises.split(',') : undefined;
    const incorporators = params.incorporators ? params.incorporators.split(',') : undefined;
    const realStates = params.realStates ? params.realStates.split(',') : undefined;
    const managersPos = params.managersPos ? params.managersPos.split(',') : undefined;
    const origins = params.origins ? params.origins.split(',') : undefined;

    let options;
    try {
      await api.post(`/reports`, {
        enterprises,
        startDate,
        endDate,
        incorporators,
        realStates,
        managersPos,
        origins,
      });

    } catch (err) {
      options = {
        place: "tr",
        message: err.response.data.message,
        type: "danger",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7
      };
    } finally {
      setTimeout(clearValues, 3000)
    }

    if (options?.type === 'success') {
      setTimeout(() => setToggleModal(false), 2000)
    }

    notificationAlertRef.current.notificationAlert(options);

  };

  const onSubmit = async () => {
    try {
      setIsDisabled(true)
      const params = getValues()

      getData(params);
    } catch (error) {
      console.error(error)
    } finally {
      setTimeout(() => {
        setIsDisabled(false)
      }, 2000)
    }
  };

  const handleSubmitReport = (e) => {
    handleSubmit(onSubmit(e))
  }

  const clearValues = () => {
    setValue('enterprises', undefined)
    setValue('incorporators', undefined)
    setValue('realStates', undefined)
    setValue('managersPos', undefined)
    setValue('origins', undefined)
    setValue('owners', undefined)
    setValue('managers', undefined)
    setValue('commercialDirector', undefined)
    setValue('salesDirector', undefined)

    setStartDate(new Date().toISOString())
    setEndDate(new Date().toISOString())

    setOtherPeriod(false)

  }

  const loadOptions = (inputValue, callback, url) => {
    api.get(url).then((response) => {
      const options = [];
      response.data.forEach((option) => {
        options.push({
          label: option.corporateName || option.name,
          value: option._id,
        });
      });

      let filterOptions = options.filter((o) =>
        Object.keys(o).some((k) =>
          o[k].toLowerCase().includes(inputValue.toLowerCase())
        )
      );

      callback(filterOptions);
    });
  };

  const loadMediaOrigin = (inputValue, callback, url) => {

    const options = [];
    mediasOrigin.forEach((option) => {
      options.push({
        label: option.label,
        value: option.value,
      });
    });

    let filterOptions = options.filter((o) =>
      Object.keys(o).some((k) =>
        o[k].toLowerCase().includes(inputValue.toLowerCase())
      )
    );

    callback(filterOptions)
  };

  const onChangeSelect = (e, field) => {
    setValue(field, `${[e.map(({ value }) => value)]}`);
  };

  return (
    <Modal isOpen={isOpen} modalClassName="modal-black" size="md" style={{ position: 'relative', top: 0, marginBottom: 15 }}>
      <div className="modal-header">
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={setToggleModal}>
          <i className="tim-icons icon-simple-remove"></i>
        </button>
        <h4 className="modal-title" style={{ fontWeight: 'bold', margin: '0 auto' }}>Gerar Relatório</h4>
      </div>
      <ModalBody>     
        <FormGroup>
          <div>
            <div>
              <Label for="enterprise" style={{ fontWeight: 'bold', paddingTop: 10 }}>Empreendimento</Label>
              <AsyncSelect
                classNamePrefix="react-select"
                className="react-select info"
                placeholder="Selecionar Empreendimento"
                name="enterprises"
                isSearchable
                isMulti
                closeMenuOnSelect={false}

                cacheOptions
                defaultOptions
                loadOptions={(inputValue, callback, url) =>
                  loadOptions(inputValue, callback, "enterprises")
                }
                {...register("enterprises")}  
                onChange={(e, _) => onChangeSelect(e, "enterprises")}
              />
            </div>

          </div>
          <FormControl style={{ width: '100%' }}>
            <Label style={{ fontWeight: 'bold', paddingTop: 10 }}>Período de entrada do cliente</Label>
            <Select
              style={{
                width: '100%',
                height: 40,
                border: "1px solid #272261",
                borderCollapse: 'separate',
                marginBottom: '15px',
                color: '#1C82E7',
                backgroundColor: 'transparent',
              }}
              name="period"
              id="period"
              placeholder="Periodo de entrada do cliente"
              onChange={(e) => handleChangePeriod(e.target.value)}
            >
              {
                periodOptions.map(({ value }, index) => (
                  <MenuItem key={index} value={value} style={{ color: 'black' }}>{value}</MenuItem>
                ))
              }
            </Select>
            {
              otherPeriod && (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <DateTime
                      locale="pt-br"
                      dateFormat="DD/MM/YYYY"
                      input={true}
                      id="date-hour"
                      isValidDate={(current, date) => moment(date).isSameOrAfter(moment(current))}
                      {...register('startDate')}
                      inputProps={{ placeholder: "Data Inicial" }}
                      onChange={(date) => setStartDate(moment(date._d).format())}
                    />
                  </div>
                  <span style={{ padding: "0 10px" }}>Até</span>
                  <div>
                    <DateTime
                      locale="pt-br"
                      dateFormat="DD/MM/YYYY"
                      input={true}
                      id="date-hour"
                      isValidDate={(current, date) => moment(date).isSameOrAfter(moment(current))}
                      {...register('endDate')}
                      inputProps={{ placeholder: "Data Final", required: true }}
                      className="inline"
                      onChange={(date) => setEndDate(moment(date._d).format())}
                    />
                  </div>
                </div>
              )
            }

          </FormControl>
          <Label for="mediaOrigin" style={{ paddingTop: 10 }}>Mídia Origem</Label>
          <AsyncSelect
            classNamePrefix="react-select"
            className="react-select info"
            placeholder="Selecionar Mídia Origem"
            name="enterprises"
            isSearchable
            isMulti
            closeMenuOnSelect={false}
            cacheOptions
            defaultOptions
            loadOptions={loadMediaOrigin}
            {...register("origins")}
            onChange={(e, _) => onChangeSelect(e, "origins")}
          />
          {
            (HasPermission('report.filter.managerPos') || HasPermission('report.filter.incorporator')) && (
              <div style={{ display: 'flex', flexDirection: adjustWidthByPermissionAndScreenSize('incorporator') && adjustWidthByPermissionAndScreenSize('managerPos') ? 'row' : 'column', justifyContent: 'space-beetwen', width: '100%', gap: 16, paddingTop: 10 }}>
                {
                  HasPermission('report.filter.managerPos') && (
                    <div style={{ width: adjustWidthByPermissionAndScreenSize('incorporator') ? "48%" : "100%" }}>
                      <Label for="posManagers">Gestor de PDV</Label>
                      <AsyncSelect
                        classNamePrefix="react-select"
                        className="react-select info"
                        placeholder="Selecionar Gestor de PDV"
                        name="posManagers"
                        isSearchable
                        isMulti
                        closeMenuOnSelect={false}
                        cacheOptions
                        defaultOptions
                        loadOptions={(inputValue, callback, url) =>
                          loadOptions(inputValue, callback, "users/find/pos-managers")
                        }
                        {...register("managersPos")}
                        onChange={(e, _) =>
                          onChangeSelect(e, "managersPos")
                        }
                      />
                    </div>
                  )
                }
                {
                  HasPermission('report.filter.incorporator') && (
                    <div style={{ width: adjustWidthByPermissionAndScreenSize('managerPos') ? "48%" : "100%" }}>
                      <Label for="incorporators" >Incorporador</Label>
                      <AsyncSelect
                        classNamePrefix="react-select"
                        className="react-select info"
                        placeholder="Selecionar Incorporador"
                        name="incorporators"
                        isSearchable
                        isMulti
                        closeMenuOnSelect={false}
                        cacheOptions
                        defaultOptions
                        loadOptions={(inputValue, callback, url) =>
                          loadOptions(
                            inputValue,
                            callback,
                            "partners/find/incorporators"
                          )
                        }
                        {...register("incorporators")}
                        onChange={(e, _) =>
                          onChangeSelect(e, "incorporators")
                        }
                      />
                    </div>
                  )
                }

              </div>
            )
          }
          {
            HasPermission('report.filter.realState') && (
              <div>
                <Label for="realState" style={{ paddingTop: 10 }}>Imobiliária</Label>
                <AsyncSelect
                  classNamePrefix="react-select"
                  className="react-select info"
                  placeholder="Selecionar Imobiliária"
                  name="realState"
                  isSearchable
                  isMulti
                  closeMenuOnSelect={false}
                  cacheOptions
                  defaultOptions
                  loadOptions={(inputValue, callback, url) =>
                    loadOptions(inputValue, callback, "partners/find/realstate")
                  }
                  {...register("realStates")}
                  onChange={(e, _) =>
                    onChangeSelect(e, "realStates", "realStates")
                  }
                />
              </div>
            )
          }
          {
            (HasPermission('report.filter.owner') || HasPermission('report.filter.manager')) && (
              <div style={{ display: 'flex', flexDirection: adjustWidthByPermissionAndScreenSize('owner') && adjustWidthByPermissionAndScreenSize('manager') ? 'row' : 'column', justifyContent: 'space-beetwen', width: '100%', gap: 16, paddingTop: 10 }}>
                {
                  HasPermission('report.filter.owner') && (
                    <div style={{ width: adjustWidthByPermissionAndScreenSize('manager') ? "48%" : "100%" }}>
                      <Label for="owner">Donos</Label>
                      <AsyncSelect
                        classNamePrefix="react-select"
                        className="react-select info"
                        placeholder="Selecionar Donos"
                        name="owner"
                        isSearchable
                        isMulti
                        closeMenuOnSelect={false}
                        cacheOptions
                        defaultOptions
                        loadOptions={(inputValue, callback, url) =>
                          loadOptions(inputValue, callback, "users/possible-owners")
                        }
                        {...register("owners")}
                        onChange={(e, _) =>
                          onChangeSelect(e, "owners")
                        }
                      />
                    </div>
                  )
                }
                {
                  HasPermission('report.filter.manager') && (
                    <div style={{ width: adjustWidthByPermissionAndScreenSize('owner') ? "48%" : "100%" }}>
                      <Label for="managers">Gerentes</Label>
                      <AsyncSelect
                        classNamePrefix="react-select"
                        className="react-select info"
                        placeholder="Selecionar Gerentes"
                        name="managers"
                        isSearchable
                        isMulti
                        closeMenuOnSelect={false}
                        cacheOptions
                        defaultOptions
                        loadOptions={(inputValue, callback, url) =>
                          loadOptions(
                            inputValue,
                            callback,
                            "users/find/manager"
                          )
                        }
                        {...register("managers")}
                        onChange={(e, _) =>
                          onChangeSelect(e, "managers")
                        }
                      />
                    </div>
                  )
                }

              </div>
            )
          }
          {
            (HasPermission('report.filter.commercialDirector') || HasPermission('report.filter.salesDirector')) && (
              <div style={{ display: 'flex', flexDirection: adjustWidthByPermissionAndScreenSize('commercialDirector') && adjustWidthByPermissionAndScreenSize('salesDirector') ? 'row' : 'column', justifyContent: 'space-beetwen', width: '100%', gap: 16, paddingTop: 10 }}>
                {
                  HasPermission('report.filter.commercialDirector') && (
                    <div style={{ width: adjustWidthByPermissionAndScreenSize('salesDirector') ? "48%" : "100%" }}>
                      <Label for="commercialDirector">Diretores Comerciais</Label>
                      <AsyncSelect
                        classNamePrefix="react-select"
                        className="react-select info"
                        placeholder="Selecionar Diretores Comerciais"
                        name="commercialDirector"
                        isSearchable
                        isMulti
                        closeMenuOnSelect={false}
                        cacheOptions
                        defaultOptions
                        loadOptions={(inputValue, callback, url) =>
                          loadOptions(inputValue, callback, "users/find/commercial-director")
                        }
                        {...register("commercialDirector")}
                        onChange={(e, _) =>
                          onChangeSelect(e, "commercialDirector")
                        }
                      />
                    </div>
                  )
                }
                {
                  HasPermission('report.filter.salesDirector') && (
                    <div style={{ width: adjustWidthByPermissionAndScreenSize('commercialDirector') ? "48%" : "100%" }}>
                      <Label for="salesDirector">Diretores de Venda</Label>
                      <AsyncSelect
                        classNamePrefix="react-select"
                        className="react-select info"
                        placeholder="Selecionar Diretores de Venda"
                        name="salesDirector"
                        isSearchable
                        isMulti
                        closeMenuOnSelect={false}
                        cacheOptions
                        defaultOptions
                        loadOptions={(inputValue, callback, url) =>
                          loadOptions(
                            inputValue,
                            callback,
                            "users/find/sales-director"
                          )
                        }
                        {...register("salesDirector")}
                        onChange={(e, _) =>
                          onChangeSelect(e, "salesDirector")
                        }
                      />
                    </div>
                  )
                }

              </div>
            )
          }
          <ModalFooter className="mt-4">
            <Button
              color="primary"
              onClick={(e) => handleSubmitReport(e)}
              disabled={isDisabled}
              style={{ cursor: isDisabled ? "progress" : "pointer" }}
            >
              {isDisabled ? "Gerando Relatório..." : "Gerar Relatório"}
            </Button>
          </ModalFooter>
        </FormGroup>
      </ModalBody>

    </Modal>
  )
}