import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth/Auth.js";
import AdminLayout from "layouts/Admin/Admin.js";

import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.2.0";
import "assets/demo/demo.css";
import { AuthProvider } from "context/AuthContext";
import PrivateRoute from "./components/PrivateRoute.js";
import Dashboard from "views/Dashboard";
import { NotificationProvider } from "context/NotificationContext.js";

ReactDOM.render(
  <AuthProvider>
    <NotificationProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <Redirect from="/" to="/auth/login" />
        </Switch>
      </BrowserRouter>
    </NotificationProvider>
  </AuthProvider>,
  document.getElementById("root")
);
