import React, { useEffect } from "react";
import classNames from "classnames";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Label,
  Input,
} from "reactstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import { Link } from "react-router-dom";
import api from "../../../api/index";
import { HasPermission } from "variables/permissions";
import { TableHeader } from "views/components/TableHeader";
import { ModalDeleteItem } from "views/components/ModalDeleteItem";
import { FilterTable } from "views/components/FilterTables";
import { useForm } from "react-hook-form";
import { filterByUrlParams } from "variables/filter-by-url-params";
import AsyncSelect from "react-select/async";
import { Base64 } from "js-base64";

const Users = () => {
  const [data, setData] = React.useState([]);
  const [base64, setBase64] = React.useState("");
  const [toggleModalRemove, setToggleModalRemove] = React.useState(false);
  const [removeId, setRemoveId] = React.useState(null);
  const { register, handleSubmit, setValue, getValues } = useForm();

  const removeData = async (id) => {
    const { status } =  await api.delete(`/users/${id}`);
    if(status === 200) {
      setToggleModalRemove(false);
      getData();
    }
    setRemoveId(null);
  };

  const getData = async (params = "") => {
    onExport(params)
    const { data } = await api.get(`/users?${params}`);
    setData(data);
  };

  const loadOptions = (inputValue, callback, url, isType) => {
    api.get(url)
    .then((response) => {
      const options = []
      response.data.forEach((option) => {
        options.push({
          label: option.translateRole || option.corporateName,
          value: option._id
        })
      })


      let filterOptions = options.filter(o =>
        Object.keys(o).some(k => o[k].toLowerCase().includes(inputValue.toLowerCase())));
        
      callback(filterOptions);
    })
  }

  const onExport = async (params = "") => {
    const { data } = await api.get(`/users?${params}`, {
       headers: {
        'X-Custom-Response': 'csv'
       }
    });

    const csv = Base64.decode(data).toString()

    setBase64(csv)
  }

  const onSubmit = (e) => {
    const params = filterByUrlParams(e)

    getData(params)
  }

  const onChangeText = (e, field) => {
    setValue(field, `*${e.target.value}`)
    const params = getValues();
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      onSubmit(params)
    }
  }

  
  const onChangeSelect = (e, field) => {
    setValue(field, `${e.map(({ value }) => value)}`)
    const params = getValues();
    onSubmit(params)
  }

  useEffect(() => {
    getData()
  }, [handleSubmit])

  return (
    <>
      <div className="content">
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <FilterTable onSubmit={handleSubmit((e) => onSubmit(e))}>
                <Row> 
                    {
                      HasPermission("users.filter.name") && (
                        <Col sm="4">
                          <Label for="name">Nome</Label>
                            <Input 
                              type="text"
                              name="name"
                              placeholder="Nome"
                              id="name"
                              {...register('name')}
                              onChange={(e, _) => onChangeText(e, 'name')}
                            />
                        </Col>
                      )
                    }
                    {
                      HasPermission('users.filter.email') && (
                        <Col sm="4">
                          <Label for="email">Email</Label>
                          <Input 
                            type="text"
                            name="email"
                            placeholder="Email"
                            id="email"
                            {...register('email')}
                            onChange={(e, _) => onChangeText(e, 'email')}
                          />
                        </Col>
                      )
                    }
                    {
                      HasPermission("users.filter.role") && (
                        <Col>
                            <Label for="role">Cargo</Label>
                            <AsyncSelect 
                              classNamePrefix="react-select"
                              className="react-select info"
                              placeholder="Selecionar Cargo"
                              name="roles"
                              isSearchable
                              closeMenuOnSelect={false}
                              cacheOptions
                              isMulti
                              defaultOptions
                              loadOptions={(inputValue, callback, url) => loadOptions(inputValue, callback, 'roles')}
                              {...register('role')}
                              onChange={(e, _) => onChangeSelect(e, 'role')}
                            />
                        </Col> 
                      )
                    }
                </Row>
                {
                  HasPermission("users.filter.nameFantasy") && (
                    <Row>
                      <Col sm="4">
                        <Label for="partner">Empresas</Label>
                        <AsyncSelect 
                          classNamePrefix="react-select"
                          className="react-select info"
                          placeholder="Selecionar Empresas"
                          name="partners"
                          isSearchable
                          closeMenuOnSelect={false}
                          cacheOptions
                          isMulti
                          defaultOptions
                          loadOptions={(inputValue, callback, url) => loadOptions(inputValue, callback, 'partners')}
                          {...register('partner')}
                          onChange={(e, _) => onChangeSelect(e, 'partner')}
                        />
                      </Col> 
                  </Row>
                  )
                }
            </FilterTable>
            <Card>
              <TableHeader 
                title="Listar Usuários"
                permission="users.create"
                linkToCreate="/admin/user/new"
                hasCSV="users.export"
                csv={base64}
              />
              <CardBody>
                <ReactTable
                  data={data}
                  filterable
                  columns={[
                    {
                      Header: "Nome",
                      Cell: data => {
                        return <p>{data.row.original.name}</p>
                      },
                    },
                    {
                      Header: "Email",
                      Cell: data => {
                        return <p>{data.row.original.email}</p>
                      },
                    },
                    {
                      Header: "Cargo",
                      Cell: data => {
                        return <p>{data.row.original.role?.translateRole}</p>
                      },
                    },
                    {
                      Header: "Empresa",
                      Cell: data => {
                        return <p>{data.row.original.partner?.corporateName}</p>
                      },
                    },
                    {
                      Header: "Ações",
                      sortable: false,
                      filterable: false,
                      Cell: data => (
                        <div className="actions-right">
                         {
                          HasPermission('users.edit') && (
                              <Link to={`/admin/user/${data.row.original._id}/edit`}>
                                <Button
                                  color="warning"
                                  size="sm"
                                  className={classNames("btn-icon btn-link edit", {
                                    "btn-neutral":  false
                                  })}
                                >
                                  <i className="tim-icons icon-pencil" />
                                </Button>
                              </Link>
                          )
                         }
                         {
                          HasPermission(
                              "users.delete",
                              {
                                vpCommercial: data.row.original.partner?.typePartner === "House",
                              }
                            ) 
                             && (
                              <Button
                                onClick={() => {
                                  setToggleModalRemove(true);
                                  setRemoveId(data.row.original._id);
                                }}
                                color="danger"
                                size="sm"
                                className={classNames("btn-icon btn-link remove", {
                                  "btn-neutral":  false
                                })}
                              >
                                <i className="tim-icons icon-simple-remove" />
                              </Button>
                          )
                         }
                          
                        </div>
                      )
                    }
                  ]}
                  defaultPageSize={10}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <ModalDeleteItem 
        title="Deseja realmente excluir este usuário?"
        isOpen={toggleModalRemove}
        setToggleModal={() => setToggleModalRemove(false)}
        removeData={() => removeData(removeId)}
      />
    </>
  );
}
export default Users;