import BusinessPanel from "views/pages/BusinessPanel";
import Clients from "views/pages/Clients";
import { BusinessClosed } from "views/pages/Clients/BusinessClosed";
import { BusinessDiscarded } from "views/pages/Clients/BusinessDiscarded";
import WizardClient from "views/pages/Clients/Create/WizardClient";
import Enterprises from "views/pages/Enterprises";


export const coordinatorProductsRoutes = [
    {
        path: "/enterprises",
        name: "Empreendimentos",
        icon: "fa fa-building",
        component: Enterprises,
        layout: "/admin",
      },
      {
        collapse: true,           
        name: "Clientes",
        icon: "tim-icons icon-badge",
        state: "pagesCollapse",
        views: [
          {
            path: "/clients",
            name: "Listagem de Clientes",
            mini: "C",
            component: Clients,
            layout: "/admin"
          },
          {
            path: "/businessPanel",
            name: "Painel de Negócios",
            mini: "PN",
            component: BusinessPanel,
            layout: "/admin"
          },
          {
            path: "/business-closed",
            name: "Negócios Fechados",
            mini: "NF",
            component: BusinessClosed,
            layout: "/admin"
          },
          {
            path: "/business-discarded",
            name: "Negócios Descartados",
            mini: "ND",
            component: BusinessDiscarded,
            layout: "/admin"
          },
        ] 
      },
      {
        path: "/client/new",
        name: "Criar Cliente",
        component: WizardClient,
        layout: "/admin",
        invisible: true
      },
]