import React from "react";
import { CardTimeLine } from "./CardTimeLine";

export const ActivityTimeLine = (props) => {

    const {
        cards,
        setCards,
        activities,
        isLoading,
        isActivity
    } = props;

    React.useEffect(() => {
        setCards(activities)
    }, [activities, setCards])

    return (
        <div style={{ overflowY: "scroll", maxHeight: 400, marginTop: 30 }}>
            {
                isLoading && (<p style={{ margin: 0, padding: 0 }}>Carregando cards da timeline...</p>)
            }
            {
                cards && !isLoading && cards.map((item, index) => (
                    <CardTimeLine key={index} content={item} isActivity={isActivity} />
                ))
            }
        </div>
    )
}