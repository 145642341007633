import { CSVLink } from "react-csv";
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { Button, CardHeader, CardTitle } from "reactstrap"
import { HasPermission } from "variables/permissions"

export const TableHeader = (props) => {

    const { 
        title, 
        permission,
        linkToCreate,
        openModal,
        hasCSV,
        hasSendClient,
        csv,
    } = props;

    return (
        <CardHeader>
                <CardTitle>
                  <div 
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4 className="d-inline-flex px-2 mb-0">{title}</h4>
                    <div>
                      {
                        hasCSV && HasPermission(hasCSV) && (
                            <CSVLink className="float-lg-right btn btn-primary ml-2" data={csv}>Exportar</CSVLink>
                        )
                      }
                      {
                        HasPermission(permission) && (
                          <Link to={linkToCreate}>
                            <Button className="float-lg-right btn-primary">Criar</Button>
                          </Link>
                        )
                      }
                      {
                         hasSendClient && HasPermission(permission) && (
                          <Button className="float-lg-right btn-primary" onClick={openModal}>Encaminhar Clientes</Button>
                        )
                      }
                    </div>
                  </div>
                </CardTitle>
              </CardHeader>
    )
}