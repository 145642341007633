import Partners from "views/pages/Partners";
import Users from "views/pages/Users";
import CreateUser from "views/pages/Users/CreateUser";
import WizardCreatePartner from "views/pages/Partners/Create/WizardPartner.js";
import Enterprises from "views/pages/Enterprises";
import Stands from "views/pages/Stands";
import CreateStand from "views/pages/Stands/Create";
import BusinessPanel from "views/pages/BusinessPanel";
import Clients from "views/pages/Clients";
import WizardClient from "views/pages/Clients/Create/WizardClient";
import { CustomerManagementPage } from "views/pages/CustomerManagement";

export const commercialDirectorRoutes = [
    {
        collapse: true,
        name: "Usuários",
        icon: "tim-icons icon-single-02",
        state: "pagesCollapse",
        views: [
          {
            path: "/users",
            name: "Listar usuários",
            mini: "U",
            component: Users,
            layout: "/admin"
          },
        ] 
      },
      {
        path: "/user/new",
        name: "Criar Usuários",
        component: CreateUser,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/new",
        name: "Criar Usuários",
        component: CreateUser,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/partners",
        name: "Parceiros",
        component: Partners,
        icon: "fa fa-briefcase",
        layout: "/admin",
      },
      {
        path: "/partner/new",
        name: "Criar Parceiro",
        component: WizardCreatePartner,
        layout: "/admin",
        invisible: true,
      },
      {
        path: "/enterprises",
        name: "Empreendimentos",
        icon: "fa fa-building",
        component: Enterprises,
        layout: "/admin",
      },
      {
        path: "/stands",
        name: "Stands",
        icon: "tim-icons icon-badge",
        component: Stands,
        layout: "/admin"
      },
      {
        path: "/stand/new",
        name: "Criar Stand",
        component: CreateStand,
        layout: "/admin",
        invisible: true
      },
      {
        collapse: true,           
        name: "Clientes",
        icon: "tim-icons icon-badge",
        state: "pagesCollapse",
        views: [
          {
            path: "/clients",
            name: "Listagem de Clientes",
            mini: "C",
            component: Clients,
            layout: "/admin"
          },
          {
            path: "/businessPanel",
            name: "Painel de Negócios",
            mini: "PN",
            component: BusinessPanel,
            layout: "/admin"
          },
        ] 
      },
      {
        path: "/client/new",
        name: "Criar Cliente",
        component: WizardClient,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/customerManagement/:clientId",
        name: "Gestão de Cliente",
        component: CustomerManagementPage,
        layout: "/admin",
        invisible: true
      },
]