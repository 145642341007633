import api from "api";
import moment from "moment";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { CardBody, Card, Button } from "reactstrap";
import { filterByUrlParams } from "variables/filter-by-url-params";
import { HasPermission } from "variables/permissions";

const PanelCardBody = (props) => {
  const { data, queryParams,filterParams, setVgv, setCardsLength } = props;   

  const status = data[0]?.business.status
  const [page, setPage] = React.useState(1);
  const [showButton, setShowButton] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const history = useHistory();

  const getCardsByStatus = async () => {
    setPage(state => state + 1)
    setIsLoading(true)
    
    const paramsValidate = filterByUrlParams(filterParams)
    const { data: cardsData } = await api.get(`/business/panel?${queryParams || ""}&${paramsValidate}&status=${status}&page=${page + 1}&limit=10`)

    if (!cardsData[0]?.cards) {
      setIsLoading(false)
      setShowButton(false)
      return
    }

    setVgv(previous => previous + cardsData[0].vgv)
    setCardsLength(previous => previous + cardsData[0].cards.length)
    
    data.push(...cardsData[0].cards)
    setIsLoading(false)

  }

  const getStatus = (status) => {
    if (status <= 5) return "#05E9BE";
    if (status > 10) return "#890001"; // []AJEITAR LOGICA DEPOIS 

    return "#FC8D72";
  };

  React.useEffect(() => {
    if (data.length >= 10) {
      setShowButton(true)
    }
  }, [data])

  return (
    <CardBody style={{ height: "62vh", overflowY: "auto" }}>
      {
        
          data.map(({ business }, index) => (
            <Draggable draggableId={business._id} index={index} key={business._id + index}>
              {(provided) => (
                <Card
                  innerRef={provided.innerRef}
                  {...provided.dragHandleProps}
                  {...provided.draggableProps}
                  style={{
                    padding: 10,
                    marginBottom: 10,
                    border: "1px solid black",
                    fontSize: 10,
                    position: "relative",
                    cursor: "pointer",
                    animation: "ease-in-out",
                    ...provided.draggableProps.style,
                  }}
                  onDoubleClick={() =>
                    history.push(`/admin/customerManagement/${business.client._id}`)
                  }
                >
                  <i
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 10,
                      color: getStatus(
                        Math.abs(
                          new Date().getDate() -
                            new Date(business.updatedAt).getDate()
                        )
                      ),
                    }}
                    onDoubleClick={() =>
                      history.push(
                        `/admin/customerManagement/${business.client._id}`
                      )
                    }
                  />
                  <i
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 10,
                      color: getStatus(
                        Math.abs(
                          moment(new Date()).diff(
                            moment(new Date(business.updatedAt)),
                            "d"
                          )
                        )
                      ),
                    }}
                    className="fa-solid fa-clock"
                  />
                  <h6 style={{ color: "#6D3D94", padding: 2 }}>
                    {business.client.name}
                  </h6>
                  <p>Ticket Médio: {business.client.averageTicket}</p>
                  {HasPermission("panel.view.info.owner") && (
                    <p>Dono: {business.client.owner?.name}</p>
                  )}
                  {HasPermission("panel.view.info.realState") && (
                    <p>Imobiliária: {business.client.owner?.partner?.corporateName}</p>
                  )}
                  {HasPermission("panel.view.info.immediateSuperior") && (
                    <p>Superior Imediato: {business.supperiorImediate?.name}</p>
                  )}
                  <p>
                    Empreendimento:{" "}
                    {business.enterprise
                      ? business.enterprise.name
                      : business.stand.enterprise.name}
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    Ultimo Contato:{" "}
                    {new Date(business.updatedAt).toLocaleDateString("pt-BR")}{" "}
                    {new Date(business.updatedAt).toLocaleTimeString("pt-BR")}
                  </p>
                </Card>
              )}
            </Draggable>
            
          ))
    
      }
      {
        isLoading && <p style={{ fontSize: 12}}>Carregando mais cards...</p>
      }
      {
        data.length >= 10 && showButton && (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                style={{ 
                  fontSize: 12,
                  cursor: 'pointer',
                  color: "#dddccd",
                }}
                color="secondary"
                onClick={getCardsByStatus}
              >
                Ver mais
              </Button>
          </div>
        )
      }
    </CardBody>
  );
};

export default PanelCardBody;
