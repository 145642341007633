import api from "api";
import { useAuth } from "context/AuthContext";
import React, { useCallback } from "react";
import NotificationAlert from "react-notification-alert";
import moment from "moment";
import { ModalReport } from "./components/ModalReport";
import { BarCharts } from "./components/BarCharts";
import { useNotification } from "context/NotificationContext";
import { DashboardFilters } from "./components/DashboardFilters";

const Dashboard = () => {
  const [statistics, setStatistics] = React.useState([])
  const [isOpenModalReport, setIsOpenModalReport] = React.useState(false)
  const [captions, setCaptions] = React.useState(new Map())
  const [captionsFormatted, setCaptionsFormatted] = React.useState([])
  const notificationAlertRef = React.useRef(null);

  const { user } = useAuth()

  const { attReports } = useNotification()

  const buildCaption = (field, value) => {
    const fieldFormatted = field.split('.').length > 1 ? field.split('.')[1] : field
    captions.set(fieldFormatted, {value: value.map(({ label }) => label)})
    setCaptions(captions)
    setCaptionsFormatted(Array.from(captions).map(([key, value]) => ({ key, ...value })))
  }

  const getStatistics = async (filterParams = "") => {
    const { data } = await api.get(`/statistics?${filterParams}`)
    setStatistics(data)
  }

  const getEventSSE = useCallback(() => {
    let options;
    const url = `https://report-staging.pgrc.com.br/api/v1/sse?userId=${user.id}`
    const sse = new EventSource(url);

    sse.addEventListener("pdf-generated", (event) => {

      if (event.data) {
        const report = {
          pdf: event.data,
          createdAt: moment().subtract(3, 'h'),
        }
  
        if (report.pdf) {    
          const reportsString = JSON.stringify(report) 

          attReports(reportsString, notificationAlertRef.current)
        }

        options = {
          place: "tr",
          message: "Em breve seu relatório estará pronto!",
          type: "success",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7
        };

        setTimeout(notificationAlertRef.current.notificationAlert(options), 2500)
        setIsOpenModalReport(false)
      } 
    });

    sse.addEventListener("pdf-empty", (event) => {

      options = {
        place: "tr",
        message: "Os dados pedidos no relatório estão vazios!",
        type: "danger",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 4
      };

      setTimeout(notificationAlertRef.current.notificationAlert(options), 2500)
    });

    sse.onerror = (event) => {
      if (event.type === 'error') {
        options = {
          place: "tr",
          message: "Houve um erro ao tentar se conectar com o servidor, tente novamente!",
          type: "danger",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 4
        };
        
      }
      
      setTimeout(notificationAlertRef.current.notificationAlert(options), 4000)
    };
  }, [user]) 

  React.useEffect(() => {
    getEventSSE()
    getStatistics()
  }, [])

  return (
    <>
      <div className="content">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <ModalReport 
          isOpen={isOpenModalReport} 
          setToggleModal={() => setIsOpenModalReport(false)} 
          notificationAlertRef={notificationAlertRef}
        />
        <h1>Bem vindo ao Dashboard</h1>
        <DashboardFilters buildCaption={buildCaption} fetchData={getStatistics} />
        <BarCharts 
          statistics={statistics} 
          onOpenReportModal={() => setIsOpenModalReport(true)}
          captions={captionsFormatted}
        />
      </div>
    </>
  );
};

export default Dashboard;
