import { Card } from "reactstrap";

export const ClientDataIcons = (props) => {
    const {
        hasLabel,
        classNameIcon,
        label,
        value,
        isPhoneNumber,
        onClickIcon
    } = props;

    return (
       <section  
            style={{
                display: hasLabel ?  "flex" : "block", 
                alignItems: hasLabel ?  "center" : "none", 
                padding: hasLabel ?  6 : 0
            }}
            onClick={onClickIcon}
        >
            <Card 
                style={{ 
                    width: hasLabel ? 42 : 45,
                    height: hasLabel ? 42 : 45,
                    backgroundColor: "#2A3755",
                    borderRadius: "50%",
                    marginTop: hasLabel ? 0 : 4,
                    marginLeft: hasLabel ? 8 : 14, 
                    marginBottom: 0,
                    marginRight: hasLabel ? 20 : 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: hasLabel ? "default" : "pointer"
                }}
            >
                <i 
                    style={{
                        color: "#E6E8ED"
                    }} 
                    className={classNameIcon}
                />
            </Card>
           {
                hasLabel && !isPhoneNumber && (
                    <div>
                        <div>
                            <p style={{ color: "#6A6A79"}}>{label}</p>
                            <p>{value}</p>
                        </div>
                    </div>
                    
                )
                
           }
           {
            isPhoneNumber && value && (
                <div>
                    <div>
                        <p style={{ color: "#6A6A79"}}>{label}</p>
                        <p>{value[0].value}</p>
                    </div>
                    <details>
                        <summary style={{ fontSize: 12}}>Mostrar mais</summary>
                        {
                            value.length > 1 && value.map((item, index) => {
                                if (index === 0) return undefined
                                return (
                                    <p key={index}>{item.value}</p>
                                )
                            })
                        }
                    </details>
                </div>
            )   
           }
       </section>
    )
}