import Enterprises from "views/pages/Enterprises";
import Clients from "views/pages/Clients";
import { BusinessClosed } from "views/pages/Clients/BusinessClosed";
import BusinessPanel from "views/pages/BusinessPanel";
import { CustomerManagementPage } from "views/pages/CustomerManagement";

export const incorporationRoutes = [
      {
        path: "/enterprises",
        name: "Empreendimentos",
        icon: "fa fa-building",
        component: Enterprises,
        layout: "/admin",
      },
      {
        collapse: true,           
        name: "Clientes",
        icon: "tim-icons icon-badge",
        state: "pagesCollapse",
        views: [
          {
            path: "/clients",
            name: "Listagem de Clientes",
            mini: "C",
            component: Clients,
            layout: "/admin"
          },
          {
            path: "/businessPanel",
            name: "Painel de Negócios",
            mini: "PN",
            component: BusinessPanel,
            layout: "/admin"
          },
          {
            path: "/business-closed",
            name: "Negócios Fechados",
            mini: "NF",
            component: BusinessClosed,
            layout: "/admin"
          },
        ] 
      },
      {
        path: "/customerManagement/:clientId",
        name: "Gestão de Cliente",
        component: CustomerManagementPage,
        layout: "/admin",
        invisible: true
      },
      
]