import React, { useEffect } from "react";
import classNames from "classnames";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Label,
  Input,
} from "reactstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import { Link } from "react-router-dom";
import api from "../../../api/index";
import { HasPermission } from "variables/permissions";
import { translatePartners } from "variables/translatePartners";
import { useForm } from "react-hook-form";
import { filterByUrlParams } from "variables/filter-by-url-params";
import { FilterTable } from "views/components/FilterTables";
import AsyncSelect from "react-select/async";


const ListChart = (props) => {
  const [data, setData] = React.useState([]);
  const { register, handleSubmit, setValue, getValues } = useForm();

  const getData = async (params = "") => {
    const { data } = await api.get(`/partners?${params}`);
    setData(data);
  };

  const loadOptions = (inputValue, callback, isType = false) => {
    api.get('partners')
    .then((response) => {
      const options = []
      response.data.forEach((option) => {
        options.push({
          label: isType ? translatePartners(option.typePartner) : option.fantasyName,
          value: option.typePartner 
        })
      })


      let filterOptions = options.filter(o =>
        Object.keys(o).some(k => o[k].toLowerCase().includes(inputValue.toLowerCase())));
        
      callback(filterOptions);
    })
  }

  const onSubmit = (e) => {
    const params = filterByUrlParams(e)

    getData(params)
  }

  const onChangeText = (e, field) => {
    setValue(field, `*${e.target.value}`)
    const params = getValues();
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      onSubmit(params)
    }
  }

  const onChangeSelect = (e, field) => {
    setValue(field, `${e.map(({ label }) => label)}`)
    const params = getValues();
    onSubmit(params)
  }



  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <div className="content">
        <Row className="mt-5">
          <Col xs={12} md={12}>
          <FilterTable onSubmit={handleSubmit((e) => onSubmit(e))}>
            <Row>
                {
                  HasPermission("org.filter.partner.nameFantasy") && (
                    <Col>
                      <Label for="fantasyName">Nome Fantasia</Label>
                      <Input
                        type="text"
                        name="fantasyName"
                        placeholder="Nome fantasia"
                        id="fantasyName"
                        {...register('fantasyName')}
                        onChange={(e, _) => onChangeText(e, 'fantasyName')}
                      />
                    </Col> 
                  )
                }
                {
                  HasPermission('org.filter.partner.type') && (
                    <Col>
                      <Label for="typePartner">Tipo</Label>
                      <AsyncSelect 
                        classNamePrefix="react-select"
                        className="react-select info"
                        placeholder="Selecionar Tipo"
                        name="typePartners"
                        isSearchable
                        closeMenuOnSelect={false}
                        cacheOptions
                        isMulti
                        defaultOptions
                        loadOptions={(inputValue, callback, isType) => loadOptions(inputValue, callback, true)}
                        {...register('typePartner')}
                        onChange={(e, _) => onChangeSelect(e, 'typePartner')}
                      />
                    </Col> 
                  )
                }
              </Row>
            </FilterTable>
            <Card>
              <CardHeader>
                <CardTitle>
                  <div>
                    <h4 className="d-inline-flex p-2">Listar Organogramas</h4>
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={data}
                  filterable
                  resizable={false}
                  columns={[  
                    {
                      Header: "Nome Fantasia",
                      Cell: data => {
                        return <p>{data.row.original.fantasyName}</p>
                      }
                    },
                    {
                      Header: "Tipo",
                      Cell: data => {
                        return <p>{translatePartners(data.row.original.typePartner)}</p>
                      }
                    },
                    {
                      Header: "Ações",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                      Cell: data => 
                        (
                        <div className="actions-right">
                         {
                          HasPermission("chart.view") && (
                            <Link to={`/admin/chart/${data.row.original._id}/edit`}>
                              <Button
                                color="warning"
                                size="sm"
                                className={classNames("btn-icon btn-link edit", {
                                  "btn-neutral":  false
                                })}
                              >
                                <i className="tim-icons icon-paper" />
                              </Button>
                            </Link>
                          )
                         }
                        </div>
                      )
                    }
                  ]}
                  defaultPageSize={10}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default ListChart;