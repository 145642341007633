export const translateCaption = (caption) => {

    const captions = {
        _id: 'Empreendimentos',
        owner: 'Donos',
        manager: 'Gerentes',
        salesDirector: 'Diretores de Vendas',
        commercialDirector: 'Diretores Comerciais',
        superiorImmediate: 'Superiores Imediatos',
        partner: "Imobiliárias",
        posManagers: "Gestores de PDV",
        incorporators: "Incorporadores",
    }

    return captions[caption]
      
}