import React from "react";
import api from "api";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";

export const useClientData = () => {
    const [ clientData, setClientData ] = React.useState({});

    const { params } = useRouteMatch()

    const getClientData = React.useCallback(async () => {
        const { data } = await api.get(`/clients/${params.clientId}`)
        data.createdAt = `${new Date(data.createdAt).toLocaleDateString("pt-BR")} ${new Date(data.createdAt).toLocaleTimeString("pt-BR")}`
        setClientData(data)
    }, [params.clientId])

    React.useEffect(() => {
        getClientData()
    }, [getClientData])

    return { clientData }
}
