import api from "api";

export const useRemoveClient = () => {

    const removeData = async (id) => {
        const { status } = await api.delete(`/clients/${id}`)
        return status
    };

    return { removeData }
}