import { Card, CardContent } from "@mui/material"

export const CountCard = ({ title, value, icon }) => {


    return (
        <Card className="bg-transparent" style={{ maxWidth: 220, width: '100%'}}>
            <CardContent 
                style={{ 
                    display: 'flex',                
                    justifyContent: 'space-evenly', 
                    alignItems: 'center', 
                    padding: "20px 0",
                    border: '1px #2083F7',
                    borderStyle: 'dotted',
                    borderRadius: 5,
                    height: 75
                }}> 
                {icon}
                <div style={{ lineHeight: 0.3, width: "65%" }}>
                    <h4 style={{ fontSize: 12, fontWeight: 'bold'}}>{title}</h4>
                    <p style={{ fontSize: 12, fontWeight: 'bold'}}>{value}</p>
                </div>
            </CardContent>
        </Card>
    )
}