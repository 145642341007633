import { adminPerformanceRoutes } from "./AdminPerformanceRoutes"
import { brokerRoutes } from "./BrokerRoutes"
import { coordinatorProductsRoutes } from "./CoordinatorProductRoutes"
import { managerPosRoutes } from "./ManagerPosRoutes"
import { generalDirectorRoutes } from "./GeneralDirectorRoutes"
import { commercialDirectorRoutes } from "./CommercialDirectorRoutes"
import { marketingDirectorRoutes } from "./MarketingDirectorRoutes"
import { marketingRoutes } from "./MarketingRoutes"
import { salesDirectorRoutes } from "./SalesDirectorRoutes"
import { operationDirectorRoutes } from "./OperationDirectorRoutes"
import { managerRoutes } from "./ManagerRoutes"
import { vpCommercialRoutes } from "./VpCommercialRoutes"
import { vpBusinessRoutes } from "./VpBusinessRoutes"
import { incorporationServiceRoutes } from "./IncorporationServiceRoutes"
import { incorporationRoutes } from "./IncorporatorRoutes"

const routerStack = {
    adminPerformanceRoutes, 
    brokerRoutes,
    commercialDirectorRoutes,
    coordinatorProductsRoutes,
    generalDirectorRoutes,
    incorporationServiceRoutes,
    incorporationRoutes,
    managerPosRoutes,
    managerRoutes,
    marketingDirectorRoutes,
    marketingRoutes,
    operationDirectorRoutes,
    salesDirectorRoutes,
    vpBusinessRoutes,
    vpCommercialRoutes,
}

export default routerStack;