import React from "react";
import classNames from "classnames";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import ReactTable from "components/ReactTable/ReactTable.js";
import { Link } from "react-router-dom";
import api from "../../../api/index";
import { TableHeader } from "views/components/TableHeader";
import { ModalDeleteItem } from "views/components/ModalDeleteItem";
import { IconData } from "../CustomerManagement/components/IconData";
import { HasPermission } from "variables/permissions";
import { FilterTable } from "views/components/FilterTables";
import { useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { filterByUrlParams } from "variables/filter-by-url-params";
import { Base64 } from "js-base64";
import { useAuth } from "context/AuthContext";

const Clients = () => {
  const [data, setData] = React.useState([]);
  const [removeId, setRemoveId] = React.useState(null);
  const [base64, setBase64] = React.useState("");
  const [toggleModalRemove, setToggleModalRemove] = React.useState(false);
  const [queryParams, setQueryParams] = React.useState("");
  const [pagination, setPagination] = React.useState(1)

  const { register, handleSubmit, setValue, getValues } = useForm();

  const { user } = useAuth();
  const getStatus = (status) => {
    if (status <= 5) return "#05E9BE";
    if (status < 10 && status >= 9) return "#890001";

    return "#FC8D72";
  };

  const removeData = async (id) => {
    const { status } = await api.delete(`/clients/${id}`);
    if (status === 200) {
      setToggleModalRemove(false);
      getData();
    }
    setRemoveId(null);
  };

  const loadOptions = (inputValue, callback, url) => {
    api.get(url).then((response) => {
      const options = [];
      response.data.forEach((option) => {
        options.push({
          label: option.corporateName || option.name,
          value: option._id,
        });
      });

      let filterOptions = options.filter((o) =>
        Object.keys(o).some((k) =>
          o[k].toLowerCase().includes(inputValue.toLowerCase())
        )
      );

      callback(filterOptions);
    });
  };

  const onSubmit = async (e) => {
    let params = filterByUrlParams(e);

    getData(params);
  };

  const onExport = async (params = "") => {
    const { data } = await api.get(`/clients?${params}`, {
      headers: {
        "X-Custom-Response": "csv",
      },
    });

    const csv = Base64.decode(data).toString();

    setBase64(csv);
  };

  const onChangeText = (e, field) => {
    setValue(field, `*${e.target.value}`);
    const params = getValues();
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      onSubmit(params);
    }
  };

  const onChangeSelect = (e, field, type) => {
    setValue(field, `${e.map(({ value }) => value)}`);
    
    const params = getValues();
   
    onSubmit(params);

  };

  const getData = async (filterParams = "", page = 0) => {
    onExport(queryParams);
    const { data } = await api.get(`/clients?${queryParams}&${filterParams}&sort=-updatedAt&page=${pagination + page}&limit=10`);
    setData(data);
  };

  const onNextPage = () => {
    setPagination(previous => previous + 1)
    const params = getValues();
    const filterParams = filterByUrlParams(params);

    getData(filterParams, 1)
  }

  const onPreviousPage = () => {
    setPagination(previous => previous - 1)
    const params = getValues();
    const filterParams = filterByUrlParams(params);

    getData(filterParams, -1)
  }

  React.useEffect(() => {
    if (user.role.name === "MANAGER_POS") {
      setQueryParams(`enterprise.posManagers=${user.id}`);
      if(queryParams) {
        getData();
      }
    } else if (user.role.name === "BROKER") {
      setQueryParams(`owner=${user.id}`);
      if(queryParams) {
        getData();
      }
    } else if (user.role.name === "MANAGER") {
      setQueryParams(`supperiorImediate=${user.id}`);
      if(queryParams) {
        getData();
      }
    } else if (user.role.name === "SALES_DIRECTOR") {
      setQueryParams(`ownersBelowChart=${user.id}`);
      if(queryParams) {
        getData();
      }
    } else if (user.role.name === "COMMERCIAL_DIRECTOR_INCORPORATOR") {
      setQueryParams(`enterprise.incorporators=${user.partner._id}`);
      if(queryParams) {
        getData();
      }
    } else if (user.role.name === "COORDINATOR_PRODUCTS") {
      setQueryParams(
        `enterprise.coordinatorsProducts=${user.id}&enterprise.realStates=${user.partner._id}`
      );
      if(queryParams) {
        getData();
      }
    } else if (user.role.name === "GENERAL_DIRECTOR") {
      setQueryParams(`partner._id=${user.partner._id}`);
      if(queryParams) {
        getData();
      }
    } else {
      getData();
    }
  }, [user.role, user.id, user.partner?._id, queryParams]);

  return (
    <>
      <div className="content">
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <FilterTable onSubmit={handleSubmit((e) => onSubmit(e))}>
              <FormGroup>
                <Row>
                  {HasPermission("clients.filter.name") && (
                    <Col sm="4">
                      <Label for="name">Nome</Label>
                      <Input
                        type="text"
                        name="name"
                        placeholder="Nome"
                        id="name"
                        {...register("name")}
                        onChange={(e, _) => onChangeText(e, "name")}
                      />
                    </Col>
                  )}
                  {HasPermission("clients.filter.enterprise") && (
                    <Col sm="4">
                      <Label for="enterprise">Empreendimento</Label>
                      <AsyncSelect
                        classNamePrefix="react-select"
                        className="react-select info"
                        placeholder="Selecionar Empreendimento"
                        name="enterprises"
                        isSearchable
                        isMulti
                        closeMenuOnSelect={false}
                        cacheOptions
                        defaultOptions
                        loadOptions={(inputValue, callback, url) =>
                          loadOptions(inputValue, callback, "enterprises")
                        }
                        {...register("enterprise")}
                        onChange={(e, _) => onChangeSelect(e, "enterprise._id", 'enterprise')}
                      />
                    </Col>
                  )}
                  {HasPermission("clients.filter.stand") && (
                    <Col sm="4">
                      <Label for="stand">Stand</Label>
                      <AsyncSelect
                        classNamePrefix="react-select"
                        className="react-select info"
                        placeholder="Selecionar Stand"
                        name="stands"
                        isSearchable
                        isMulti
                        closeMenuOnSelect={false}
                        cacheOptions
                        defaultOptions
                        loadOptions={(inputValue, callback, url) =>
                          loadOptions(inputValue, callback, `stands`)
                        }
                        {...register("stand")}
                        onChange={(e, _) => onChangeSelect(e, "stand", 'stand')}
                      />
                    </Col>
                  )}
                </Row>
                {HasPermission("clients.filter.owner") && (
                  <Row>
                    <Col sm="4">
                      <Label for="owner">Dono</Label>
                      <AsyncSelect
                        classNamePrefix="react-select"
                        className="react-select info"
                        placeholder="Selecionar Dono"
                        name="owners"
                        isSearchable
                        isMulti
                        closeMenuOnSelect={false}
                        cacheOptions
                        defaultOptions
                        loadOptions={(inputValue, callback, url) =>
                          loadOptions(
                            inputValue,
                            callback,
                            "users/possible-owners"
                          )
                        }
                        {...register("owner")}
                        onChange={(e, _) => onChangeSelect(e, "owner")}
                      />
                    </Col>
                  </Row>
                )}
              </FormGroup>
            </FilterTable>

            <Card>
              <TableHeader
                title="Listar Clientes"
                permission="clients.create"
                linkToCreate="/admin/client/new"
                hasCSV="clients.export"
                csv={base64}
              />
              <CardBody>
                <ReactTable
                  data={data || []}
                  filterable
                  columns={[
                    {
                      Header: "Nome",
                      Cell: (data) => {
                        return <p>{data.row.original.name}</p>;
                      },
                    },
                    {
                      Header: "Empreendimento/Stand",
                      Cell: (data) => {
                        return (
                          <p>
                            {data.row.original.enterprise
                              ? data.row.original.enterprise.name
                              : data.row.original.stand?.name}
                          </p>
                        );
                      },
                    },
                    {
                      Header: "Imobiliária",
                      Cell: (data) => {
                        return (
                          <p>
                            {data.row.original.owner?.partner?.corporateName}
                          </p>
                        );
                      },
                    },
                    {
                      Header: "Dono",
                      Cell: (data) => {
                        return <p>{data.row.original.owner?.name}</p>;
                      },
                    },
                    {
                      Header: "Telefone",
                      Cell: (data) => {
                        return (
                          <p>
                            {HasPermission("clients.list.view.phoneNumber")
                              ? data.row.original.phoneNumbers[0]?.value
                              : "-"}
                          </p>
                        );
                      },
                    },
                    {
                      Header: "Ultima Atualização",
                      Cell: (data) => {
                        return (
                          <div style={{ margin: "0 auto" }}>
                            <IconData
                              children={`${new Date(
                                data.row.original.updatedAt
                              ).toLocaleDateString("pt-BR")} ${new Date(
                                data.row.original.updatedAt
                              ).toLocaleTimeString("pt-BR")}`}
                              icon="fa-solid fa-clock tooltip-icon-3"
                              className="icon-component-3"
                              width={30}
                              height={30}
                              color={getStatus(
                                Math.abs(new Date().getDate() -
                                new Date(
                                  data.row.original.updatedAt
                                ).getDate())
                              )}
                            />
                          </div>
                        );
                      },
                    },
                    {
                      Header: "Ações",
                      sortable: false,
                      filterable: false,
                      Cell: (data) => (
                        <div className="actions-right">
                          {HasPermission("clients.management") && (
                            <Link
                              to={`/admin/customerManagement/${data.row.original._id}`}
                            >
                              <Button
                                color="warning"
                                size="sm"
                                className={classNames(
                                  "btn-icon btn-link view",
                                  {
                                    "btn-neutral": false,
                                  }
                                )}
                              >
                                <i className="tim-icons icon-single-02" />
                              </Button>
                            </Link>
                          )}
                          {HasPermission("clients.document") && (
                            <Link
                              to={`/admin/documents/clients/${data.row.original._id}`}
                            >
                              <Button
                                color="warning"
                                size="sm"
                                className={classNames(
                                  "btn-icon btn-link view",
                                  {
                                    "btn-neutral": false,
                                  }
                                )}
                              >
                                <i className="tim-icons icon-paper" />
                              </Button>
                            </Link>
                          )}
                          {HasPermission("clients.edit") && (
                            <Link
                              to={`/admin/client/${data.row.original._id}/edit`}
                            >
                              <Button
                                color="warning"
                                size="sm"
                                className={classNames(
                                  "btn-icon btn-link edit",
                                  {
                                    "btn-neutral": false,
                                  }
                                )}
                              >
                                <i className="tim-icons icon-pencil" />
                              </Button>
                            </Link>
                          )}
                          {HasPermission("clients.discard") && (
                            <Button
                              onClick={() => {
                                setToggleModalRemove(true);
                                setRemoveId(data.row.original._id);
                              }}
                              color="danger"
                              size="sm"
                              className={classNames(
                                "btn-icon btn-link remove",
                                {
                                  "btn-neutral": false,
                                }
                              )}
                            >
                              <i className="tim-icons icon-simple-remove" />
                            </Button>
                          )}
                        </div>
                      ),
                    },
                  ]}
                  defaultPageSize={10}
                  className="-striped -highlight"
                  paginationOptions={{
                    onGoToNextPage: onNextPage,
                    onGoToPreviousPage: onPreviousPage,
                    canGoToNextPage: data.length === 10,
                    canGoToPreviousPage: pagination !== 1,
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <ModalDeleteItem
        title="Deseja realmente descartar este cliente?"
        isOpen={toggleModalRemove}
        setToggleModal={() => setToggleModalRemove(false)}
        removeData={() => removeData(removeId)}
      />
    </>
  );
};

export default Clients;
