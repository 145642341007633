import React, { forwardRef, useEffect } from "react";
import cep from "cep-promise";
// reactstrap components
import {
  Input,
  Row,
  Col,
  FormGroup,
  Label
} from "reactstrap";
import api from "../../../../api";
import InputMask from "react-input-mask";
import AsyncSelect from "react-select/async";

const CreateEnterprise = forwardRef((props, ref) => {
    const [name, setName] = React.useState("");
    const [nameSPE, setNameSPE] = React.useState("");
    const [documentNumber, setDocumentNumber] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [website, setWebsite] = React.useState("");
    const [zipCode, setZipCode] = React.useState("");
    const [street, setStreet] = React.useState("");
    const [number, setNumber] = React.useState("");
    const [complement, setComplement] = React.useState("");
    const [neighborhood, setNeighborhood] = React.useState("");
    const [state, setState] = React.useState("");
    const [city, setCity] = React.useState("");
    const [type, setType] = React.useState("");
    const [incorporators, setIncorporators] = React.useState([]);
    const [realStates, setRealStates] = React.useState([]);
    const [posManagers, setPosManagers] = React.useState([]);
    const [coordinatorsProducts, setCoordinatorsProducts] = React.useState([]);

    React.useImperativeHandle(ref, () => ({
      isValidated: () => isValidated(),
      state: {
        incorporators,
        realStates,
        posManagers,
        coordinatorsProducts,
        name,
        nameSPE,
        documentNumber,
        phoneNumber,
        website,
        zipCode,
        street,
        number,
        complement,
        neighborhood,
        state,
        city,
        type,
      },
    }));
    
    function isValidated() {
      if(name !== "" && nameSPE !== "" && documentNumber !== "") {
        return true;
      }
      return false;
    }


    async function handleFindAdrress(number) {
      if(number.length > 7) {
        try {
          let { street, neighborhood, city, state, cep: numberCep } = await cep(number);
          setZipCode(numberCep);
          setStreet(street);
          setNeighborhood(neighborhood);
          setCity(city);
          setState(state);
        } catch(error) {
          if (error instanceof Error) {
            console.log(error)
          }
        }
      }
    }

    const handleIncorporators = (inputValue, callback) => {
      api.get(`partners/find/incorporators`)
        .then((response) => {
          const options = []
          response.data.forEach((incorporator) => {
            options.push({
              label: incorporator.corporateName,
              value: incorporator._id
            })
          });

          let filterOptions = options.filter(o =>
            Object.keys(o).some(k => o[k].toLowerCase().includes(inputValue.toLowerCase())));
            
          callback(filterOptions);
        })
    }

    const handleRealState = (inputValue, callback) => {
        api.get(`partners/find/realstate`)
          .then((response) => {
            const options = []
            response.data.forEach((realstate) => {
              options.push({
                label: realstate.corporateName,
                value: realstate._id
              })
            })

            let filterOptions = options.filter(o =>
              Object.keys(o).some(k => o[k].toLowerCase().includes(inputValue.toLowerCase())));
              
            callback(filterOptions);
          })
    }
    
    const handlePosManagers = (inputValue, callback) => {
      api.get(`users/find/pos-managers`)
        .then((response) => {
          const options = []
          response.data.forEach((posManager) => {
            options.push({
              label: posManager.name,
              value: posManager._id
            })
          })
          let filterOptions = options.filter(o =>
            Object.keys(o).some(k => o[k].toLowerCase().includes(inputValue.toLowerCase())));
            
          callback(filterOptions);
        })
    }

    const handleCoordinatorsProducts = (inputValue, callback) => {
      api.get(`users/find/coordinators-products`)
        .then((response) => {
          const options = []
          response.data.forEach((coordinator) => {
            options.push({
              label: coordinator.name,
              value: coordinator._id
            })
          })
          let filterOptions = options.filter(o =>
            Object.keys(o).some(k => o[k].toLowerCase().includes(inputValue.toLowerCase())));
            
          callback(filterOptions);
        })
    }
    
    useEffect(() => {
    }, [])
    return (
      <>
        <Row className="justify-content-center mt-2">
            <Col sm="5">
                <FormGroup>
                    <Label for="type">Incorporadores</Label>
                    <AsyncSelect
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Selecione os incorporadores..."
                        name="developer"
                        isSearchable
                        closeMenuOnSelect={false}
                        isMulti
                        loadOptions={handleIncorporators}
                        cacheOptions
                        defaultOptions
                        required
                        onChange={(incorporators) => setIncorporators(incorporators.map((incorporator) => incorporator.value))}
                    />
                </FormGroup>
            </Col>
            <Col sm="5">
                <FormGroup>
                    <Label for="type">Imobiliárias</Label>
                    <AsyncSelect
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Selecione os imobiliárias..."
                        name="realState"
                        isSearchable
                        closeMenuOnSelect={false}
                        isMulti
                        loadOptions={handleRealState}
                        cacheOptions
                        required
                        defaultOptions
                        onChange={(realStates) => setRealStates(realStates.map((realState) => realState.value))}
                    />
                </FormGroup>
            </Col>
            <Col sm="5">
                <FormGroup>
                    <Label for="type">Gestores PDV</Label>
                      <AsyncSelect
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Selecione os Gestores PDV..."
                        name="pointOfSaleManagers"
                        isSearchable
                        closeMenuOnSelect={false}
                        isMulti
                        loadOptions={handlePosManagers}
                        cacheOptions
                        defaultOptions
                        required
                        onChange={(posManagers) => setPosManagers(posManagers.map((posManager) => posManager.value))}
                      />
                </FormGroup>
            </Col>
            <Col sm="5">
                <FormGroup>
                    <Label for="type">Coordenadores de Produto</Label>
                      <AsyncSelect
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Selecione os Coordenadores de Produto..."
                        name="productCoordinators"
                        isSearchable
                        closeMenuOnSelect={false}
                        isMulti
                        loadOptions={handleCoordinatorsProducts}
                        cacheOptions
                        defaultOptions
                        required
                        onChange={(coordinatorsProducts) => setCoordinatorsProducts(coordinatorsProducts.map((coordinatorsProduct) => coordinatorsProduct.value))}
                      />
                </FormGroup>
            </Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col sm="5">
            <FormGroup>
              <Label for="name">Nome</Label>
              <Input
                name="name"
                id="name"
                placeholder="Informe o Nome..."
                type="text"
                required
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>

          </Col>
          <Col sm="5">
            <FormGroup>
              <Label for="nameSPE">Nome SPE</Label>
              <Input
                name="nameSPE"
                placeholder="Informe o Nome SPE..."
                id="nameSPE"
                type="text"
                required
                onChange={(e) => setNameSPE(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="5">
            <FormGroup>
              <Label for="documentNumber">CNPJ</Label>
              <Input
                name="documentNumber"
                placeholder="Informe o CNPJ..."
                id="documentNumber"
                type="text"
                mask="99.999.999/9999-99"
                tag={InputMask}
                required
                onChange={(e) => setDocumentNumber(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="5">
            <FormGroup>
              <Label for="phoneNumber">Telefone</Label>
              <Input
                name="phoneNumber"
                placeholder="Informe o Telefone..."
                id="phoneNumber"
                mask="(99) 99999-9999"
                tag={InputMask}
                type="text"
                required
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col sm="5">
            <FormGroup>
              <Label for="website">Website</Label>
              <Input
                name="website"
                placeholder="Informe o Website..."
                id="website"
                type="text"
                onChange={(e) => setWebsite(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="5">
            <FormGroup>
              <Label for="zipCode">CEP</Label>
              <Input
                name="zipCode"
                id="zipCode"
                placeholder="Informe o CEP..."
                type="text"
                required
                onChange={(e) => handleFindAdrress(e.target.value)}
              />
            </FormGroup>

          </Col>
          <Col sm="5">
            <FormGroup>
              <Label for="street">Rua</Label>
              <Input
                name="street"
                id="street"
                placeholder="Informe a Rua..."
                type="text"
                value={street}
                required
                onChange={(e) => setStreet(e.target.value)}
              />
            </FormGroup>
           
          </Col>
          <Col sm="5">
            <FormGroup>
              <Label for="complement">Complemento</Label>
              <Input
                name="complement"
                id="complement"
                placeholder="Informe o Complemento..."
                type="text"
                onChange={(e) => setComplement(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center mt-2">
            <Col sm="3">
                <FormGroup>
                <Label for="number">Número</Label>
                <Input
                    name="number"
                    placeholder="Informe o Número..."
                    id="number"
                    type="text"
                    onChange={(e) => setNumber(e.target.value)}
                />
                </FormGroup>
            </Col>
            <Col sm="4">
                <FormGroup>
                <Label for="neighborhood">Bairro</Label>
                <Input
                    name="neighborhood"
                    placeholder="Informe o Bairro..."
                    id="neighborhood"
                    type="text"
                    value={neighborhood}
                    required
                    onChange={(e) => setNeighborhood(e.target.value)}
                />
                </FormGroup>
            </Col>
            <Col sm="3">
                <FormGroup>
                <Label for="city">Cidade</Label>
                <Input
                    name="city"
                    placeholder="Informe a Cidade..."
                    id="city"
                    type="text"
                    value={city}
                    required
                    onChange={(e) => setCity(e.target.value)}
                />
                </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col sm="3">
            <FormGroup>
              <Label for="state">Estado</Label>
              <Input
                name="state"
                placeholder="Informe o Estado"
                id="state"
                type="text"
                value={state}
                required
                onChange={(e) => setState(e.target.value)}
              />
            </FormGroup>
 
          </Col>
          <Col sm="3">
            <FormGroup>
              <Label for="type">Tipo</Label>
              <Input 
                name="type"
                id="type"
                type="select"
                value={type}
                required
                onChange={(e) => setType(e.target.value)}
              >
                <option>
                  Selecione o tipo...
                </option>
                <option value="HORIZONTAL">
                  Horizontal
                </option>
                <option value="VERTICAL">
                  Vertical
                </option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
});

export default CreateEnterprise;
