import classNames from "classnames";
import React, { forwardRef, useImperativeHandle, useState } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  CardGroup,
} from "reactstrap";


const TypeSelection = forwardRef((props, ref) => {
  const [typeClient, setTypeClient] = useState("stand");

  function handleTypeSelect(key) {
    setTypeClient(key);
  }

  useImperativeHandle(ref, () => ({
    isValidated: undefined,
    state: {
      typeClient,
    }
  }));
  
  return (
    <>
      <CardGroup>
        {props.options.map((element => (
        <Card key={element.key}  className={classNames(
          {
            "card-selection": true,
            "card-selection-active": element.key == typeClient
          }
          )}
          onClick={() => handleTypeSelect(element.key)}>
            {element.img}
            <CardBody className="elementCard">
              <CardTitle tag="h2">
                {element.title}
              </CardTitle>
            </CardBody>
          </Card>
        )))}

      </CardGroup>
    </>
  )
})

export default TypeSelection;

