import React, { forwardRef, useEffect } from "react";
import { Card, CardImg, Col, Row } from "reactstrap";
import api from "../../../api/index";
import { useRouteMatch } from "react-router-dom";
import PDFViewer from "../../components/PDFViewer";

const DetailsDocumentApproval = forwardRef((props, ref) => {
  const [documents, setDocuments] = React.useState([]);
  const [approve, setApprove] = React.useState(false);
  const [open, setOpen] = React.useState("");

  const { params } = useRouteMatch();

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return false;
    },
    state: {
      documents,
      approve,
    },
  }));

  const getData = async () => {
    const { data } = await api.get(`/document-approval/${params.approvalId}`);
    setDocuments(data.documents);
    setApprove(data.approve);
  };

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const isImage = (data) => {
    const extension = data.toLowerCase().substr(data.lastIndexOf(".") + 1);
    switch (extension) {
      case "png":
      case "jpeg":
      case "jpg":
      case "gif":
      case "jfif":
        return true;
      default:
        return false;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="content">
        <h1>Documentos enviados pelo interessado</h1>
        <Row className="justify-content-center mt-2">
          <Col sm="8">
            {documents.length !== 0 ? (
              documents.map((document, index) => (
                <Card>
                  {(isImage(document[0]) && (
                    <CardImg src={document[0]}></CardImg>
                  )) || <PDFViewer file={document[0]} />}
                </Card>
              ))
            ) : (
              <h1>Test</h1>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
});

export default DetailsDocumentApproval;
