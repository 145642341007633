import { Card, CardTitle } from "reactstrap";
import { ClientDataIcons } from "./ClientDataIcons";
import React from "react";
import api from "api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ModalStatusClient } from "./ModalStatusClient";
import { ModalOwners } from "./ModalOwners";
import { useClientData } from "../hooks/useClientData";
import { translateStatus } from "variables/translateStatus";
import { HasPermission } from "variables/permissions";
import { useAuth } from "context/AuthContext";
import { ModalScheduling } from "views/components/ModalScheduling";
import NotificationAlert from "react-notification-alert";

export const ClientData = ({ addHistoricalCard }) => {
    const [modalStatus, setModalStatus] = React.useState(false);
    const [modalOwners, setModalOwners] = React.useState(false);
    const [modalScheduling, setModalScheduling] = React.useState(false);
    const [status, setStatus] = React.useState("");
    const [listStatus, setListStatus] = React.useState([]);
    const [owner, setOwner] = React.useState("");
    const [owners, setOwners] = React.useState([]);
    const [ title, setTitle ] = React.useState("")
    const notificationAlertRef = React.useRef(null);

    const { user } = useAuth();

    const [averageTicketFormatted, setAverageTicketFormatted] = React.useState("")

    const { clientData } = useClientData()

    const history = useHistory()

    const getStatus = async () => {
        const isBroker = user.role.name === 'BROKER'
        let listStatus = ['Prospection', 'Scheduling Prevision', 'Effective Scheduling', 'Others Services', 'Treatment', 'Proposal'];

        if (isBroker) {
            listStatus = ['Prospection', 'Scheduling Prevision']
        }

        setListStatus(listStatus)
    }

    const onChangeStatus = async () => {
        await api.patch(`/business/${clientData._id}`, {
            status: status
        })
        if (status === "Scheduling Prevision") {
            setModalScheduling(true)
            setTitle("Previsão Agendamento")
        }
        if (status === "Effective Scheduling") {
            setModalScheduling(true)
            setTitle("Agendamento Efetivo")
        }
        if (status === "Others Services") {
            setModalScheduling(true)
            setTitle("Outros Atendimentos")
        }

        setModalStatus(false)

        addHistoricalCard({
            text: `${user.name} mudou o status do cliente "${clientData?.name}" de "${translateStatus(clientData?.status)}" para "${translateStatus(status)}"`,
            updatedAt: new Date().toISOString()
        })

        clientData.status = status;
    }

    const getOwners = async () => {
        const { data } = await api.get('/users/possible-owners');
        setOwners(data)
    };

    const onChangeOwners = async () => {
        let options;
        try {
            await api.patch(`/clients/${clientData._id}`, {
                owner,
            })
    
            setModalOwners(false)
    
            const actualOwner = owners.find(item => item._id === owner)
    
            addHistoricalCard({
                text: `${user.name} mudou o dono do cliente "${clientData?.name}" de "${clientData?.owner.name}" para "${actualOwner.name}"`,
                updatedAt: new Date().toISOString()
            })
    
    
            clientData.owner = actualOwner;

            options = {
                place: "tr",
                message: "Usuário registrado com sucesso!",
                type: "success",
                icon: "tim-icons icon-bell-55",
                autoDismiss: 7
            };
        } catch (err) {
            options = {
                place: "tr",
                message: err.message,
                type: "danger",
                icon: "tim-icons icon-bell-55",
                autoDismiss: 7
              };
        }

        notificationAlertRef.current.notificationAlert(options);  
    }


    React.useEffect(() => {
        if (clientData.status) {
            setStatus(clientData.status)
        }

        if (clientData.owner) {
            setOwner(clientData.owner._id)
        }

        if (clientData.averageTicket) {
            setAverageTicketFormatted(clientData.averageTicket.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL"
            }))
        }

    }, [clientData])

    const translateMediasOrigin = (value) => {
        switch (value) {
            case "Action":
                return "Ação";
            case "TeleMarketing":
                return "TeleMarketing";
            case "SocialMedias":
                return "Redes Sociais";
            case "Sites":
                return "Sites";
            case "MarketingMail":
                return "E-mail Marketing";
            case "DigitalMedias":
                return "Mídias Digitais";
            case "Radio":
                return "Rádio";
            case "Outdoor":
                return "Outdoor";
            case "Others":
                return "Outros";
            default:
                return "Não Identificado"
        }
    }

    return (
        <>
        <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
        </div>
            {
                
                clientData && (
                    <>
                    
                        <Card className="py-4 px-2">
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                {
                                    HasPermission('clients.management.transfer') && (
                                        <ClientDataIcons classNameIcon="fa-solid fa-arrow-right-arrow-left" onClickIcon={() => {
                                            getOwners()
                                            setModalOwners(!modalOwners)
                                        }} />
                                    )
                                }
                                {
                                    HasPermission('clients.management.edit') && (
                                        <ClientDataIcons classNameIcon="tim-icons icon-pencil" onClickIcon={() => history.push(`/admin/client/${clientData._id}/edit`)} />
                                    )
                                }
                                {
                                    HasPermission('clients.management.edit.stage') && (
                                        <ClientDataIcons classNameIcon="fa-solid fa-arrows-left-right" onClickIcon={() => {
                                            getStatus()
                                            setModalStatus(!modalStatus)
                                        }} />
                                    )
                                }
                            </div>
                            <div >
                                <CardTitle tag='h3' style={{ maxWidth: 250, margin: '0 auto' }} className="text-white text-center pt-4 pb-5">{clientData.name}</CardTitle>
                            </div>
                            <ClientDataIcons
                                classNameIcon="fa-regular fa-building"
                                hasLabel
                                label="Empreendimento"
                                value={clientData.enterprise ? clientData.enterprise.name : clientData.stand?.enterprise.name}
                            />
                            <ClientDataIcons
                                classNameIcon="tim-icons icon-email-85"
                                hasLabel
                                label="E-mail"
                                value={clientData.email}
                            />
                            <ClientDataIcons
                                classNameIcon="tim-icons icon-money-coins"
                                hasLabel
                                label="Ticket Médio"
                                value={averageTicketFormatted}
                            />
                            <ClientDataIcons
                                classNameIcon="fa-regular fa-clock"
                                hasLabel
                                label="Criado em"
                                value={clientData.createdAt}
                            />
                            <ClientDataIcons
                                classNameIcon="fa-solid fa-phone"
                                hasLabel
                                label="Telefone(s)"
                                isPhoneNumber
                                value={clientData?.phoneNumbers && clientData.phoneNumbers}
                            />
                            <ClientDataIcons
                                classNameIcon="tim-icons icon-zoom-split"
                                hasLabel
                                label="Canal de Origem"
                                value={translateMediasOrigin(clientData.mediaOrigin)}
                            />
                        </Card>
                        <ModalStatusClient
                            isOpen={modalStatus}
                            onClose={() => setModalStatus(false)}
                            status={status}
                            onChangeStatus={(e) => setStatus(e.target.value)}
                            listStatus={listStatus}
                            onSubmit={onChangeStatus}
                        />
                        <ModalOwners
                            isOpen={modalOwners}
                            onClose={() => setModalOwners(false)}
                            owner={owner}
                            onChangeOwner={(e) => setOwner(e.target.value)}
                            listOwners={owners}
                            onSubmit={onChangeOwners}
                        />
                        <ModalScheduling
                            isOpen={modalScheduling}
                            title={title}
                            setToggleModal={() => setModalScheduling(!modalScheduling)}
                            clientId={clientData._id}
                        />
                    </>

                )
            }
        </>
    )
}