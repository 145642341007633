import api from "api";
import React from "react";
import { useForm } from "react-hook-form";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { filterByUrlParams } from "variables/filter-by-url-params";
import { FilterTable } from "views/components/FilterTables";
import PanelCardContainer from "views/components/Panel/PanelCardContainer";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { ActualPeriodModal } from "views/components/ActualPeriodModal";
import { useAuth } from "context/AuthContext";
import { HasPermission } from "variables/permissions";

const statusColumns = [
  {
      status: "Prospection",
      cards: [],
  },
  {
      status: "Scheduling Prevision",
      cards: [],
  },
  {
      status: "Effective Scheduling",
      cards: [],
  },
  {
      status: "Others Services",
      cards: [],
  },
  {
      status: "Treatment",
      cards: [],
  },
  {
      status: "Proposal",
      cards: [],
  },
]

const BusinessPanel = () => {
  const [columns, setColumns] = React.useState(statusColumns);
  const [actualPeriod, setActualPeriod] = React.useState({
    startAt: "",
    endAt: "",
  });
  const [lastContactChecked, setLastContactChecked] = React.useState({
    fiveDays: false,
    tenDays: false, 
    moreThanDays: false,
  });

  const [isOpenPeriodModal, setIsOpenPeriodModal] = React.useState(false);

  const [queryParams, setQueryParams] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const [periods, setPeriods] = React.useState();

  const { handleSubmit, setValue, register, getValues } = useForm();

  const { user } = useAuth();
  const fetchData = async (filterParams = "", page = 1) => {
    setIsLoading(true)
    
    await getCards(filterParams, page).then((response) => {
      setTimeout(() => {
        setColumns(response)
        setIsLoading(false)
      }, 2500);
    })

  
  }

  const getCards = async (filterParams = "", page) => {
    var copyColumns = JSON.parse(JSON.stringify(columns))
    columns.map(async (column, index) => {
      const cardsByStatus = await getCardsByStatus(column.status, filterParams, page).then((response) => response)
      copyColumns[index].cards = cardsByStatus[0]?.cards.length > 0 ? cardsByStatus[0].cards : []
      copyColumns[index].vgvFormatted = cardsByStatus[0]?.vgvFormatted 
      copyColumns[index].vgv = cardsByStatus[0]?.vgv
    })
    return copyColumns
  }

  const getCardsByStatus = async (status, filterParams, page) => {
      const { data } = await api.get(`/business/panel?&sort=-updatedAt&${queryParams}&${filterParams}&status=${status}&page=${page}&limit=10`)
      return data
  }

  const onSubmit = async (filterParam = "") => {
    const queryParams= getValues()
    let params = filterByUrlParams(queryParams);
    await fetchData(params + `&${filterParam}`)
  };

  const loadOptions = (inputValue, callback, url) => {
    setTimeout(() =>{
      api.get(url).then((response) => {
        const options = [];
        response.data.forEach((option) => {
          options.push({
            label: option.corporateName || option.name,
            value: option._id,
        });
      });

      let filterOptions = options.filter((o) =>
        Object.keys(o).some((k) =>
          o[k].toLowerCase().includes(inputValue.toLowerCase())
    )
  );

      callback(filterOptions);
    });
   }, 3000)
  };

  const onChangeText = (e, field) => {
    setValue(field, `*${e.target.value}`);

    if (e.target.value.length > 2 || e.target.value.length === 0) {
      onSubmit();
    }
  };

  const onChangeSelect = (e, field, type) => {
    setValue(field, `${e.map(({ value }) => value)}`);

    onSubmit();
  };

  const periodOptions = [
    {
      value: "Hoje",
      startAt: moment(new Date()).startOf("day").subtract(3, "h").toISOString(),
      endAt: moment(new Date()).endOf("day").subtract(3, "h").toISOString(),
      handlePeriod: async (startAt, endAt) => {

        await onSubmit(
          `updatedAt=gte:${new Date(
            startAt
          ).toISOString()}&updatedAt=lte:${new Date(endAt).toISOString()}`
        );
      },
    },
    {
      value: "Semana",
      startAt: moment(new Date()).subtract(7, "day").startOf("day").subtract(3, "h").toISOString(),
      endAt: moment(new Date()).endOf("day").subtract(3, "h").toISOString(),
      handlePeriod: async (startAt, endAt) => {
        await onSubmit(  
          `updatedAt=gte:${new Date(
            startAt
          ).toISOString()}&updatedAt=lte:${new Date(
            endAt
          ).toISOString()}`
        );
      },
    },
    {
      value: "Mês",
      startAt: moment(new Date()).startOf("month").subtract(3, "h").toISOString(),
      endAt: moment(new Date()).endOf("month").subtract(3, "h").toISOString(),
      handlePeriod: async (startAt, endAt, queryParams) => {
        await onSubmit(
          `updatedAt=gte:${new Date(
            startAt
          ).toISOString()}&updatedAt=lte:${new Date(
            endAt
          ).toISOString()}`
        );
      },
    },
    {
      value: "Ano",
      startAt: moment(new Date()).startOf("year").subtract(3, "h").toISOString(),
      endAt: moment(new Date()).endOf("year").subtract(3, "h").toISOString(),
      handlePeriod: async (startAt, endAt) => {


        await onSubmit(
          `updatedAt=gte:${new Date(
            startAt
          ).toISOString()}&updatedAt=lte:${new Date(
            endAt
          ).toISOString()}`
        );
      },
    },
    {
      value: "Período Específico",
      handlePeriod: async () => {
        setIsOpenPeriodModal(true);
      },
    },
  ];

  const lastContactOptions = [
    {
      color: "#05E9BE",
      checked: lastContactChecked.fiveDays,
      onChange: async () => {
        setLastContactChecked({
          fiveDays: !lastContactChecked.fiveDays,
          tenDays: false,
          moreThanDays: false,
        });

        if (!lastContactChecked.fiveDays) {
          await onSubmit(
            `updatedAt=gte:${moment(new Date())
              .subtract(5, "days")
              .startOf("d")
              .subtract(3, "hours")
              .toISOString()}&updatedAt=lte:${moment(new Date())
                .subtract(3, "hours")
                .endOf("d")
                .toISOString()}`
          );
        } else {
          onSubmit()
        }
      },
    },
    {
      color: "#FC8D72",
      checked: lastContactChecked.tenDays,
      onChange: async () => {
        setLastContactChecked({
          fiveDays: false,
          tenDays: !lastContactChecked.tenDays,
          moreThanDays: false,
        });

        if (!lastContactChecked.tenDays) {
          await onSubmit(
            `updatedAt=gte:${moment(new Date())
              .subtract(9, "days")
              .startOf("d")
              .subtract(3, "hours")
              .toISOString()}&updatedAt=lte:${moment(new Date())
              .subtract(5, "days")
              .startOf("d")
              .subtract(3, "hours")
              .toISOString()}`
          );
        } else {
          onSubmit();
        }
      },
    },
    {
      color: "#890001",
      checked: lastContactChecked.moreThanDays,
      onChange: async () => {
        setLastContactChecked({
          fiveDays: false,
          tenDays: false,
          moreThanDays: !lastContactChecked.moreThanDays,
        });

        if (!lastContactChecked.moreThanDays) {
          await onSubmit(
            `updatedAt=gte:${moment(new Date())
              .subtract(60, "days") // [ajeitar logica depois]
              .startOf("d")
              .subtract(3, "hours")
              .toISOString()}&updatedAt=lte:${moment(new Date())
              .subtract(9, "days")
              .startOf("d")
              .subtract(3, "hours")
              .toISOString()}`
          );
        } else {
          onSubmit()
        }
      },
    },
  ];

  const onChangeStartDateOnPeriodFilter = (isoDate) => {
    setActualPeriod({
      startAt: isoDate,
      endAt: actualPeriod.endAt,
    });
  };

  const onChangeEndDateOnPeriodFilter = (isoDate) => {
    setActualPeriod({
      startAt: actualPeriod.startAt,
      endAt: isoDate,
    });
  };

  const getProfileLogged = React.useCallback(() => {
    if (user.role.name === "MANAGER_POS") {
      setQueryParams(`enterprise.posManagers=${user.id}`);
    } else if (user.role.name === "BROKER") {
      setQueryParams(`client.owner=${user.id}`);
    } else if (user.role.name === "MANAGER") {
      setQueryParams(`supperiorImediate=${user.id}`);
    } else if (user.role.name === "SALES_DIRECTOR") {
      setQueryParams(`ownersBelowChart=${user.id}`);
    } else if (user.role.name === "COMMERCIAL_DIRECTOR_INCORPORATOR") {
      setQueryParams(`enterprise.incorporators=${user.partner._id}`);
    } else if (user.role.name === "COORDINATOR_PRODUCTS") {
      setQueryParams(
        `enterprise.coordinatorsProducts=${user.id}&enterprise.realStates=${user.partner._id}`
      );
    } else if (user.role.name === "GENERAL_DIRECTOR") {
      setQueryParams(`partner._id=${user.partner._id}`);
    } else {
      setQueryParams("&")
    }

  }, [user.role, user.id, user.partner?._id])

  React.useEffect(() => {
    getProfileLogged()
  }, [getProfileLogged]);

  React.useEffect(() => {
      setPeriods(periodOptions);
      const fetchColumns = async () => {
        await fetchData()
      }

      if (queryParams) {
        fetchColumns()  
      }
  }, [queryParams]);

  return (
    <div className="content" style={{ height: "100vh" }}>
      <FilterTable onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Row style={{ marginBottom: 15 }}>
            <Col>
              <Label for="period">Período</Label>
              <div>
                {periods &&
                  periods.map((period, index) => (
                    <Button
                      className="btn btn-primary mr-2"
                      key={index}
                      onClick={() =>
                        period.handlePeriod(period.startAt, period.endAt)
                      }
                    >
                      {period.value}
                    </Button>
                  ))}
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: 15 }}>
            <Col>
              <Label for="period">Ultimo Contato</Label>
              <div
                style={{
                  marginLeft: 20,
                  display: "flex",
                  gap: 35,
                  justifyContent: "flex-start",
                  alignItems: "baseline",
                }}
              >
                {lastContactOptions.map((lastContact, index) => (
                  <FormGroup
                    style={{ display: "flex", alignItems: "center" }}
                    key={index}
                  >
                    <Input
                      type="checkbox"
                      style={{
                        accentColor: lastContact.color,
                      }}
                      checked={lastContact.checked}
                      onChange={lastContact.onChange}
                    />
                    <i
                      style={{
                        color: lastContact.color,
                        marginTop: 4,
                      }}
                      className="fa-solid fa-clock"
                    />
                  </FormGroup>
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            {
              HasPermission('clients.panel.filter.view.name') && (
                <Col>
                  <Label for="name">Nome</Label>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Nome"
                    id="name"
                    {...register("client.name")}
                    onChange={(e, _) => onChangeText(e, "client.name")}
                  />
                </Col>
              )
            }
            {
              HasPermission('clients.panel.filter.view.owner') && (
                <Col>
                  <Label for="owner">Dono</Label>
                  <AsyncSelect
                    classNamePrefix="react-select"
                    className="react-select info"
                    placeholder="Selecionar Dono"
                    name="owners"
                    isSearchable
                    isMulti
                    closeMenuOnSelect={false}
                    cacheOptions
                    defaultOptions
                    loadOptions={(inputValue, callback, url) =>
                      loadOptions(inputValue, callback, "users/possible-owners")
                    }
                    {...register("client.owner")}
                    onChange={(e, _) => onChangeSelect(e, "client.owner", "client")}
                  />
                </Col>
              )
            }
            {
              HasPermission('clients.panel.filter.view.productCoordinator') && (
                <Col>
                  <Label for="coordinatorsProducts">Coordenador de Produto</Label>
                  <AsyncSelect
                    classNamePrefix="react-select"
                    className="react-select info"
                    placeholder="Selecionar Coordenador de Produto"
                    name="coordinatorsProducts"
                    isSearchable
                    isMulti
                    closeMenuOnSelect={false}
                    cacheOptions
                    defaultOptions
                    loadOptions={(inputValue, callback, url) =>
                      loadOptions(
                        inputValue,
                        callback,
                        "users/find/coordinators-products"
                      )
                    }
                    {...register("enterprise.coordinatorsProducts")}
                    onChange={(e, _) =>
                      onChangeSelect(
                        e,
                        "enterprise.coordinatorsProducts",
                        "enterprise"
                      )
                    }
                  />
                </Col>
              )
            }
          </Row>
          <Row>
            {
              HasPermission('clients.panel.filter.view.posManagers') && (
                <Col>
                  <Label for="posManagers">Gestor de PDV</Label>
                  <AsyncSelect
                    classNamePrefix="react-select"
                    className="react-select info"
                    placeholder="Selecionar Dono"
                    name="posManagers"
                    isSearchable
                    isMulti
                    closeMenuOnSelect={false}
                    cacheOptions
                    defaultOptions
                    loadOptions={(inputValue, callback, url) =>
                      loadOptions(inputValue, callback, "users/find/pos-managers")
                    }
                    {...register("enterprise.posManagers")}
                    onChange={(e, _) =>
                      onChangeSelect(e, "enterprise.posManagers", "enterprise")
                    }
                  />
                </Col>
              )
            }
            {
              HasPermission("clients.panel.filter.view.realState") && (
                <Col>
                  <Label for="realState">Imobiliária</Label>
                  <AsyncSelect
                    classNamePrefix="react-select"
                    className="react-select info"
                    placeholder="Selecionar Imobiliaria"
                    name="realState"
                    isSearchable
                    isMulti
                    closeMenuOnSelect={false}
                    cacheOptions
                    defaultOptions
                    loadOptions={(inputValue, callback, url) =>
                      loadOptions(inputValue, callback, "partners/find/realstate")
                    }
                    {...register("client.owner.partner._id")}
                    onChange={(e, _) =>
                      onChangeSelect(e, "partner._id", "client.realState")
                    }
                  />
                </Col>
              )
            }
            {
              HasPermission('clients.panel.filter.view.incorporator') && (
                <Col>
                  <Label for="incorporators">Incorporador</Label>
                  <AsyncSelect
                    classNamePrefix="react-select"
                    className="react-select info"
                    placeholder="Selecionar Incorporador"
                    name="incorporators"
                    isSearchable
                    isMulti
                    closeMenuOnSelect={false}
                    cacheOptions
                    defaultOptions
                    loadOptions={(inputValue, callback, url) =>
                      loadOptions(
                        inputValue,
                        callback,
                        "partners/find/incorporators"
                      )
                    }
                    {...register("enterprise.incorporators")}
                    onChange={(e, _) =>
                      onChangeSelect(e, "enterprise.incorporators", "incorporator")
                    }
                  />
                </Col>
              )
            }
          </Row>
        </FormGroup>
      </FilterTable>

        {columns &&   
          <PanelCardContainer 
            data={columns} 
            isLoading={isLoading} 
            queryParams={queryParams}
            filterParams={getValues()}
          />
        }
      <ActualPeriodModal
        isOpen={isOpenPeriodModal}
        onClose={() => setIsOpenPeriodModal(false)}
        onChangeStartAt={onChangeStartDateOnPeriodFilter}
        onChangeEndAt={onChangeEndDateOnPeriodFilter}
        onSubmit={() => {
          onSubmit(
            `updatedAt=gte:${new Date(
              actualPeriod.startAt
            ).toISOString()}&updatedAt=lte:${new Date(
              actualPeriod.endAt
            ).toISOString()}`
          );

          setActualPeriod({
            startAt: "",
            endAt: "",
          });
        }}
      />
    </div>
  );
};

export default BusinessPanel;
