import React, { useEffect } from "react";
import classNames from "classnames";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Label,
  Input,
} from "reactstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import { Link } from "react-router-dom";
import api from "../../../api/index";
import { HasPermission } from "variables/permissions";
import { translatePartners } from "variables/translatePartners";
import { TableHeader } from "views/components/TableHeader";
import { ModalDeleteItem } from "views/components/ModalDeleteItem";
import { useForm } from "react-hook-form";
import { filterByUrlParams } from "variables/filter-by-url-params";
import { FilterTable } from "views/components/FilterTables";
import AsyncSelect from "react-select/async";
import { Base64 } from "js-base64";

const Partners = (props) => {
  const [data, setData] = React.useState([]);
  const [toggleModalRemove, setToggleModalRemove] = React.useState(false);
  const [removeId, setRemoveId] = React.useState(null);
  const [base64, setBase64] = React.useState("");
  const { register, handleSubmit, setValue, getValues } = useForm();

  const removeData = async (id) => {
    const { status } =  await api.delete(`/partners/${id}`);
    if(status === 200) {
      setToggleModalRemove(false);
      getData();
    }
    setRemoveId(null);
  };

  const loadOptions = (inputValue, callback, isType = false) => {
    api.get('partners')
    .then((response) => {
      const options = []
      response.data.forEach((option) => {
        options.push({
          label: isType ? translatePartners(option.typePartner) : option.fantasyName,
          value: isType ? option.typePartner : option._id
        })
      })


      let filterOptions = options.filter(o =>
        Object.keys(o).some(k => o[k].toLowerCase().includes(inputValue.toLowerCase())));
        
      callback(filterOptions);
    })
  }

  const onSubmit = (e) => {
    const params = filterByUrlParams(e)

    getData(params)
  }

  const onChangeText = (e, field) => {
    setValue(field, `*${e.target.value}`)
    const params = getValues();
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      onSubmit(params)
    }
  }

  const onChangeSelect = (e, field) => {
    setValue(field, `${e.map(({ value }) => value)}`)
    const params = getValues();
    onSubmit(params)
  }

  const onExport = async (params = "") => {
    const { data } = await api.get(`/partners?${params}`, {
       headers: {
        'X-Custom-Response': 'csv'
       }
    });

    const csv = Base64.decode(data).toString()

    setBase64(csv)
  }

  const getData = async (params = "") => {
    onExport(params)

    const { data } = await api.get(`/partners?${params}`);
    setData(data);
  };

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <div className="content">
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <FilterTable onSubmit={handleSubmit((e) => onSubmit(e))}>
            <Row>
               {
                HasPermission('partner.filter.nameFantasy') && (
                  <Col>
                    <Label for="fantasyName">Nome Fantasia</Label>
                    <Input 
                      type="text"
                      placeholder="Nome fantasia"
                      name="fantasyName"
                      id="fantasyName"
                      {...register('fantasyName')}
                      onChange={(e, _) => onChangeText(e, 'fantasyName')}
                    />
                  </Col> 
                )
               }
               {
                HasPermission("partner.filter.type") && (
                  <Col>
                    <Label for="typePartner">Tipo</Label>
                    <AsyncSelect 
                      classNamePrefix="react-select"
                      className="react-select info"
                      placeholder="Selecionar Tipo"
                      name="typePartners"
                      isSearchable
                      closeMenuOnSelect={false}
                      cacheOptions
                      isMulti
                      defaultOptions
                      loadOptions={(inputValue, callback, isType) => loadOptions(inputValue, callback, true)}
                      {...register('typePartner')}
                      onChange={(e, _) => onChangeSelect(e, 'typePartner')}
                    />
                  </Col> 
                )
               }
              </Row>
            </FilterTable>
            <Card>
              <TableHeader 
                title="Listar Parceiros"
                permission="partners.create"
                linkToCreate="/admin/partner/new"
                csv={base64}
                hasCSV="partners.export"
              />
              <CardBody>
                <ReactTable
                  data={data}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Nome Fantasia",
                      Cell: data => {
                        return <p>{data.row.original.fantasyName}</p>
                      }
                    },
                    {
                      Header: "Tipo",
                      Cell: data => {
                        return <p>{translatePartners(data.row.original.typePartner)}</p>
                      }
                    },
                    {
                      Header: "Ações",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                      Cell: data => 
                        (
                        <div className="actions-right">
                         {
                          HasPermission("partners.edit") && (
                            <Link to={`/admin/partner/${data.row.original._id}/edit`}>
                              <Button
                                color="warning"
                                size="sm"
                                className={classNames("btn-icon btn-link edit", {
                                  "btn-neutral":  false
                                })}
                              >
                                <i className="tim-icons icon-pencil" />
                              </Button>
                            </Link>
                          )
                         }
                         {
                          HasPermission("partners.delete") && (
                            <Button
                              onClick={() => {
                                setToggleModalRemove(true);
                                setRemoveId(data.row.original._id);
                              }}
                              color="danger"
                              size="sm"
                              className={classNames("btn-icon btn-link remove", {
                                "btn-neutral":  false
                              })}
                            >
                              <i className="tim-icons icon-simple-remove" />
                            </Button>
                          )
                         }
                          
                        </div>
                      )
                    }
                  ]}
                  defaultPageSize={10}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <ModalDeleteItem 
        title="Deseja realmente excluir este parceiro?"
        isOpen={toggleModalRemove}
        setToggleModal={() => setToggleModalRemove(false)}
        removeData={() => removeData(removeId)}
      />
    </>
  );
}
export default Partners;