import WizardCreatePartner from "views/pages/Partners/Create/WizardPartner.js";
import Enterprises from "views/pages/Enterprises";
import Partners from "views/pages/Partners";
import WizardEnterprise from "views/pages/Enterprises/Create/WizardEnterprise";
import Stands from "views/pages/Stands";
import CreateStand from "views/pages/Stands/Create";
import Clients from "views/pages/Clients";
import { BusinessClosed } from "views/pages/Clients/BusinessClosed";
import { BusinessDiscarded } from "views/pages/Clients/BusinessDiscarded";

export const incorporationServiceRoutes = [
    {
        path: "/partners",
        name: "Parceiros",
        component: Partners,
        icon: "fa fa-briefcase",
        layout: "/admin",
      },
      {
        path: "/partner/new",
        name: "Criar Parceiro",
        component: WizardCreatePartner,
        layout: "/admin",
        invisible: true,
      },
      {
        path: "/enterprises",
        name: "Empreendimentos",
        icon: "fa fa-building",
        component: Enterprises,
        layout: "/admin",
      },
      {
        path: "/enterprise/new",
        name: "Criar Empreendimento",
        component: WizardEnterprise,
        layout: "/admin",
        invisible: true,
      },
      {
        path: "/stands",
        name: "Stands",
        icon: "tim-icons icon-badge",
        component: Stands,
        layout: "/admin"
      },
      {
        path: "/stand/new",
        name: "Criar Stand",
        component: CreateStand,
        layout: "/admin",
        invisible: true
      },
      {
        collapse: true,           
        name: "Clientes",
        icon: "tim-icons icon-badge",
        state: "pagesCollapse",
        views: [
          {
            path: "/clients",
            name: "Listagem de Clientes",
            mini: "C",
            component: Clients,
            layout: "/admin"
          },
          {
            path: "/business-closed",
            name: "Negócios Fechados",
            mini: "NF",
            component: BusinessClosed,
            layout: "/admin"
          },
          {
            path: "/business-discarded",
            name: "Negócios Descartados",
            mini: "ND",
            component: BusinessDiscarded,
            layout: "/admin"
          },
        ] 
      },
]