import React from "react"
import CurrencyInput from "react-currency-input"
import { Button, Card, CardBody, CardFooter, Col, FormGroup, Input, Label, Row } from "reactstrap"
import { PhoneNumberInput } from "./PhoneNumberInput"
import api from "api"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import NotificationAlert from "react-notification-alert";

export const ClientFormWithoutAdmin = (props) => {
    const [ phoneNumbers, setPhoneNumbers ] = React.useState([])
    const [averageTicket, setAverageTicket] = React.useState("");
    const [ ticket, setTicket ] = React.useState("");
    const [ isDisabled, setIsDisabled ] = React.useState(false)

    const { clientId, phoneNumbersList, averageTicketValue } = props;

    const history = useHistory();

    const notificationAlertRef = React.useRef(null);

    React.useEffect(() => {
        if (ticket.length > 2) {
          setAverageTicket(ticket.substring(0, ticket.length - 2).replace(",", "").replace("R$", "").replace(".", ""))
        }
      }, [ticket])
    
      React.useEffect(() => {
        setPhoneNumbers(phoneNumbersList)
      }, [phoneNumbersList])

      React.useEffect(() => {
        setTicket(averageTicketValue)
      }, [averageTicketValue])

      const onCreateNewPhoneNumber = (type, value) => {
        setPhoneNumbers([...phoneNumbers, {type, value}])
        setIsDisabled(false)
      }

      const handleSubmit = async () => {
        const averageTicketOnNumber = Number(averageTicket.replace(",", ".").split('.').join(""))

        const { data, status } = await api.patch(`/clients/${clientId}`, {
            averageTicket: averageTicket ? averageTicketOnNumber : Number(ticket),
            phoneNumbers: phoneNumbers,
        });
    
        let options = {};
    
        if(status !== 201) {
          options = {
            place: "tr",
            message: data,
            type: "danger",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
          };
        }
    
        options = {
          place: "tr",
          message: "Cliente modificado com sucesso!",
          type: "success",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7
        };
    
        notificationAlertRef.current.notificationAlert(options);  
        setTimeout(() => {
          history.goBack();
        }, 2000)
      }

      const translateType = (type) => {
        if (type === "Work") {
            return "Trabalho"
        }

        return "Pessoal" 
      }

    return (
        <Card className="col-auto - variable width content" >
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <CardBody>
                <Row>
                    <Col sm="3">
                        <FormGroup>
                            <Label for="averageTicket">Ticket Médio</Label>
                            <CurrencyInput 
                                name="averageTicket"
                                placeholder="Ticket Médio"
                                className="form-control"
                                id="averageTicket"
                                inputType="text"
                                prefix="R$"
                                decimalSeparator=","
                                thousandSeparator="."
                                value={ticket}
                                onChangeEvent={(e) => setTicket(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        <FormGroup>
                            <Label for="telephone">Telefone</Label>
                           {
                            phoneNumbers && phoneNumbers.map((phoneNumber, index) => (
                                <Input
                                    key={index}
                                    name="phoneNumber"
                                    disabled
                                    style={{ 
                                        fontWeight: "bold", 
                                        maxWidth: 310, 
                                        width: "100%",
                                        marginBottom: 6
                                    }}
                                    placeholder="Informe o Telefone..."
                                    id="telephone"
                                    value={`${phoneNumber.value} (${translateType(phoneNumber.type)})`}
                                    type="text"
                                />
                            ))
                           }
                        </FormGroup>
                        {
                            isDisabled && (
                                <FormGroup>
                                    <PhoneNumberInput onCreateNewPhoneNumber={onCreateNewPhoneNumber} />
                                </FormGroup>
                            )
                        }
                        <Button 
                            className="btn btn-info mt-3" 
                            disabled={isDisabled}
                            onClick={() => setIsDisabled(true)}
                        >
                            <i className="tim-icons icon-simple-add" />
                        </Button>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter>
                <Button className="btn btn-fill" onClick={handleSubmit}>Salvar</Button>
            </CardFooter>
        </Card>
    )
}