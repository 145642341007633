import React from "react";
import { Button, Card, CardBody, CardFooter, CardTitle } from "reactstrap"

export const FilterTable = (props) => {
  const [showFilter, setShowFilter] = React.useState(false);

    const {
        children,
        onSubmit
    } = props;

    return (
        <Card
          className="px-3"
          style={{
            position: 'relative',
          }}
        >
        <CardBody style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}} onClick={() => setShowFilter(!showFilter)}>
          <CardTitle className="text-white">Filtros</CardTitle>
          <i 
            className="tim-icons icon-minimal-down drop-filter" 
            style={{
              cursor: 'pointer',
              color: '#fff',
              marginRight: 10,
              marginTop: 10
            }}
          />
        </CardBody>
        <CardFooter
            className="drop-filter"
            style={{
              display: showFilter ? 'block' : 'none',
            }}
        >
          <form onSubmit={onSubmit} className="drop-filter notification-dropdown">

          {children}
          <Button type="submit" className="btn btn-primary my-4">Filtrar</Button>
          </form>
      </CardFooter>
      </Card>
    )
}