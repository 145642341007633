import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Row,
  Button
} from "reactstrap";
import UnitFormComponents from "views/components/UnitFormComponents";


const INITAL_UNIT = 0
const CreateUnit = forwardRef((props, ref) => {
    const type = props.wizardData?.Cadastrar?.type;
    const [units, setUnits] = useState([]);

    useImperativeHandle(ref, () => ({
      isValidated: () => {
        return true;
      },
      state: {
        units
      },
    }));

    useEffect(() => {
      if(units.length === 0)
        [...Array(INITAL_UNIT)].forEach(() => handleCreateUnit())
    },[])
    
    const handleCreateUnit = () => {
      setUnits(oldUnits => [...oldUnits, { id: Math.floor(Math.random() * (1 - 0 + 1) + 0), nameTower: null, number: null, privateArea: null, idealFraction: null, qtyRoom: null, qtySpaces: null, parkingsSpaceNumber: null}])
    }

    const handleEditUnit = (unitId, data) => {
      setUnits(oldUnits => [...oldUnits.map((unit) => {
        if(unit.id === unitId) {
          unit.nameTower = data.nameTower
          unit.number = data.number
          unit.privateArea = data.privateArea
          unit.idealFraction = data.idealFraction
          unit.qtyRoom = data.qtyRoom
          unit.qtySpaces = data.qtySpaces
          unit.parkingsSpaceNumber = data.parkingsSpaceNumber

        }
        return unit
      })])
    }
    
    return (
      <>
      {
        units?.map((unit, i) => (
          <div key={i}>
            <h2 style={{ textAlign: "center"}}>Unidade {i + 1}</h2>
            <UnitFormComponents key={unit.id} towers={props.wizardData?.Torres?.towers} type={type} onData={(data) => handleEditUnit(unit.id, data) }/>
          </div>

        ))
      }
      <Row className="justify-content-center mt-5">
        <Button className="btn btn-info" onClick={handleCreateUnit}>
          <i className="tim-icons icon-simple-add"></i>
        </Button>
      </Row>
     </>
    );
});

export default CreateUnit;