import React, { useEffect } from "react";
import {  Col, FormGroup, Input, Label, Row } from "reactstrap";

const TowerFormComponents = (props) => {
  const [name, setName] = React.useState(null);
  const [qtyAptPerFloor, setQtyAptPerFloor] = React.useState(null);
  const [qtyFlooring, setQtyFlooring] = React.useState(null);
  
  useEffect(() => {
    props.onData({
      name,
      qtyAptPerFloor,
      qtyFlooring
    });
  }, [name, qtyAptPerFloor, qtyFlooring])

  useEffect(() => {
    const tower = props.tower
    if(!tower) return
    setName(tower.name)
    setQtyAptPerFloor(tower.qtyAptPerFloor)
    setQtyFlooring(tower.qtyFlooring)
  },[props.tower])

    return (
      <>
        <Row className="justify-content-center mt-2">
          <Col sm="5">
            <FormGroup>
              <Label for="name">Nome</Label>
              <Input
                name="name"
                id="name"
                placeholder="Informe o Nome..."
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm="5">
            <FormGroup>
              <Label for="qtyAptPerFloor">Qtd. apt. por andar</Label>
              <Input
                name="qtyAptPerFloor"
                placeholder="Informe a quantidade..."
                id="qtyAptPerFloor"
                type="number"
                value={qtyAptPerFloor}
                onChange={(e) => setQtyAptPerFloor(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm="5">
            <FormGroup>
              <Label for="qtyFlooring">Qtd. de pavimentos</Label>
              <Input
                name="qtyFlooring"
                placeholder="Informe a quantidade de pavimentos..."
                id="qtyFlooring"
                type="number"
                value={qtyFlooring}
                onChange={(e) => setQtyFlooring(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  };
  
  export default TowerFormComponents;
  