import BusinessPanel from "views/pages/BusinessPanel";
import Clients from "views/pages/Clients";
import WizardClient from "views/pages/Clients/Create/WizardClient";
import EditClient from "views/pages/Clients/Edit/EditClient";
import { CustomerManagementPage } from "views/pages/CustomerManagement";
import Enterprises from "views/pages/Enterprises";

export const brokerRoutes = [
    {
        path: "/enterprises",
        name: "Empreendimentos",
        icon: "fa fa-building",
        component: Enterprises,
        layout: "/admin",
      },
      {
        collapse: true,           
        name: "Clientes",
        icon: "tim-icons icon-badge",
        state: "pagesCollapse",
        views: [
          {
            path: "/clients",
            name: "Listagem de Clientes",
            mini: "C",
            component: Clients,
            layout: "/admin"
          },
          {
            path: "/businessPanel",
            name: "Painel de Negócios",
            mini: "PN",
            component: BusinessPanel,
            layout: "/admin"
          },
        ] 
      },
      {
        path: "/customerManagement/:clientId",
        name: "Gestão de Cliente",
        component: CustomerManagementPage,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/client/new",
        name: "Criar Cliente",
        component: WizardClient,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/client/:clientId/edit",
        name: "Editar Cliente",
        component: EditClient,
        layout: "/admin",
        invisible: true,
      },
]