import { CardHeader } from "reactstrap";

const PanelCardHeader = (props) => {

    const { 
        step, 
        quantity, 
        value,
    } = props

    return (
    <CardHeader className="p-0">
        <div 
            style={{
                backgroundColor: "#1E8CF9",
                padding: 14,
                position: "relative",
                minHeight: 130
            }}
        >
            <h5 style={{ fontWeight: "bold", marginBottom: 8}}>{step}</h5>
            <p>Qtd: {quantity}</p>
            <p>Vgv: {value?.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            })}
            </p>
        </div>
    </CardHeader>
    )
}

export default PanelCardHeader;