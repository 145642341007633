export const translatePartners = (type) => {
    switch (type) {
        case "incorporator":
            return "Incorporador"
        case "realState":
            return "Imobiliária"
        case "house":
            return "House"
        default:
            return "Proprietário"
    }
}