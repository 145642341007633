import api from "../../../api/index";
import cep from "cep-promise";
import ImageUpload from "components/CustomUpload/ImageUpload";
import React, { useEffect } from "react";
import 'moment/locale/pt-br';
import Datetime from "react-datetime";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, Col, FormGroup, Input, Label, Row } from "reactstrap";
import moment from "moment";
import NotificationAlert from "react-notification-alert";
import InputMask from "react-input-mask";
import  Select from "react-select";

const EditUser = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [documentNumber, setDocumentNumber] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [role, setRole] = React.useState("");
  const [dateBirth, setDateBirth] = React.useState("");
  const [schooling, setSchooling] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [number, setNumber] = React.useState("");
  // const [complement, setComplement] = React.useState("");
  const [neighborhood, setNeighborhood] = React.useState("");
  const [state, setState] = React.useState("");
  const [city, setCity] = React.useState("");
  const [avatarUrl, setAvatarUrl] = React.useState("");
  const [data, setData] = React.useState([]);
  const [partnerId, setPartnerId] = React.useState("");
  const [roles, setRoles] = React.useState([]);

  const notificationAlertRef = React.useRef(null);
  const history = useHistory();

  const { params } = useRouteMatch()

  const getData = async () => {
    const { data } = await api.get(`/users/${params.userId}`);
    setName(data.name);
    setEmail(data.email);
    setRole(data.role);
    setDateBirth(moment(data.dateBirth).utc());
    setSchooling(data.schooling);
    setDocumentNumber(data.documentNumber);
    setPhoneNumber(data.phoneNumber);
    setZipCode(data.zipCode);
    setStreet(data.street);
    setNumber(data.number);
    setNeighborhood(data.neighborhood);
    setState(data.state);
    setCity(data.city);
    setPartnerId(data.partner._id);
    setAvatarUrl(data.avatarUrl)
  };

  async function handleFindPartner() {
    const { data: partners } = await api.get("/partners");

    partners.forEach((partner) => {
      data.push(
        {
          label: partner.corporateName,
          value: partner._id
        }
      );
    });
    setData(data);
  }

  async function handleFindAdrress(number) {
    if(number.length > 7) {
      try {
        let { street, neighborhood, city, state, cep: numberCep } = await cep(number);
        setZipCode(numberCep);
        setStreet(street);
        setNeighborhood(neighborhood);
        setCity(city);
        setState(state);
      } catch(error) {
        if (error instanceof Error) {
          console.log(error)
        }
      }
    }
  }

  const myUploader = (data) => {
    setAvatarUrl(data.Location);
  }

  const handleSubmit = async () => {
    let options = {};
   try {
     await api.patch(`/users/${params.userId}`, {
      name,
      email,
      documentNumber,
      phoneNumber,
      role,
      dateBirth,
      schooling,
      partner: partnerId,
      zipCode,
      street,
      number,
      neighborhood,
      state,
      city,
      avatarUrl
    });




    options = {
      place: "tr",
      message: "Usuário modificado com sucesso!",
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
  } catch (e) {
      options = {
        place: "tr",
        message: e.response.data.errors[0],
        type: "danger",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7
      }; 
  }

  notificationAlertRef.current.notificationAlert(options);  
  if (options.type === 'success') {
    setTimeout(() => {
      history.push("/admin/users");
    }, 2000)
  }
  
  }


  useEffect(() => {
    getData();
    handleFindPartner();
  }, [])

  
  useEffect(() => {
    const fetch = async () => {
      const response = await api.get("/roles");
      setRoles(response.data)
    }

    fetch()
  }, [])

  return (
    <>
      <div className="content"  style={{ width: "100%"}}>
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Card className="col-auto - variable width content">
          <CardBody>
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label for="name">Nome</Label>
                  <Input 
                    name="name"
                    placeholder="Informe seu nome..."
                    id="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="email">E-mail</Label>
                  <Input 
                    name="email"
                    placeholder="Informe seu e-mail..."
                    id="email"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}

                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label for="document-number">CPF</Label>
                  <Input 
                    name="documentNumber"
                    placeholder="CPF"
                    id="document-number"
                    type="text"
                    value={documentNumber}
                    mask="999.999.999-99"
                    tag={InputMask}
                    onChange={(e) => setDocumentNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="date-birth">Data de nascimento</Label>
                  <Datetime
                    locale="pt-br"
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    input={true}
                    id="date-birth"
                    value={dateBirth}
                    onChange={(date) => setDateBirth(moment(date._d).utc().format("YYYY-MM-DD"))}
                    inputProps={{placeholder:"Clique para informar sua data de nascimento..."}}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <FormGroup>
                  <Label for="role">Cargo</Label>
                  <Input 
                    name="role"
                    id="cargo"
                    type="select"
                    value={role._id}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option>
                      Selecione o cargo...
                    </option>
                    {
                      roles.map(({_id, translateRole}) => (
                        <option value={_id}>
                          {translateRole}
                        </option>
                      ))
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="4">
                <FormGroup>
                  <Label for="escolaridade">Escolaridade</Label>
                  <Input 
                    name="schooling"
                    id="escolaridade"
                    placeholder="Escolaridade"
                    type="select"
                    value={schooling}
                    onChange={(e) => setSchooling(e.target.value)}
                  >
                    <option>
                      Selecione a escolaridade...
                    </option>
                    <option value={"CompleteHighSchool"}>  
                      Ensino Médio Completo
                    </option>
                    <option value={"IncompleteHighSchool"}>
                      Ensino Médio Incompleto
                    </option>
                    <option value={"CompleteHigherEducation"}>
                      Ensino Superior Completo
                    </option>
                    <option value={"IncompleteHigherEducation"}>
                      Ensino Superior Incompleto
                    </option>
                    <option value={"CompletePrimaryEducation"}>
                      Ensino Fundamental Completo
                    </option>
                    <option value={"IncompletePrimaryEducation"}>
                      Ensino Fundamental Incompleto
                    </option>

                  </Input>
                </FormGroup>
              </Col>
              <Col sm="4">
                <FormGroup>
                  <Label for="partnerId">Empresa</Label>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    placeholder="Selecione a empresa associada..."
                    name="partnerId"
                    isSearchable
                    required
                    closeMenuOnSelect={true}
                    options={data}
                    value={data.filter(data => (
                      data.value === partnerId
                    ))}
                    onChange={(partner) => setPartnerId(partner.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="2">
                <FormGroup>
                  <Label for="zip-code">CEP</Label>
                  <Input 
                    name="zipCode"
                    placeholder="Informe o CEP..."
                    id="zip-code"
                    type="text"
                    value={zipCode}
                    onChange={(e) => handleFindAdrress(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup>
                  <Label for="street">Rua</Label>
                  <Input 
                    name="street"
                    placeholder="Informe a Rua..."
                    id="street"
                    type="text"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup>
                  <Label for="number">Número</Label>
                  <Input 
                    name="number"
                    placeholder="Informe o Número..."
                    type="text"
                    id="number"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="4">
                <FormGroup>
                  <Label for="neighborhood">Bairro</Label>
                  <Input 
                    name="neighborhood"
                    placeholder="Informe o Bairro..."
                    id="neighborhood"
                    type="text"
                    value={neighborhood}
                    onChange={(e) => setNeighborhood(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <FormGroup>
                  <Label for="telephone">Telefone</Label>
                  <Input 
                    name="phoneNumber"
                    placeholder="Informe o Telefone..."
                    id="telephone"
                    type="text"
                    value={phoneNumber}
                    mask="(99) 99999-9999"
                    tag={InputMask}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="4">
                <FormGroup>
                  <Label for="city">Cidade</Label>
                  <Input 
                    name="city"
                    placeholder="Informe a Cidade..."
                    id="city"
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="4">
                <FormGroup>
                  <Label for="state">Estado</Label>
                  <Input 
                    name="state"
                    placeholder="Informe o Estado..."
                    id="state"
                    type="text"
                    value={state}
                    onChange={(e) => setState(e.target.value)}

                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="text-center">
              <Col sm="12">
                <ImageUpload avatar={true}
                file={avatarUrl}             
                changeBtnClasses="btn-simple"
                addBtnClasses="btn-simple"
                removeBtnClasses="btn-simple"
                uploadHandler={myUploader}/>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button className="btn btn-fill" onClick={() => handleSubmit()}>Salvar</Button>
          </CardFooter>
        </Card>
      </div>
    </>
  )
}

export default EditUser;