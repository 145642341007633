import api from "api";
import classNames from "classnames";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useRouteMatch } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  CardGroup,
} from "reactstrap";


const LobSelection = forwardRef((props, ref) => {
  const [typePartner, setTypePartner] = useState("");

  function handleTypeSelect(key) {
    setTypePartner(key);
  }

  useImperativeHandle(ref, () => ({
    isValidated: undefined,
    state: {
      typePartner,
    }
  }));
  
  return (
    <>
      <CardGroup>
        {props.options.map((element => (
        <Card key={element.key}  className={classNames(
          {
            "card-selection": true,
            "card-selection-active": element.key == typePartner

          }
        )}
          onClick={() => handleTypeSelect(element.key)}>
            {element.img}
            <CardBody className="elementCard">
              <CardTitle tag="h2">
                {element.title}
              </CardTitle>
            </CardBody>
          </Card>
        )))}

      </CardGroup>
    </>
  )
})

export default LobSelection;

