import { Button, FormGroup, Input, Modal, ModalBody, ModalFooter } from "reactstrap"

export const ModalOwners = (props) => {

    const { 
        isOpen,
        onClose,
        owner,
        onChangeOwner,
        listOwners,
        onSubmit
    } = props;


    return (
        <Modal isOpen={isOpen} modalClassName="modal-black">
        <div className="modal-header">
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i className="tim-icons icon-simple-remove" onClick={onClose}></i>
        </button>
        <h5 className="modal-title">Selecione o Dono</h5>
        </div>
        <ModalBody>
            <FormGroup>
                <Input 
                    name="owner"
                    id="dono"
                    type="select"
                    value={owner}
                    onChange={onChangeOwner}
                >
                    <option>
                        Selecione um novo Dono...
                    </option>
                    {
                        listOwners.map(({name, _id}, index) => (
                            <option key={index} value={_id}>{name}</option>
                        ))
                    }
                </Input>
            </FormGroup>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={onSubmit}>Ok</Button>
        </ModalFooter>
    </Modal>
    )
}