import React from "react";

export const IconData = (props) => {

    const {
        bgIcon,
        children,
        className,
        icon,
        setToggleModal,
        width,
        height,
        color
    } = props;

    return (
        <div style={{ display: 'flex', alignItems: "baseline", gap: 6}}>
            <i 
                className={icon}
                style={{
                    width: width || 42,
                    height: height || 42,
                    backgroundColor: bgIcon,
                    marginBottom: 14,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    color: color || "#FFFFFF"
                }}
                onClick={setToggleModal}
            >
                <span className={className} style={{ color: "#fff", backgroundColor: '#27293D', padding: 8 }}>{children}</span>
            </i>
        </div>
    )
}