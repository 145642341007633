import * as React from "react";
import ReactTable from "components/ReactTable/ReactTable.js";
import api from "api";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import { TableHeader } from "views/components/TableHeader";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import classNames from "classnames";
import { ModalDeleteItem } from "views/components/ModalDeleteItem";
import { HasPermission } from "variables/permissions";
import { useForm } from "react-hook-form";
import { filterByUrlParams } from "variables/filter-by-url-params";
import { FilterTable } from "views/components/FilterTables";
import AsyncSelect from "react-select/async";


const Stands = () => {
    const [data, setData] = React.useState([]);
    const [toggleModalRemove, setToggleModalRemove] = React.useState(false);
    const [removeId, setRemoveId] = React.useState(null);
    const { handleSubmit, register, setValue, getValues } = useForm();

    const removeData = async (id) => {
        const { status } = await api.delete(`/stands/${id}`);
        if (status === 200) {
            setToggleModalRemove(false);
            getData();
        }
        setRemoveId(null);
    };

    React.useEffect(() => {
        getData()
    }, [])

    const getData = async (params = "") => {
        const { data } = await api.get(`/stands?${params}`);
        setData(data);
    }

    const onSubmit = (e) => {
        const params = filterByUrlParams(e)

        getData(params)
    }

    const onChangeText = (e, field) => {
        setValue(field, `*${e.target.value}`)
        const params = getValues();
        if (e.target.value.length > 2 || e.target.value.length === 0) {
            onSubmit(params)
        }
    }

    const onChangeSelect = (e) => {
        setValue('enterprise', `${e.map(({ value }) => value)}`)
        const params = getValues();
        onSubmit(params)
    }

    const loadOptions = (inputValue, callback) => {
        api.get(`enterprises`)
            .then((response) => {
                const options = []
                response.data.forEach((enterprise) => {
                    options.push({
                        label: enterprise.name,
                        value: enterprise._id
                    })
                });


                let filterOptions = options.filter(o =>
                    Object.keys(o).some(k => o[k].toLowerCase().includes(inputValue.toLowerCase())));

                callback(filterOptions);
            })
    }

    return (
        <>
            <div className="content">
                <Row className="mt-5">
                    <Col xs={12} md={12}>
                        <FilterTable onSubmit={handleSubmit((e) => onSubmit(e))}>
                            <Row>
                                <Col sm="6">
                                    <Label for="name">Nome</Label>
                                    <Input
                                        type="text"
                                        name="name"
                                        placeholder="Nome"
                                        id="name"
                                        {...register('name')}
                                        onChange={(e, _) => onChangeText(e, 'name')}
                                    />
                                </Col>
                                <Col sm="6">
                                    <Label for="enterprise">Empreendimentos</Label>
                                    <AsyncSelect
                                        className="react-select info"
                                        classNamePrefix="react-select"
                                        placeholder="Selecione os empreendimentos..."
                                        name="enterprises"
                                        isSearchable
                                        closeMenuOnSelect={false}
                                        isMulti
                                        loadOptions={loadOptions}
                                        cacheOptions
                                        defaultOptions
                                        {...register('enterprise')}
                                        onChange={(e, _) => onChangeSelect(e)}
                                    />
                                </Col>
                            </Row>
                        </FilterTable>
                        <Card>
                            <TableHeader
                                title="Listar Stands"
                                permission="stands.create"
                                linkToCreate="/admin/stand/new"
                            />
                            <CardBody>
                                <ReactTable
                                    data={data}
                                    filterable
                                    columns={[
                                        {
                                            Header: "Nome",
                                            Cell: data => (
                                                <p>{data.row.original.name}</p>
                                            )
                                        },
                                        {
                                            Header: "Empreendimento",
                                            Cell: data => (
                                                <p>{data.row.original.enterprise?.name}</p>
                                            )
                                        },
                                        {
                                            Header: "Ações",
                                            sortable: false,
                                            filterable: false,
                                            Cell: data => (
                                                <div className="actions-right">
                                                    {
                                                        HasPermission('stands.edit') && (
                                                            <Link to={`/admin/stand/${data.row.original._id}/edit`}>
                                                                <Button
                                                                    color="warning"
                                                                    size="sm"
                                                                    className={classNames("btn-icon btn-link edit", {
                                                                        "btn-neutral": false
                                                                    })}
                                                                >
                                                                    <i className="tim-icons icon-pencil" />
                                                                </Button>
                                                            </Link>
                                                        )
                                                    }

                                                    {
                                                        HasPermission('stands.delete') && (
                                                            <Button
                                                                onClick={() => {
                                                                    setToggleModalRemove(true);
                                                                    setRemoveId(data.row.original._id);
                                                                }}
                                                                color="danger"
                                                                size="sm"
                                                                className={classNames("btn-icon btn-link remove", {
                                                                    "btn-neutral": false
                                                                })}
                                                            >
                                                                <i className="tim-icons icon-simple-remove" />
                                                            </Button>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    ]}
                                    defaultPageSize={10}
                                    className="-striped -highlight"
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            <ModalDeleteItem
                title="Deseja realmente excluir este stand?"
                isOpen={toggleModalRemove}
                setToggleModal={() => setToggleModalRemove(false)}
                removeData={() => removeData(removeId)}
            />
        </>
    )
}

export default Stands