import { Label } from "reactstrap"
import { FilterTable } from "../FilterTables"
import AsyncSelect from "react-select/async";
import api from "api";
import { useForm } from "react-hook-form";
import { filterByUrlParams } from "variables/filter-by-url-params";
import { Stack } from "@mui/material";
import { UseScreenWidth } from "variables/screen-width";
import React from "react";

export const DashboardFilters = ({ buildCaption, fetchData }) => {

    const { screenWidth } = UseScreenWidth()
    const form = useForm()

    const onChangeSelect = (e, field) => {
        form.setValue(field, `${e.map(({ value }) => value)}`);
    
        onSubmit();

        buildCaption(field, e);
    };

    const onSubmit = () => {
        const params = form.getValues();
        const queryParams = filterByUrlParams(params);

        fetchData(queryParams);
    }


    const loadOptions = (inputValue, callback, url) => {
        api.get(url).then((response) => {
            const options = [];
            response.data.forEach((option) => {
                options.push({
                label: option.corporateName || option.name,
                value: option._id,
            });
            });

            let filterOptions = options.filter((o) =>
            Object.keys(o).some((k) =>
                o[k].toLowerCase().includes(inputValue.toLowerCase())
            )
            );

            callback(filterOptions);
         });
    };



    return (
        <FilterTable onSubmit={form.handleSubmit(onSubmit)}>
            <Stack 
                direction="column" 
                useFlexGap 
                spacing={4}
            >
                <Stack 
                    direction={ screenWidth > 1025 ? "row" : "column"}
                    useFlexGap
                    spacing={screenWidth > 1025 ? 8 : 4} 
                    justifyContent="space-between"
                    width="100%" 
                    alignItems="center"
                >
                    <div style={{ width: '100%', maxWidth: screenWidth > 1025 ? "28%" : "100%" }}>
                        <Label style={{ marginBottom: 8}} htmlFor="enterprise">Empreendimentos</Label>
                        <AsyncSelect
                            classNamePrefix="react-select"
                            className="react-select info"
                            placeholder="Selecionar Empreendimentos"
                            name="enterprise"
                            id="enterprise"
                            isSearchable
                            isMulti
                            closeMenuOnSelect={false}
                            cacheOptions
                            defaultOptions
                            loadOptions={(inputValue, callback, url) =>
                                loadOptions(inputValue, callback,"enterprises")
                            }
                            {...form.register("enterprise._id")}
                            onChange={(e) => onChangeSelect(e, "enterprise._id")}
                        />
                    </div>
                   <div style={{ width: '100%', maxWidth: screenWidth > 1025 ? "28%" : "100%" }}>
                        <Label style={{ marginBottom: 8}} htmlFor="owner">Donos</Label>
                        <AsyncSelect
                            classNamePrefix="react-select"
                            className="react-select info"
                            placeholder="Selecionar Donos"
                            name="owner"
                            id="owner"
                            isSearchable
                            isMulti
                            closeMenuOnSelect={false}
                            cacheOptions
                            defaultOptions
                            loadOptions={(inputValue, callback, url) =>
                                loadOptions(inputValue, callback, "users/possible-owners")
                            }
                            {...form.register("client.owner")}
                            onChange={(e, _) => onChangeSelect(e, "client.owner")}
                        />
                   </div>
                   <div style={{ width: '100%', maxWidth: screenWidth > 1025 ? "28%" : "100%" }}>
                        <Label style={{ marginBottom: 8}} htmlFor="manager">Gerentes</Label>
                        <AsyncSelect
                            classNamePrefix="react-select"
                            className="react-select info"
                            placeholder="Selecionar Gerentes"
                            name="manager"
                            id="manager"
                            isSearchable
                            isMulti
                            closeMenuOnSelect={false}
                            cacheOptions
                            defaultOptions
                            loadOptions={(inputValue, callback, url) =>
                                loadOptions(inputValue, callback, "users/find/manager")
                            }
                            {...form.register("manager")}
                            onChange={(e, _) => onChangeSelect(e, "manager")}
                        />
                   </div>
                </Stack>
                <Stack 
                    direction={ screenWidth > 1025 ? "row" : "column"}
                    useFlexGap
                    spacing={screenWidth > 1025 ? 8 : 4} 
                    width="100%" 
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <div style={{ width: '100%', maxWidth: screenWidth > 1025 ? "28%" : "100%"}}>
                        <Label style={{ marginBottom: 8}} htmlFor="sales-director">Diretores de Vendas</Label>
                        <AsyncSelect
                            classNamePrefix="react-select"
                            className="react-select info"
                            placeholder="Selecionar Diretores de Vendas"
                            name="sales-director"
                            id="sales-director"
                            isSearchable
                            isMulti
                            closeMenuOnSelect={false}
                            cacheOptions
                            defaultOptions
                            loadOptions={(inputValue, callback, url) =>
                                loadOptions(inputValue, callback,"users/find/sales-director")
                            }
                            {...form.register("salesDirector")}
                            onChange={(e) => onChangeSelect(e, "salesDirector")}
                        />
                    </div>
                   <div style={{ width: '100%', maxWidth: screenWidth > 1025 ? "28%" : "100%"}}>
                        <Label style={{ marginBottom: 8}} htmlFor="commercial-director">Diretores Comerciais</Label>
                        <AsyncSelect
                            classNamePrefix="react-select"
                            className="react-select info"
                            placeholder="Selecionar Diretores Comerciais"
                            name="commercial-director"
                            id="commercial-director"
                            isSearchable
                            isMulti
                            closeMenuOnSelect={false}
                            cacheOptions
                            defaultOptions
                            loadOptions={(inputValue, callback, url) =>
                                loadOptions(inputValue, callback, "users/find/commercial-director")
                            }
                            {...form.register("commercialDirector")}
                            onChange={(e, _) => onChangeSelect(e, "commercialDirector")}
                        />
                   </div>
                   <div style={{ width: '100%', maxWidth: screenWidth > 1025 ? "28%" : "100%"}}>
                        <Label style={{ marginBottom: 8}} htmlFor="immediate-superior">Superior Imediato</Label>
                        <AsyncSelect
                            classNamePrefix="react-select"
                            className="react-select info"
                            placeholder="Selecionar Superiores Imediatos"
                            name="immediate-superior"
                            id="immediate-superior"
                            isSearchable
                            isMulti
                            closeMenuOnSelect={false}
                            cacheOptions
                            defaultOptions
                            loadOptions={(inputValue, callback, url) =>
                                loadOptions(inputValue, callback, "users")
                            }
                            {...form.register("superiorImmediate")}
                            onChange={(e, _) => onChangeSelect(e, "superiorImmediate")}
                        />
                   </div>
                </Stack>
                <Stack 
                    direction={ screenWidth > 1025 ? "row" : "column"}
                    useFlexGap
                    spacing={screenWidth > 1025 ? 8 : 4} 
                    width="100%" 
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <div style={{ width: '100%', maxWidth: screenWidth > 1025 ? "28%" : "100%"}}>
                        <Label style={{ marginBottom: 8}} htmlFor="real-state">Imobiliárias</Label>
                        <AsyncSelect
                            classNamePrefix="react-select"
                            className="react-select info"
                            placeholder="Selecionar Imobiliárias"
                            name="real-state"
                            id="real-state"
                            isSearchable
                            isMulti
                            closeMenuOnSelect={false}
                            cacheOptions
                            defaultOptions
                            loadOptions={(inputValue, callback, url) =>
                                loadOptions(inputValue, callback,"partners/find/realstate")
                            }
                            {...form.register("client.partner._id")}
                            onChange={(e) => onChangeSelect(e, "client.partner._id")}
                        />
                    </div>
                   <div style={{ width: '100%', maxWidth: screenWidth > 1025 ? "28%" : "100%"}}>
                        <Label style={{ marginBottom: 8}} htmlFor="manager-pos">Gestores de PDV</Label>
                        <AsyncSelect
                            classNamePrefix="react-select"
                            className="react-select info"
                            placeholder="Selecionar Gestores de PDV"
                            name="manager-pos"
                            id="manager-pos"
                            isSearchable
                            isMulti
                            closeMenuOnSelect={false}
                            cacheOptions
                            defaultOptions
                            loadOptions={(inputValue, callback, url) =>
                                loadOptions(inputValue, callback, "users/find/pos-managers")
                            }
                            {...form.register("enterprise.posManagers")}
                            onChange={(e, _) => onChangeSelect(e, "enterprise.posManagers")}
                        />
                   </div>
                   <div style={{ width: '100%', maxWidth: screenWidth > 1025 ? "28%" : "100%"}}>
                        <Label style={{ marginBottom: 8}} htmlFor="incorporator">Incorporadores</Label>
                        <AsyncSelect
                            classNamePrefix="react-select"
                            className="react-select info"
                            placeholder="Selecionar Incorporadores"
                            name="incorporator"
                            id="incorporator"
                            isSearchable
                            isMulti
                            closeMenuOnSelect={false}
                            cacheOptions
                            defaultOptions
                            loadOptions={(inputValue, callback, url) =>
                                loadOptions(inputValue, callback, "users")
                            }
                            {...form.register("enterprise.incorporators")}
                            onChange={(e, _) => onChangeSelect(e, "enterprise.incorporators")}
                        />
                   </div>
                </Stack>
            </Stack>
        </FilterTable>
    )
}