
import React from "react";

import { Row, Col } from "reactstrap";

import ImageUpload from "components/CustomUpload/ImageUpload.js";

const UploadFile = React.forwardRef((props, ref) => {
  const [file, setFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    isValidated() {
      return true;
    },
    state: {
      logoUrl: file
    }
  }));

  const myUploader = (data) => {
    setLoading(true);
    setFile(data.Location);
    setLoading(false);
  }

  return (
    <>
      <h5 className="info-text">Qual a logo da sua empresa?</h5>
      <Row className="justify-content-center">
        <Col className="text-center" lg="10">
          <ImageUpload
            avatar={false}
            changeBtnClasses="btn-simple"
            addBtnClasses="btn-simple"
            removeBtnClasses="btn-simple"
            uploadHandler={myUploader}
          />
        </Col>
      </Row>
    </>
  );
});

export default UploadFile;
