import React from "react";
import { Col, Input, Row } from "reactstrap"
import InputMask from "react-input-mask";

export const PhoneNumberInputAdmin = (props) => {
    const [phoneNumber, setPhoneNumber] = React.useState("")
  
    const { phoneNumberValue, onChange, index } = props;

    React.useEffect(() => {
        setPhoneNumber(phoneNumberValue)
    }, [phoneNumberValue])

    return (
      <>
        <Row>
            <Col sm="12" style={{  display: "flex", alignItems: "center", gap: 6}}>
                <Input
                    name="phoneNumber"
                    style={{ fontWeight: "bold", maxWidth: 310, width: "100%" }}
                    placeholder="Informe o Telefone..."
                    id="telephone"
                    value={phoneNumber}
                    mask="(99) 99999-9999"
                    tag={InputMask}
                    type="text"
                    onChange={(e) => {
                        setPhoneNumber(e.target.value)
                        onChange(e.target.value, index)
                    }}
                />
            </Col>
        </Row>
      </>
    )
}