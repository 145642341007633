import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Row,
  Button
} from "reactstrap";
import UnitFormComponents from "views/components/UnitFormComponents";


const EditUnit = forwardRef((props, ref) => {
    const type = props.wizardData?.Editar?.type;
    const [units, setUnits] = useState([]);

    useImperativeHandle(ref, () => ({
      isValidated: () => {
        return true;
      },
      state: {
        units
      },
    }));

    useEffect(() => {
      setUnits(props.wizardData?.Editar?.units);
    },[props.wizardData?.Editar])
    
    const handleCreateUnit = () => {
      setUnits(oldUnits => [...oldUnits, { id: Math.floor(Math.random() * (1 - 0 + 1) + 0) }])
    }

    const handleEditUnit = (unitId, data) => {
      setUnits(oldUnits => [...oldUnits.map((unit) => {
        if(unit.id === unitId) {
          unit.nameTower = data.nameTower
          unit.number = data.number
          unit.privateArea = data.privateArea
          unit.idealFraction = data.idealFraction
          unit.qtyRoom = data.qtyRoom
          unit.qtySpaces = data.qtySpaces
          unit.parkingsSpaceNumber = data.parkingsSpaceNumber

        }
        return unit
      })])
    }
    
    return (
      <>
      {
        units?.map((unit, i) => (
          <>
            <h2>Unidade {i + 1}</h2>
            <UnitFormComponents key={unit.id} unit={unit} towers={props.wizardData?.Torres?.towers} type={type} onData={(data) => handleEditUnit(unit.id, data) } operation="edit"/>
          </>

        ))
      }
      <Row className="justify-content-center mt-5">
        <Button className="btn btn-info" onClick={handleCreateUnit}>
          <i className="tim-icons icon-simple-add"></i>
        </Button>
      </Row>
     </>
    );
});

export default EditUnit;