import React, { useEffect } from "react";
import classNames from "classnames";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Label,
  Input,
} from "reactstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import { Link } from "react-router-dom";
import api from "../../../api/index";
import { HasPermission } from "variables/permissions";
import { TableHeader } from "views/components/TableHeader";
import { ModalDeleteItem } from "views/components/ModalDeleteItem";
import { FilterTable } from "views/components/FilterTables";
import { useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { filterByUrlParams } from "variables/filter-by-url-params";
import { Base64 } from "js-base64";

const Enterprises = (props) => {
  const [data, setData] = React.useState([]);
  const [toggleModalRemove, setToggleModalRemove] = React.useState(false);
  const [base64, setBase64] = React.useState("");
  const [removeId, setRemoveId] = React.useState(null);
  const { handleSubmit, register, setValue, getValues } = useForm();

  const removeData = async (id) => {
    const { data, status } =  await api.delete(`/enterprises/${id}`);
    if(status === 200) {
      setToggleModalRemove(false);
      getData();
    }
    setRemoveId(null);
  };

  const getData = async (params = "") => {
    onExport(params)
    const { data } = await api.get(`/enterprises?${params}`);
    setData(data);
  };

  const onSubmit = (e) => {
    const params = filterByUrlParams(e)

    getData(params)
  }

  const onExport = async (params = "") => {
    const { data } = await api.get(`/enterprises?${params}`, {
       headers: {
        'X-Custom-Response': 'csv'
       }
    });

    const csv = Base64.decode(data)

    setBase64(csv)
  }
  
  const onChangeText = (e, field) => {
    setValue(field, `*${e.target.value}`)
    const params = getValues();
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      onSubmit(params)
    }
  }

  const onChangeSelect = (e, field) => {
    setValue(field, `${e.map(({ value }) => value)}`)
    const params = getValues();
    onSubmit(params)
  }


  const loadOptions = (inputValue, callback) => {
    api.get(`partners/find/incorporators`)
      .then((response) => {
        const options = []
        response.data.forEach((incorporator) => {
          options.push({
            label: incorporator.corporateName,
            value: incorporator._id
          })
        });

        let filterOptions = options.filter(o =>
          Object.keys(o).some(k => o[k].toLowerCase().includes(inputValue.toLowerCase())));
          
        callback(filterOptions);
      })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <div className="content">
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <FilterTable onSubmit={handleSubmit((e) => onSubmit(e))}>
                <Row>
                  {
                    HasPermission("enterprise.filter.name") && (
                        <Col sm="6">
                          <Label for="name">Nome</Label>
                          <Input 
                            type="text"
                            name="name"
                            placeholder="Nome"
                            id="name"
                            {...register('name')}
                            onChange={(e, _) => onChangeText(e, 'name')}
                          />
                        </Col>
                    )
                  }
                  {
                    HasPermission('enterprise.filter.incorporators') && (
                        <Col sm="6">
                          <Label for="incorporator">Incorporador</Label>
                          <AsyncSelect
                              className="react-select info"
                              classNamePrefix="react-select"
                              placeholder="Selecione os incorporadores..."
                              name="incorporators"
                              isSearchable
                              closeMenuOnSelect={false}
                              isMulti
                              loadOptions={loadOptions}
                              cacheOptions
                              defaultOptions
                              {...register('incorporators')}
                              onChange={(e, _) => onChangeSelect(e, 'incorporators')}
                          />
                        </Col>
                    )
                  }
                </Row>
            </FilterTable>
            <Card>
             <TableHeader 
                title="Listar Empreendimentos"
                permission="enterprises.create"
                linkToCreate="/admin/enterprise/new"
                hasCSV="enterprises.export"
                csv={base64}
              />
              <CardBody>
                <ReactTable
                  data={data}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Nome do empreendimento",
                      Cell: data => {
                        return <p>{data.row.original.name}</p>
                      },
                    },
                    {
                      Header: "Tipo",
                      Cell: data => {
                        return <p>{data.row.original.type}</p>
                      },
                    },
                    
                    {
                      Header: "Ações",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                      Cell: data => 
                        (
                        <div className="actions-right">
                         {
                          HasPermission("enterprises.edit") && (
                            <Link to={`/admin/enterprise/${data.row.original._id}/edit`}>
                              <Button
                                color="warning"
                                size="sm"
                                className={classNames("btn-icon btn-link edit", {
                                  "btn-neutral":  false
                                })}
                              >
                                <i className="tim-icons icon-pencil" />
                              </Button>
                            </Link>
                            )
                         }
                         {
                          HasPermission("enterprises.delete") && (
                            <Button
                              onClick={() => {
                                setToggleModalRemove(true);
                                setRemoveId(data.row.original._id);
                              }}
                              color="danger"
                              size="sm"
                              className={classNames("btn-icon btn-link remove", {
                                "btn-neutral":  false
                              })}
                            >
                              <i className="tim-icons icon-simple-remove" />
                            </Button>
                          )
                         }
                        </div>
                      )
                    }
                  ]}
                  defaultPageSize={10}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <ModalDeleteItem 
        title="Deseja realmente excluir este empreendedor?"
        isOpen={toggleModalRemove}
        setToggleModal={() => setToggleModalRemove(false)}
        removeData={() => removeData(removeId)}
      />
    </>
  );
}
export default Enterprises;