import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Row,
  Button
} from "reactstrap";
import TowerFormComponents from "views/components/TowerFormComponents";
import { v4 as uuidv4 } from 'uuid';


const INITAL_TOWERS = 0
const CreateTower = forwardRef((props, ref) => {
  const type = props.wizardData?.Cadastrar?.type;
  const [towers, setTowers] = useState([]);

  useImperativeHandle(ref, () => ({
    isValidated: () => {
      return true;
    },
    state: {
      towers
    },
  }));

  useEffect(() => {
    if (towers.length === 0)
      [...Array(INITAL_TOWERS)].forEach(() => handleCreateTower())
  }, [])

  const handleCreateTower = () => {
    setTowers(oldTowers => [...oldTowers, { id: uuidv4(), name: null, qtyAptPerFloor: null, qtyFlooring: null }])
  }

  const handleEditTower = (towerId, data) => {
    setTowers(oldTowers => [...oldTowers.map((tower) => {
      if (tower.id === towerId) {
        tower.name = data.name
        tower.qtyAptPerFloor = data.qtyAptPerFloor
        tower.qtyFlooring = data.qtyFlooring
      }
      return tower
    })])
  }

  return (
    <>
      {
        type === "VERTICAL" ?
          towers?.map((tower, i) => (
            <div key={i}>
              <h2 style={{ textAlign: "center" }}>Torre {i + 1}</h2>
              <TowerFormComponents key={tower.id} onData={(data) => handleEditTower(tower.id, data)} />
            </div>

          )) : null
      }
      {
        type === "VERTICAL" ?
          <Row className="justify-content-center mt-3">
            <Button className="btn btn-info" onClick={handleCreateTower}>
              <i className="tim-icons icon-simple-add"></i>
            </Button>
          </Row>
          :
          <Row className="justify-content-center mt-2 px-5">
            <h2 style={{ fontSize: "24px" }}>Empreendimento do tipo horizontal não há torres, pule a etapa.</h2>
          </Row>
      }
    </>
  );
});

export default CreateTower;