import React, { forwardRef, useEffect } from "react";
import cep from "cep-promise";
// reactstrap components
import {
  Input,
  Row,
  Col,
  FormGroup,
  Label
} from "reactstrap";
import InputMask from "react-input-mask";

const CreatePartner = forwardRef((props, ref) => {
    const [corporateName, setCorporateName] = React.useState("");
    const [fantasyName, setFantasyName] = React.useState("");
    const [documentNumber, setDocumentNumber] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [website, setWebsite] = React.useState("");
    const [zipCode, setZipCode] = React.useState("");
    const [street, setStreet] = React.useState("");
    const [number, setNumber] = React.useState("");
    const [complement, setComplement] = React.useState("");
    const [neighborhood, setNeighborhood] = React.useState("");
    const [state, setState] = React.useState("");
    const [city, setCity] = React.useState("");
    
    React.useImperativeHandle(ref, () => ({
      isValidated: () => {
        return true;
      },
      state: {
        corporateName,
        fantasyName,
        documentNumber,
        phoneNumber,
        website,
        zipCode,
        street,
        number,
        complement,
        neighborhood,
        state,
        city
      },
    }));
    

    async function handleFindAdrress(number) {
      if(number.length > 7) {
        try {
          let { street, neighborhood, city, state, cep: numberCep } = await cep(number);
          setZipCode(numberCep);
          setStreet(street);
          setNeighborhood(neighborhood);
          setCity(city);
          setState(state);
        } catch(error) {
          if (error instanceof Error) {
            console.log(error)
          }
        }
      }
    }
    useEffect(() => {
    }, [])
    return (
      <>
        <Row className="justify-content-center mt-2">
          <Col sm="5">
            <FormGroup>
              <Label for="corporateName">Razão Social</Label>
              <Input
                name="corporateName"
                id="corporateName"
                placeholder="Informe a Razão Social..."
                type="text"
                onChange={(e) => setCorporateName(e.target.value)}
              />
            </FormGroup>

          </Col>
          <Col sm="5">
            <FormGroup>
              <Label for="fantasyName">Nome Fantasia</Label>
              <Input
                name="fantasyName"
                placeholder="Informe o Nome Fantasia..."
                id="fantasyName"
                type="text"
                onChange={(e) => setFantasyName(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="5">
            <FormGroup>
              <Label for="documentNumber">CNPJ</Label>
              <Input
                name="documentNumber"
                placeholder="Informe o CNPJ..."
                id="documentNumber"
                mask="99.999.999/9999-99"
                tag={InputMask}
                type="text"
                onChange={(e) => setDocumentNumber(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="5">
            <FormGroup>
              <Label for="phoneNumber">Telefone</Label>
              <Input
                name="phoneNumber"
                placeholder="Informe o Telefone..."
                id="phoneNumber"
                mask="(99) 99999-9999"
                tag={InputMask}
                type="text"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col sm="4">
            <FormGroup>
              <Label for="website">Website</Label>
              <Input
                name="website"
                placeholder="Informe o Website..."
                id="website"
                type="text"
                onChange={(e) => setWebsite(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup>
              <Label for="zipCode">CEP</Label>
              <Input
                name="zipCode"
                id="zipCode"
                placeholder="Informe o CEP..."
                type="text"
                onChange={(e) => handleFindAdrress(e.target.value)}
              />
            </FormGroup>

          </Col>
          <Col sm="4">
            <FormGroup>
              <Label for="street">Rua</Label>
              <Input
                name="street"
                id="street"
                placeholder="Informe a Rua..."
                type="text"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </FormGroup>
           
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col sm="4">
            <FormGroup>
              <Label for="complement">Complemento</Label>
              <Input
                name="complement"
                id="complement"
                placeholder="Informe o Complemento..."
                type="text"
                onChange={(e) => setComplement(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup>
              <Label for="number">Número</Label>
              <Input
                name="number"
                placeholder="Informe o Número..."
                id="number"
                type="text"
                onChange={(e) => setNumber(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup>
              <Label for="neighborhood">Bairro</Label>
              <Input
                name="neighborhood"
                placeholder="Informe o Bairro..."
                id="neighborhood"
                type="text"
                value={neighborhood}
                onChange={(e) => setNeighborhood(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col sm="4">
            <FormGroup>
              <Label for="city">Cidade</Label>
              <Input
                name="city"
                placeholder="Informe a Cidade..."
                id="city"
                value={city}
                type="text"
                onChange={(e) => setCity(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup>
              <Label for="state">Estado</Label>
              <Input
                name="state"
                placeholder="Informe o Estado"
                id="state"
                value={state}
                type="text"
                onChange={(e) => setState(e.target.value)}
              />
            </FormGroup>
          
          </Col>
        </Row>
      </>
    );
});

export default CreatePartner;
