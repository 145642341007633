import moment from "moment";

export default class ThisMonthEntryPeriod {
    
    makePeriod() {
        const initialDate = moment(new Date()).startOf("month").subtract(3, "h").toISOString()
        const endDate = moment(new Date()).endOf("month").subtract(3, "h").toISOString()

        return { initialDate, endDate }
    }
}