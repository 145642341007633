import WizardCreatePartner from "views/pages/Partners/Create/WizardPartner.js";
import Partners from "views/pages/Partners";
import Users from "views/pages/Users";
import CreateUser from "views/pages/Users/CreateUser.js";
import EditUser from "views/pages/Users/Edit.js";
import Enterprises from "views/pages/Enterprises";
import OrgChart from "views/pages/OrgChart";
import ViewChart from "views/pages/OrgChart/view.js";
import DocumentApproval from "views/pages/Users/DocumentApproval";
import DetailsDocumentApproval from "views/pages/DocumentApproval/DetailsDocumentApproval";
import Stands from "views/pages/Stands";
import CreateStand from "views/pages/Stands/Create";
import EditStand from "views/pages/Stands/Edit";
import Clients from "views/pages/Clients";
import BusinessPanel from "views/pages/BusinessPanel";
import { BusinessClosed } from "views/pages/Clients/BusinessClosed";
import { BusinessDiscarded } from "views/pages/Clients/BusinessDiscarded";
import WizardClient from "views/pages/Clients/Create/WizardClient";
import { Documents } from "views/pages/Clients/Documents";
import EditClient from "views/pages/Clients/Edit/EditClient";
import { CustomerManagementPage } from "views/pages/CustomerManagement";

export const vpCommercialRoutes = [
    {
        collapse: true,
        name: "Usuários",
        icon: "tim-icons icon-single-02",
        state: "pagesCollapse",
        views: [
          {
            path: "/users",
            name: "Listar usuários",
            mini: "U",
            component: Users,
            layout: "/admin"
          },
          {
            path: "/approval-documents",
            name: "Listar aprovação de documentos",
            mini: "AD",
            component: DocumentApproval,
            layout: "/admin"
          }
        ] 
      },
      {
        path: "/approval-document/:approvalId/details",
        name: "Aprovação de Documentos",
        component: DetailsDocumentApproval,
        layout: "/admin",
        invisible: true,
      },
      {
        path: "/user/new",
        name: "Criar Usuários",
        component: CreateUser,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/new",
        name: "Criar Usuários",
        component: CreateUser,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/user/:userId/edit",
        name: "Editar Usuários",
        component: EditUser,
        layout: "/admin",
        invisible: true,
      },
      {
        path: "/partners",
        name: "Parceiros",
        component: Partners,
        icon: "fa fa-briefcase",
        layout: "/admin",
      },
      {
        path: "/partner/new",
        name: "Criar Parceiro",
        component: WizardCreatePartner,
        layout: "/admin",
        invisible: true,
      },
      {
        path: "/enterprises",
        name: "Empreendimentos",
        icon: "fa fa-building",
        component: Enterprises,
        layout: "/admin",
      },
      {
        path: "/stands",
        name: "Stands",
        icon: "tim-icons icon-badge",
        component: Stands,
        layout: "/admin"
      },
      {
        path: "/stand/new",
        name: "Criar Stand",
        component: CreateStand,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/stand/:standId/edit",
        name: "Editar Stand",
        component: EditStand,
        layout: "/admin",
        invisible: true,
      },
      {
        collapse: true,           
        name: "Clientes",
        icon: "tim-icons icon-badge",
        state: "pagesCollapse",
        views: [
          {
            path: "/clients",
            name: "Listagem de Clientes",
            mini: "C",
            component: Clients,
            layout: "/admin"
          },
          {
            path: "/businessPanel",
            name: "Painel de Negócios",
            mini: "PN",
            component: BusinessPanel,
            layout: "/admin"
          },
          {
            path: "/business-closed",
            name: "Negócios Fechados",
            mini: "NF",
            component: BusinessClosed,
            layout: "/admin"
          },
          {
            path: "/business-discarded",
            name: "Negócios Descartados",
            mini: "ND",
            component: BusinessDiscarded,
            layout: "/admin"
          },
        ] 
      },
      {
        path: "/documents/clients/:clientId",
        name: "Documentos do Cliente",
        component: Documents,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/client/new",
        name: "Criar Cliente",
        component: WizardClient,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/client/:clientId/edit",
        name: "Editar Cliente",
        component: EditClient,
        layout: "/admin",
        invisible: true,
      },
      {
        path: "/charts",
        name: "Organagrama",
        icon: "tim-icons icon-vector",
        component: OrgChart,
        layout: "/admin",
      },
      {
        path: "/chart/:id/edit",
        name: "Visualizar Organagrama",
        component: ViewChart,
        layout: "/admin",
        invisible: true,
      },
      {
        path: "/customerManagement/:clientId",
        name: "Gestão de Cliente",
        component: CustomerManagementPage,
        layout: "/admin",
        invisible: true
      },
]