import moment from "moment";

export default class FifthDaysEntryPeriod { 
    
 
    makePeriod() {
        const initialDate = moment(new Date()).subtract(15, "day").startOf("day").subtract(3, "h").toISOString()
        const endDate = moment(new Date()).endOf("day").subtract(3, "h").toISOString()

        return { initialDate, endDate }
    }

    
}