import React from "react";
import ReactWizard from "react-bootstrap-wizard";

import { Col } from "reactstrap";

import CreatePartner from './CreatePartner.js';
import LobSelection from '../LobSelection.js';
import UploadFile from '../UploadFile.js';
import api from "../../../../api/index";
import { useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { useOptionsPartners } from "variables/useOptionsPartners.js";

const WizardPartner = () => {
  const notificationAlertRef = React.useRef(null);
  const history = useHistory();

  const options = useOptionsPartners();

  const steps = [
    {
      stepName: "Tipo",
      stepIcon: "tim-icons icon-settings-gear-63",
      component: LobSelection,
      stepProps: {
        options,
      },
    },
    {
      stepName: "Logomarca",
      stepIcon: "fa fa-images",
      component: UploadFile
    },
    {
      stepName: "Cadastrar",
      stepIcon: "tim-icons icon-single-02",
      component: CreatePartner
    },
  ];

  const finishButtonClick = async (allStates) => {
    const payload = {
      ...allStates.Tipo,
      ...allStates.Avatar,
      ...allStates.Cadastrar,
      ...allStates.Logomarca
    }
    let options = {};

    try {
    const { data, status } = await api.post(`/partners`, payload);
  
    options = {
      place: "tr",
      message: "Parceiro registrado com sucesso!",
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };

  } catch (e) {
    options = {
      place: "tr",
      message: e.response.data.errors[0],
      type: "danger",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
  }  
    notificationAlertRef.current.notificationAlert(options);  
    if (options.type === 'success') {
      setTimeout(() => {
        history.push("/admin/partners");
      }, 2000)
    }
  };
  return (
    <>
      <div className="content">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Col className="mr-auto ml-auto" md="8">
          <ReactWizard
            steps={steps}
            navSteps
            validate
            title="Criar Parceiro"
            description="Estas informações nos permitirá saber mais sobre você."
            headerTextCenter
            finishButtonClasses="btn-wd btn-info"
            nextButtonClasses="btn-wd btn-info"
            previousButtonClasses="btn-wd"
            nextButtonText="Próximo"
            previousButtonText="Anterior"
            finishButtonText="Finalizar"
            progressbar
            color="red"
            finishButtonClick={finishButtonClick}
          />
        </Col>
      </div>
    </>
  );
};

export default WizardPartner;
