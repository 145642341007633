import React from "react";

import { Card, CardBody } from "reactstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import brLocale from "@fullcalendar/core/locales/pt-br";
import { ModalCalendar } from "./components/ModaLCalendar";
import api from "api";
import moment from "moment";
import { useAuth } from "context/AuthContext";

const Calendar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [date, setDate] = React.useState(new Date());
  const [queryParams, setQueryParams] = React.useState("");
  const startDate = moment(date).startOf("day");
  const endDate = moment(date).endOf("day");

  const { user } = useAuth();

  const onEventClick = (e) => {
    const date = new Date(e?.event?._def.extendedProps.scheduleAt).setHours(
      new Date(e?.event?._def.extendedProps.scheduleAt).getHours() + 3
    );
    setDate(date);
    setIsOpen(true);
  };

  const getEvents = (info, successCallback) => {
    const endDate = moment(info.end).subtract(1, 'seconds').toISOString()
    
   if (queryParams) {
    api
    .get(
      `/activity?${queryParams}&scheduledAt=gte:${info.startStr}&scheduledAt=lte:${endDate}`
    )
    .then(({ data }) => {
      const parsedEvents = [];
      for (const event of data) {
        parsedEvents.push({
          _id: event.client._id,
          title: event.client.name,
          scheduleAt: new Date(event.scheduledAt).setHours(
            new Date(event.scheduledAt).getHours()
          ),
          date: new Date(event.scheduledAt).setHours(
            new Date(event.scheduledAt).getHours()
          ),
          type: event.type,
          description: event.description,
          enterprise:
            event.client.enterprise?.name ||
            event.client.stand?.enterprise?.name,
        });
      }
      successCallback(parsedEvents);
    });
   }
  };

  React.useEffect(() => {
    if (user.role.name === "MANAGER_POS") {
      setQueryParams(
        `enterprise.posManagers=${user.id}&typeOfService=nin:SOCIAL_MEDIA,CALL&client.status=nin:Effective Scheduling`
      );
    } else if (user.role.name === "BROKER") {
      setQueryParams(
        `client.owner=${user.id}&typeOfService=nin:SOCIAL_MEDIA,CALL&client.status=nin:Effective Scheduling`
      );
    } else if (user.role.name === "MANAGER") {
      setQueryParams(
        `supperiorImediate=${user.id}&typeOfService=nin:SOCIAL_MEDIA,CALL&client.status=nin:Effective Scheduling`
      );
    } else if (user.role.name === "SALES_DIRECTOR") {
      setQueryParams(
        `ownersBelowChart.pid=${user.id}&typeOfService=nin:SOCIAL_MEDIA,CALL&client.status=nin:Effective Scheduling`
      );
    } else if (user.role.name === "COMMERCIAL_DIRECTOR_INCORPORATOR") {
      setQueryParams(
        `enterprise.incorporators=${user.partner._id}&typeOfService=nin:SOCIAL_MEDIA,CALL&client.status=nin:Effective Scheduling`
      );
    } else if (user.role.name === "COORDINATOR_PRODUCTS") {
      setQueryParams(
        `enterprise.coordinatorsProducts=${user.id}&enterprise.realStates=${user.partner._id}&typeOfService=nin:SOCIAL_MEDIA,CALL&client.status=nin:Effective Scheduling`
      );
    } else if (user.role.name === "GENERAL_DIRECTOR") {
      setQueryParams(
        `partner._id=${user.partner._id}&typeOfService=nin:SOCIAL_MEDIA,CALL&client.status=nin:Effective Scheduling`
      );
    } else {
      setQueryParams("typeOfService=nin:SOCIAL_MEDIA,CALL&client.status=nin:Effective Scheduling");
    }
  }, [user.id, user.role, user.partner._id]);

  return (
    <>
      <div className="content">
            <Card className="card-calendar">
              <CardBody style={{ padding: "20px"}}>
                <FullCalendar
                  aspectRatio={2.3}
                  scrollTimeReset={false}
                  timeZone="UTC"
                  headerToolbar={{
                    left: "dayGridDay,dayGridWeek,dayGridMonth",
                    center: "title",
                    right: "prev,next",
                  }}
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  initialDate={new Date()}
                  events={(info, successCallback) =>
                    getEvents(info, successCallback)
                  }
                  navLinks={true}
                  dayMaxEvents={true}
                  showNonCurrentDates={false}
                  eventClick={(e) => onEventClick(e)}
                  locale={brLocale}
                  fixedWeekCount={false}
                  stickyFooterScrollbar={true}
                />
              </CardBody>
            </Card>
        <ModalCalendar
          isOpen={isOpen}
          setIsOpen={() => setIsOpen(false)}
          startDate={startDate}
          endDate={endDate}
          queryParams={queryParams}
        />
      </div>
    </>
  );
};

export default Calendar;
