import api from 'api';
import * as React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap"
import { useQuery } from 'variables/query';
import NotificationAlert from "react-notification-alert";

const SetPassword = () => {
    const [ password, setPassword ] = React.useState("")
    const [ confirmPassword, setConfirmPassword ] = React.useState("")
    const [btnSubmitDisabled, setBtnSubmitDisabled] = React.useState(false);
    const notificationAlertRef = React.useRef(null);
    const history = useHistory();
    const query = useQuery();
    let options = {};

    const handleEqualsPassword = () => {
      if(password !== confirmPassword) {
        options = {
          place: "tr",
          message: "Senhas não conferem!",
          type: "danger",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7
        };
        setBtnSubmitDisabled(true);
        notificationAlertRef.current.notificationAlert(options);
        return;
      }
      setBtnSubmitDisabled(false);
    }

    const resetPassword = async (e) => {
      const { data, status  } = await api.patch('/auth/set-password', {
        password,
        confirmPassword,
        typeToken: query.get('typeToken')
      }, {
        params: {
          token: query.get("token")
        }
      });


      if(status !== 201) {
        options = {
          place: "tr",
          message: data,
          type: "danger",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7
        };
      }

      options = {
        place: "tr",
        message: "Senha alterada com sucesso!",
        type: "success",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7
      };

      notificationAlertRef.current.notificationAlert(options);
      setTimeout(() => {
        history.push("/auth/login");
      }, 2000)
    }

    return (
        <div className="content">
            <div className="rna-container">
              <NotificationAlert ref={notificationAlertRef} />
            </div>
              <Container>
              <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form className="form">
                <Card className="card-login card-white" style={{ padding: 10 }}>
                    <CardHeader className="position-relative">
                        <img alt="..." src={require("assets/img/performance_logo.png")}/>
                        <CardTitle tag="h1"> </CardTitle>
                    </CardHeader>
                    <CardBody>
                    <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nova senha"
                      type="password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Confirmar senha"
                      type="password"
                      required
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      onBlur={(e) => handleEqualsPassword()}
                    />
                  </InputGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className="mb-3"
                    color="danger"
                    size="lg"
                    onClick={resetPassword}
                    disabled={btnSubmitDisabled}
                  >
                    Confirmar
                  </Button>
                  </CardFooter>
                    
                </Card>
              </Form>
              </Col>
              </Container>
        </div>
    )
}

export default SetPassword