import { Button, Input, Label, Modal, ModalBody } from "reactstrap"
import AsyncSelect from "react-select/async";
import api from "api";
import { useForm } from "react-hook-form";
import React from "react";

export const ModalClientsSend = (props) => {

    const { 
        isOpen,
        onClose,
        owner,
        onChangeOwner,
        listOwners,
    } = props;

    const { handleSubmit, register, setValue, getValues  } = useForm()

    const onChangeClientsSend = async () => {
        const fields = getValues()
        await api.put(`/clients/transfer/owner`, {
            clients: fields.clients.split(","),
            owner
        })
        onClose()
    }

    const loadOptions = (inputValue, callback) => {
        api.get(`/clients/discarded`)
        .then((response) => {
          const options = []
          response.data.forEach((option) => {
            options.push({
              label: option.corporateName || option.name,
              value: option._id
            })
          })
    
    
          let filterOptions = options.filter(o =>
            Object.keys(o).some(k => o[k].toLowerCase().includes(inputValue.toLowerCase())));
            
          callback(filterOptions);
        })
      }

    const onChangeSelect = (e, field) => {
        setValue(field, `${e.map(({ value }) => value)}`)
    }

    return (
        <Modal isOpen={isOpen} modalClassName="modal-black">
        <div className="modal-header">
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
            <i className="tim-icons icon-simple-remove" onClick={onClose}></i>
        </button>
        <h5 className="modal-title">Selecione os clientes que serão encaminhados</h5>
        </div>
        <ModalBody>
            <form onSubmit={handleSubmit((e) => onChangeClientsSend(e))}>
                <Label>Clientes</Label>
                <AsyncSelect 
                    classNamePrefix="react-select"
                    className="react-select info"
                    placeholder="Selecionar Clientes" 
                    name="clients"
                    isSearchable
                    isMulti
                    closeMenuOnSelect={false}
                    cacheOptions
                    defaultOptions
                    loadOptions={(inputValue, callback, url) => loadOptions(inputValue, callback)}
                    {...register('clients')}
                    onChange={(e, _) => onChangeSelect(e, 'clients')}
                />
                <Label>Dono</Label>
                <Input 
                    name="owner"
                    id="dono"
                    type="select"
                    value={owner}
                    onChange={onChangeOwner}
                >
                    <option>
                        Selecione um novo Dono...
                    </option>
                    {
                        listOwners.map(({name, _id}, index) => (
                            <option key={index} value={_id}>{name}</option>
                        ))
                    }
                </Input>
                <Button color="primary" style={{ marginTop: 20}} type="submit">Ok</Button>
            </form>
        </ModalBody>
    </Modal>
    )
}