import React, { useEffect } from "react";
import {  Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import  Select from "react-select";
import { v4 as uuidv4 } from 'uuid';

const UnitFromComponents = (props) => {
    const [nameTower, setNameTower] = React.useState(null);
    const [number, setNumber] = React.useState(null);
    const [privateArea, setPrivateArea] = React.useState(null);
    const [idealFraction, setIdealFraction] = React.useState(null);
    const [qtyRoom, setQtyRoom] = React.useState(null);
    const [qtySpaces, setQtySpaces] = React.useState(null);
    const [towersOptions, setTowersOptions] = React.useState([]);
    const [parkingsSpaceNumber, setParkingsSpaceNumber] = React.useState([]);

    const handleCreateParkingsSpaceNumber = () => {
        setParkingsSpaceNumber(oldParkingsSpaceNumber => [...oldParkingsSpaceNumber, { id: uuidv4() , number: null }])
    }

    const handleEditParkingsSpaceNumber = (parkingSpaceId, data) => {
        setParkingsSpaceNumber(oldParkingsSpaceNumber => [...oldParkingsSpaceNumber.map((oldParkingSpaceNumber) => {
            if(oldParkingSpaceNumber.id === parkingSpaceId) {
                oldParkingSpaceNumber.id = parkingSpaceId
                oldParkingSpaceNumber.number = data.number
            }
            return oldParkingSpaceNumber
          })])
      }
      

    useEffect(() => {
        props?.towers?.forEach((tower) => {
            setTowersOptions(oldTowersOptions => [...oldTowersOptions, {
                    label: tower.name,
                    value: tower.name
                }
            ]);
        })
    },[])


    useEffect(() => {
        const unit = props.unit
        if(!unit) return
        setNameTower(unit.nameTower)
        setNumber(unit.number)
        setPrivateArea(unit.privateArea)
        setIdealFraction(unit.idealFraction)
        setQtyRoom(unit.qtyRoom)
        setQtySpaces(unit.qtySpaces)
        setParkingsSpaceNumber(unit.parkingsSpaceNumber)
    },[props.unit])
      
    useEffect(() => {
        props.onData({
            nameTower,
            number,
            privateArea,
            idealFraction,
            qtyRoom,
            qtySpaces,
            parkingsSpaceNumber
        });
    }, [nameTower,number, privateArea, idealFraction, qtyRoom, qtySpaces, parkingsSpaceNumber])

    return (
        <>
        <Row className="justify-content-center mt-1">
            { props.type === "VERTICAL" ?
                <Col sm="5">
                    <FormGroup>
                        <Label for="tower">Nome da Torre</Label>
                        { props.operation === "edit" ? (
                            <Select
                                className="react-select info"
                                classNamePrefix="react-select"
                                placeholder="Selecione a torre referente a unidade..."
                                name="tower"
                                isSearchable
                                required
                                options={towersOptions}
                                value={towersOptions.filter(({value}) => value === nameTower)}
                                onChange={(towerOption) => setNameTower(towerOption.value)}
                            />
                        ) : (
                            <Select
                                className="react-select info"
                                classNamePrefix="react-select"
                                placeholder="Selecione a torre referente a unidade..."
                                name="tower"
                                isSearchable
                                required
                                options={towersOptions}
                                onChange={(towerOption) => setNameTower(towerOption.value)}
                            />
                        )}
                        
                    </FormGroup>
                </Col>
                : null
            }
            <Col sm="5">
                <FormGroup>
                <Label for="number">Número</Label>
                <Input
                    name="number"
                    placeholder="Informe o número..."
                    id="number"
                    required
                    type="text"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                />
                </FormGroup>
            </Col>
            <Col sm="5">
                <FormGroup>
                <Label for="privateArea">Área privativa</Label>
                <Input
                    name="privateArea"
                    placeholder="Informe a área privativa..."
                    id="privateArea"
                    type="number"
                    required
                    value={privateArea}
                    onChange={(e) => setPrivateArea(e.target.value)}
                />
                </FormGroup>
            </Col>
            <Col sm="5">
                <FormGroup>
                <Label for="idealFraction">Fração ideal</Label>
                <Input
                    name="idealFraction"
                    placeholder="Informe a fração ideal..."
                    id="idealFraction"
                    type="number"
                    required
                    value={idealFraction}
                    onChange={(e) => setIdealFraction(e.target.value)}
                />
                </FormGroup>
            </Col>
            <Col sm="5">
                <FormGroup>
                <Label for="qtyRoom">Qtd. Quartos</Label>
                <Input
                    name="qtyRoom"
                    placeholder="Informe a fração ideal..."
                    id="qtyRoom"
                    type="number"
                    required
                    value={qtyRoom}
                    onChange={(e) => setQtyRoom(e.target.value)}
                />
                </FormGroup>
            </Col>
            <Col sm="5">
                <FormGroup>
                <Label for="qtySpaces">Qtd. vagas de garagem</Label>
                <Input
                    name="qtySpaces"
                    placeholder="Informe a quantidade vagas de garagem..."
                    id="qtySpaces"
                    type="number"
                    required
                    value={qtySpaces}
                    onChange={(e) => setQtySpaces(e.target.value)}
                />
                </FormGroup>
            </Col>
            { qtySpaces > 0 && props.operation !== "edit" ? 
                <>
                    <Col sm="6">
                        <Row className="justify-content-center mt-3">
                            <Button className="btn btn-warning" onClick={handleCreateParkingsSpaceNumber}>
                                <i className="fa-solid fa-car-rear"></i>                            
                            </Button>
                        </Row>
                        { parkingsSpaceNumber?.map((spacesParkings, i) => (
                            <>
                                <FormGroup>
                                    <Label for="parkingSpaceNumber">Número vaga de garagem</Label>
                                    <Input
                                        key={spacesParkings.id}
                                        name="parkingSpaceNumber"
                                        placeholder="Informe o número da vaga de garagem..."
                                        id="parkingSpaceNumber"
                                        type="number"
                                        onChange={(e) => handleEditParkingsSpaceNumber(spacesParkings.id, {number: Number(e.target.value)})}
                                    />
                                </FormGroup>

                            </>
                        ))}
                    </Col>
                </>
            
            : null}

            { props.operation == "edit" ? 
                <>
                    <Col sm="6">
                        <Row className="justify-content-center mt-3">
                            <Button className="btn btn-warning" onClick={handleCreateParkingsSpaceNumber}>
                                <i className="fa-solid fa-car-rear"></i>                            
                            </Button>
                        </Row>
                        { parkingsSpaceNumber?.map((spacesParkings, i) => (
                            <>
                                <FormGroup>
                                    <Label for="parkingSpaceNumber">Número vaga de garagem</Label>
                                    <Input
                                        key={spacesParkings.id}
                                        name="parkingSpaceNumber"
                                        placeholder="Informe o número da vaga de garagem..."
                                        id="parkingSpaceNumber"
                                        type="number"
                                        value={spacesParkings.number}
                                        onChange={(e) => handleEditParkingsSpaceNumber(spacesParkings.id, {number: Number(e.target.value)})}
                                    />
                                </FormGroup>

                            </>
                        ))}
                    </Col>

                </>
            
            : null}
            
        </Row>
      </>
    );
  };
  
  export default UnitFromComponents;
  