import * as React from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap"

export const Recovery = () => {
    const [ password, setPassword ] = React.useState("")
    const [ confirmPassword, setConfirmPassword ] = React.useState("")
    
    const resetPassword = () => {}

    return (
        <div className="content">
              <Container>
              <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form className="form">
                <Card className="card-login card-white" style={{ padding: 10 }}>
                    <CardHeader className="position-relative">
                        <img alt="..." src={require("assets/img/performance_logo.png")}/>
                        <CardTitle tag="h1"> </CardTitle>
                    </CardHeader>
                    <CardBody>
                    <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nova senha"
                      type="password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Confirmar senha"
                      type="password"
                      required
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </InputGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className="mb-3"
                    color="danger"
                    size="lg"
                    onClick={resetPassword}
                  >
                    Confirmar
                  </Button>
                  </CardFooter>
                    
                </Card>
              </Form>
              </Col>
              </Container>
        </div>
    )
}