
import PropertyDeveloper from "../assets/img/icons/partner-agreement.svg";
import RealEstate from "../assets/img/icons/imobiliaria-rent.svg";
import RealEstateHouse from "../assets/img/icons/house.svg";
import { useAuth } from "context/AuthContext";

export const useOptionsPartners = () => {
    const { user } = useAuth();

    const prefixPermission = "performance.partners.create.type."

    const options = [
      {
        key: "incorporator",
        title: "Incorporador",
        img: <img src={PropertyDeveloper}  className="img-select" alt="incorporador" />
      }, 
      {
        key: "realState",
        title: "Imobiliária",
        img: <img src={RealEstate}  className="img-select" alt="Imobiliaria" />,
      },
      {
        key: "house",
        title: "House",
        img: <img src={RealEstateHouse}  className="img-select" alt="House" />
      }
    ];
  
    const filterOptions = options.filter(option => {
        const typePartner = option.key;
        if (user.role.permissions.includes(`${prefixPermission + typePartner}`)) {
            return option;
        }
    })

    return user.role.name === "ADMIN" ? options : filterOptions;
}
  