import React, { useEffect } from "react";
import classNames from "classnames";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";

import ReactTable from "components/ReactTable/ReactTable.js";
import { Link } from "react-router-dom";
import api from "../../../api/index";
import moment from "moment";
import NotificationAlert from "react-notification-alert";
import { AxiosError } from "axios";
import { useHistory } from "react-router-dom";

const DocumentApproval = () => {
  const [data, setData] = React.useState([]);
  const notificationAlertRef = React.useRef(null);
  const history = useHistory();

  const getData = async () => {
    const { data } = await api.get(`/document-approval`);
    setData(data);
  };

  const approveDocument = async (item) => {
    let options = {};
    try {
      await generateContrator(item);
      const { data } = await api.put(`/document-approval/${item._id}`);
      options = {
        place: "tr",
        message: "Contrato gerado e documento aprovado com sucesso!",
        type: "success",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        options = {
          place: "tr",
          message: error.response.data?.message,
          type: "danger",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7,
        };
      }
    }
    notificationAlertRef.current.notificationAlert(options);
    setTimeout(() => {
      history.push("/admin/approval-documents");
    }, 3000);
  };

  const generateContrator = async (item) => {
    const { user } = item;
    const { partner } = user;
    try {
      const {
        data: { ...generalDirector },
      } = await api.get(`/users/${partner._id}/general-director`);
      const body = {
        hired: {
          email: user.email,
          name: `${user.name} ${user.lastName}`,
          documentNumber: user.documentNumber,
          personalDocument: user.documentNumber,
          address: `${user.street}, ${user.number}, ${user.neighborhood}, ${user.city}`,
          city: user.city,
          phoneNumber: user.phoneNumber,
          zipCode: user.zipCode,
          birthDate: moment(user.dateBirth)
            .locale("pt-br")
            .format("YYYY-MM-DD"),
        },
        contractor: {
          email: generalDirector.email,
          name: `${generalDirector.name} ${generalDirector.lastName}`,
          documentNumber: partner.documentNumber,
          personalDocument: generalDirector.documentNumber,
          city: partner.city,
          crc: partner.documentNumber,
          address: `${partner.street}, ${partner.number}, ${partner.complement}, ${partner.neighborhood}, ${partner.city}`,
          phoneNumber: generalDirector.phoneNumber,
          zipCode: partner.zipCode,
          birthDate: moment(generalDirector.dateBirth)
            .locale("pt-br")
            .format("YYYY-MM-DD"),
        },
      };
      await api.patch(`/document/${user._id}`, body);
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="content">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle>
                  <div>
                    <h4 className="d-inline-flex p-2">
                      Listar usuários pendente aprovação de documentos
                    </h4>
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={data}
                  filterable
                  columns={[
                    {
                      Header: "Usuário",
                      accessor: "user.name",
                    },

                    {
                      Header: "Empresa",
                      accessor: "user.partner.corporateName",
                    },
                    {
                      Header: "Ações",
                      sortable: false,
                      filterable: false,
                      Cell: (data) => (
                        <div className="actions-right">
                          <Link
                            to={`/admin/approval-document/${data.row.original._id}/details`}
                          >
                            <Button
                              color="info"
                              size="sm"
                              className={classNames("btn-icon btn-link", {
                                "btn-neutral": false,
                              })}
                            >
                              <i className="tim-icons icon-paper" />
                            </Button>
                          </Link>
                          <Button
                            color="success"
                            size="sm"
                            className={classNames("btn-icon btn-link edit", {
                              "btn-neutral": false,
                            })}
                            onClick={() => approveDocument(data.row.original)}
                          >
                            <i className="tim-icons icon-check-2" />
                          </Button>
                        </div>
                      ),
                    },
                  ]}
                  defaultPageSize={10}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default DocumentApproval;
