import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { useAuth } from "context/AuthContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useNotification } from "context/NotificationContext";

const Login = () => {
  const notificationAlertRef = React.useRef(null);
  const { signIn } = useAuth();
  const [state, setState] = React.useState({});
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState(""); 

  const history = useHistory();
  const { setReports } = useNotification();
  
  React.useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };

  }, []);

  const onGoToRecoveryPage = () => history.push("confirm-mail")

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signIn({
        email,
        password
      });
      history.push("/admin/dashboard")
      setReports([])
    } catch(error) {
      notificationAlertRef.current.notificationAlert({
        place: "tr",
        message: error?.response?.data?.message,
        type: "danger",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7
      });  
    }
  };

  return (
    <>
      <div className="content">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form" onSubmit={handleSubmit}>
              <Card className="card-login card-white">
                <CardHeader className="position-relative">
                  <img alt="..." src={require("assets/img/performance_logo.png")} style={{ transform: "translate(-50%, -50%)", width: "60%", top: "50%", left: "50%"}}/>
                  <CardTitle tag="h1"> </CardTitle>
                </CardHeader>
                <CardBody>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": state.emailFocus
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      onFocus={(e) => setState({ ...state, emailFocus: true })}
                      onBlur={(e) => setState({ ...state, emailFocus: false })}
                    />
                  </InputGroup>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": state.passFocus,
                      "mb-2":true
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      required
                      onChange={(e) => setPassword(e.target.value)}
                      onFocus={(e) => setState({ ...state, passFocus: true })}
                      onBlur={(e) => setState({ ...state, passFocus: false })}
                    />
                  </InputGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    type="submit"
                    className="mb-3"
                    color="danger"
                    size="lg"
                  >
                    Logar
                  </Button>
                  <div className="pull-left">
                    <h6>
                      <p
                        className="link footer-link"
                        style={{ color: '#EE2C1D', fontWeight: 'bold', padding: 5, cursor: 'pointer'}}
                        onClick={onGoToRecoveryPage}
                      >
                        Esqueci a senha
                      </p>
                    </h6>
                  </div>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    </>
  );
};

export default Login;
