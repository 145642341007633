import { Button, Modal, ModalBody, ModalFooter } from "reactstrap"
import ReactHtmlParser from 'react-html-parser';
import ReactTable from "components/ReactTable/ReactTable.js";
import React from "react";
import api from "api";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import classNames from "classnames";
import moment from "moment";

export const ModalCalendar = (props) => {
    const [ activities, setActivities ]= React.useState([])
    
    const { isOpen, setIsOpen, startDate, endDate, queryParams } = props

    const getActivities = () =>{
        api.get(`/activity?${queryParams}&scheduledAt=gte:${moment(startDate).subtract(3, 'hours').toISOString()}&scheduledAt=lte:${moment(endDate).subtract(3, 'hours').toISOString()}`).then(({ data })=> {
          const parsedEvents = [];
          for (const event of data) {
            parsedEvents.push({
              _id: event.client._id,
              title: event.client.name,
              scheduleAt: new Date(event.scheduledAt).setHours(new Date(event.scheduledAt).getHours() + 3),
              date: new Date(event.scheduledAt).setHours(new Date(event.scheduledAt).getHours() + 3),
              type: event.type,
              description: event.description,
              enterprise: event.client.enterprise?.name || event.client.stand?.enterprise?.name
            })
          }
          setActivities(parsedEvents)
        });
    }

    React.useEffect(() => {
        if (isOpen) {
            getActivities()
        }
    }, [isOpen])

    const translateService = (type) => {
        switch (type) {
            case "Call":
                return "Ligar";
            case "E-mail":
                return "E-mail"
          case "Scheduled":
                return "Agendado"
            default: 
                return ""
        }
    }

    return (
        <Modal isOpen={isOpen} modalClassName="modal-black" size="xl">
        <ModalBody>
            <ReactTable
                data={activities}
                filterable
                columns={[
                {
                    Header: "Nome",
                    accessor: "title",
                    Cell: data => { 
                        return (
                            <p style={{ fontSize: 13}}>{data.row.original.title}</p>
                        )
                        }
                },
                {
                    Header: "Empreendimento",
                    accessor: "enterprise",
                    Cell: data => {
                    return (
                        <p style={{ fontSize: 13}}>{data.row.original.enterprise}</p>
                    )
                    }
                },
                {
                    Header: "Tipo",
                    accessor: "type",
                    Cell: data => {
                        return (
                            <p style={{ fontSize: 13}}>{translateService(data.row.original.type)}</p>
                        )
                    }
                },
                {
                    Header: "Descrição",
                    accessor: "description",
                    Cell: data => {
                        return (
                            <p style={{ fontSize: 13}}>{ReactHtmlParser(data.row.original.description)}</p>
                        )
                    }
                },
                {
                    Header: "Data e Hora Marcada",
                    accessor: "schedule_at",
                    Cell: data => {
                        const formatedDate = new Date(data.row.original.scheduleAt)
                        const scheduledAt = formatedDate.toLocaleDateString('pt-BR', {
                            hour: 'numeric',
                            minute: 'numeric'
                        })
                        return (
                            <p style={{ fontSize: 13}}>{scheduledAt}</p>
                        )
                    }
                },
                {
                    Header: "Ações",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    Cell: data => {
                        return  (
                            <div className="actions-right">
                             {
                                <Link to={`/admin/customerManagement/${data.row.original._id}`}>
                                  <Button
                                    color="warning"
                                    size="sm"
                                    className={classNames("btn-icon btn-link view", {
                                      "btn-neutral":  false
                                    })}
                                  >
                                    <i className="tim-icons icon-paper" />
                                  </Button>
                                </Link>
                             }
                            </div>
                          )
                    }
                     
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
            />
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={setIsOpen}>Ok</Button>
        </ModalFooter>
    </Modal>
    )
}