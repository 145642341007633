import api from "../../../../api/index";
import React from "react";
import 'moment/locale/pt-br';
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import InputMask from "react-input-mask";
import AsyncSelect from "react-select/async";
import CurrencyInput from "react-currency-input";import { useAuth } from "context/AuthContext";
;

export const mediasOrigin = [
  { label: "Ação", value: "Action" },
  { label: "TeleMarketing", value: "TeleMarketing" },
  { label: "Redes Sociais", value: "SocialMedias" },
  { label: "Sites", value: "Sites" },
  { label: "E-mail Marketing", value: "MarketingMail" },
  { label: "Tv", value: "TV" },
  { label: "Rádio", value: "Radio" },
  { label: "Outdoor", value: "Outdoor" },
  { label: "Mídias Digitais", value: "DigitalMedias" },
  { label: "Outros", value: "Others" },
]

const CreateClient = React.forwardRef((props, ref) => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [phoneNumbers, setPhoneNumbers] = React.useState([]);
  const [note, setNote] = React.useState("");
  const [averageTicket, setAverageTicket] = React.useState("");
  const [ticket, setTicket] = React.useState("");
  const [enterprises, setEnterprises] = React.useState([]);
  const [enterprise, setEnterprise] = React.useState(null);
  const [stand, setStand] = React.useState(null);
  const [stands, setStands] = React.useState([]);
  const [processingType, setProcessingType] = React.useState({});
  const [mediaOrigin, setMediaOrigin] = React.useState("");
  const [owner, setOwner] = React.useState("");
  const [type, setType] = React.useState("")

  const { wizardData } = props;
  const notificationAlertRef = React.useRef(null);

  const { user } = useAuth();

  const isBroker = user.role.name === 'BROKER';

  const getProcessingType = async () => {
    if (wizardData?.Tipo?.typeClient === "stand") {
      const { data } = await api.get(`/stands`);
      setStands(data)
    } else {
      const { data } = await api.get(`/enterprises`);
      setEnterprises(data)
    }
  };

  const loadOwners = (inputValue, callback) => {
    api.get('/users/possible-owners')
      .then((response) => {
        const options = []
        response.data.forEach((option) => {
          options.push({
            label: option.corporateName || option.name,
            value: option._id
          })
        })


        let filterOptions = options.filter(o =>
          Object.keys(o).some(k => o[k].toLowerCase().includes(inputValue.toLowerCase())));

        callback(filterOptions);
      })
  }


  React.useEffect(() => {
    if (wizardData) {
      getProcessingType();
    }
  }, [wizardData])

  React.useEffect(() => {
    setPhoneNumbers([{ type, value: phoneNumber }])
    if (isBroker) {
      setOwner(user.id)
    }
  }, [type, isBroker, user.id, phoneNumber])

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return true;
    },
    state: {
      name,
      email,
      phoneNumber,
      phoneNumbers,
      ...processingType,
      mediaOrigin,
      averageTicket,
      note,
      owner
    },
  }));

  React.useEffect(() => {
    if (ticket.length > 2) {
      setAverageTicket(ticket.substring(0, ticket.length - 2).replace(",", "").replace("R$", "").replace('.', ''))
    }
  }, [ticket])

  return (
    <>
      <div className="content" style={{ width: "100%" }}>
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row>
          <Col sm="4">
            <FormGroup>
              <Label for="name">Nome</Label>
              <Input
                name="name"
                placeholder="Informe seu nome..."
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup>
              <Label for="email">E-mail</Label>
              <Input
                name="email"
                placeholder="Informe seu e-mail..."
                id="email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup>
              <Label for="telephone">Telefone</Label>
              <Input
                name="phoneNumber"
                placeholder="Informe o Telefone..."
                id="telephone"
                value={phoneNumber}
                mask="(99) 99999-9999"
                tag={InputMask}
                type="text"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-evenly", marginTop: 4 }}>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <span style={{ fontSize: 12, marginTop: 3 }}>Pessoal</span>
                  <Input
                    name="type"
                    id="Personal"
                    value={type}
                    type="radio"
                    onChange={() => setType("Personal")}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <span style={{ fontSize: 12, marginTop: 3 }}>Trabalho</span>
                  <Input
                    name="type"
                    id="Work"
                    value={type}
                    type="radio"
                    onChange={() => setType("Work")}
                  />
                </div>
              </div>

            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="6" md={4}>
            <FormGroup>
              {
                wizardData?.Tipo?.typeClient === "stand" ? (
                  <>
                    <Label for="stand">Stand</Label>
                    <Input
                      name="stand"
                      id="stand"
                      type="select"
                      value={stand}
                      onChange={(e) => {
                        setStand(e.target.value)
                        setProcessingType({ stand: e.target.value })
                      }}
                    >
                      <option>
                        Stand
                      </option>
                      {
                        stands.map(({ name, _id }, index) => (
                          <option key={index} value={_id}>
                            {name}
                          </option>
                        ))
                      }
                    </Input>
                  </>
                ) : (
                  <>
                    <Label for="enterprise">Empreendimento</Label>
                    <Input
                      name="enterprise"
                      id="enterprise"
                      type="select"
                      value={enterprise}
                      onChange={(e) => {
                        setEnterprise(e.target.value)
                        setProcessingType({ enterprise: e.target.value })
                      }}
                    >
                      <option>
                        Empreendimento
                      </option>
                      {
                        enterprises.map(({ name, _id }, index) => (
                          <option key={index} value={_id}>
                            {name}
                          </option>
                        ))
                      }
                    </Input>
                  </>
                )
              }
            </FormGroup>
          </Col>
          <Col sm="6" md={4}>
            <FormGroup>
              <Label for="mediaOrigin">Mídia Origem</Label>
              <Input
                name="mediaOrigin"
                id="mediaOrigin"
                placeholder="Mídia Origem"
                type="select"
                value={mediaOrigin}
                onChange={(e) => setMediaOrigin(e.target.value)}
              >
                <option>
                  Mídia origem
                </option>
                {
                  mediasOrigin.map(({ label, value }, index) => (
                    <option key={index} value={value}>{label}</option>
                  ))
                }
              </Input>
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup>
              <Label for="averageTicket">Ticket Médio</Label>
              <CurrencyInput
                name="averageTicket"
                placeholder="Ticket Médio"
                className="form-control"
                id="averageTicket"
                inputType="text"
                prefix="R$"
                decimalSeparator=","
                thousandSeparator="."
                value={ticket}
                onChangeEvent={(e) => setTicket(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="4">
            <FormGroup>
              <Label for="note">Observação</Label>
              <textarea
                name="note"
                className="form-control"
                placeholder="Observação"
                id="note"
                type="text"
                rows={3}
                cols={30}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </FormGroup>
          </Col>
          {
            !isBroker && (
              <Col sm="4">
                <FormGroup>
                  <Label for="owner">Dono</Label>
                  <AsyncSelect
                    classNamePrefix="react-select"
                    className="react-select info"
                    placeholder="Selecionar Dono"
                    name="owners"
                    isSearchable
                    closeMenuOnSelect={false}
                    cacheOptions
                    defaultOptions
                    loadOptions={loadOwners}
                    onChange={(e) => setOwner(e.value)}
                  />
                </FormGroup>
              </Col>
            )
          }
        </Row>
      </div>
    </>
  )
})

export default CreateClient;