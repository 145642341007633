import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import api from "api";
import { CircularProgressbar } from "react-circular-progressbar";

const ImageUpload = ({
  avatar,
  file,
  addBtnColor,
  addBtnClasses,
  changeBtnColor,
  changeBtnClasses,
  removeBtnColor,
  removeBtnClasses,
  uploadHandler
}) => {
  const [, setFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const fileImagem = avatar ? defaultAvatar : defaultImage
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    file !== undefined || file !== "" ? file : fileImagem 
  );
  const fileInput = React.useRef(null);

  React.useEffect(() => {
    if (!file) {
      setImagePreviewUrl(fileImagem)
    }
  }, [])

  const handleImageChange = async (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
    await handleSubmit(file);
  };

  // eslint-disable-next-line
  const handleSubmit = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const { data } = await api.post("/upload", formData,{
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        let progressPorcent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        setProgress(progressPorcent)
        setLoading(true);

      }
    });
    uploadHandler(data)
    setLoading(false);
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      {loading ? 
        <CircularProgressbar styles={{
            root: {width: 50},
            path: { stroke: "#fd5d93"}
          }}
          strokeWidth={10}
          value={progress}
        /> :
        <>
          <div 
            className={"thumbnail" + (avatar ? " img-circle" : "")}
          >
            <img 
              src={file || imagePreviewUrl} 
              alt="..."
              style={{
                minHeight: 120,
                maxHeight: 130,
              }}
            />
          </div>
          <div>
            {file === null ? (
              <Button
                color={addBtnColor}
                className={addBtnClasses}
                onClick={() => handleClick()}
              >
                {avatar ? "Adicionar Foto" : "Selecione Imagem"}
              </Button>
            ) : (
              <span>
                <Button
                  color={changeBtnColor}
                  className={changeBtnClasses}
                  onClick={() => handleClick()}
                >
                  {file ? "Modificar" : "Adicionar"}
                </Button>
              </span>
            )}
          </div>
        </>
      }


    </div>
  );
};

ImageUpload.defaultProps = {
  avatar: false,
  removeBtnClasses: "btn-round",
  removeBtnColor: "danger",
  addBtnClasses: "btn-round",
  addBtnColor: "primary",
  changeBtnClasses: "btn-round",
  changeBtnColor: "primary"
};

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  removeBtnClasses: PropTypes.string,
  removeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ]),
  addBtnClasses: PropTypes.string,
  addBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ]),
  changeBtnClasses: PropTypes.string,
  changeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ])
};

export default ImageUpload;
