import api from "api";
import React from "react";

export const useBusinessDiscarded = () => {
    const [ businessDiscarded, setBusinessDiscarded ] = React.useState([]);
    const [pagination, setPagination] = React.useState(1);

    const getBusinessDiscarded = React.useCallback(async (params = "") => {
        const { data } = await api.get(`/business/discarded?${params}&page=${pagination}&limit=10`)
        setBusinessDiscarded(data)
    }, [pagination])

    const onNextPage = () => {
        setPagination(previous => previous + 1)
      }
    
    const onPreviousPage = () => {
        setPagination(previous => previous - 1)
    }

    const getEnterprises = (inputValue, callback, url) => {
        api.get('enterprises')
        .then((response) => {
        const options = []
        response.data.forEach((option) => {
            options.push({
            label: option.corporateName || option.name,
            value: option._id
            })
        })


        let filterOptions = options.filter(o =>
            Object.keys(o).some(k => o[k].toLowerCase().includes(inputValue.toLowerCase())));
            
        callback(filterOptions);
        })
    }

    React.useEffect(() => {
        getBusinessDiscarded()
    }, [getBusinessDiscarded])

    return  { businessDiscarded, getBusinessDiscarded, getEnterprises, onNextPage, onPreviousPage, pagination }
    }