import api from "api";
import React from "react";
import NotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Card, CardBody, CardFooter, Col, FormGroup, Input, Label, Row } from "reactstrap";

const CreateStand = () => {
    const [name, setName] = React.useState("");
    const [enterprises, setEnterprises ] = React.useState([]);
    const [enterprise, setEnterprise ] = React.useState("");

    const history = useHistory();

    const notificationAlertRef = React.useRef(null);

  const getEnterprises = async () => {
    const { data } = await api.get("/enterprises")
    setEnterprises(data);
  }

  const onSubmit = async () => {
    let options = {};
    try {
      await api.post(`/stands`, {
        name,
        enterprise
      });

  
      options = {
        place: "tr",
        message: "Stand registrado com sucesso!",
        type: "success",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7
      };
      
    } catch(err) {
      options = {
        place: "tr",
        message: err.response.data.errors[0],
        type: "danger",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7
      };
    }

    notificationAlertRef.current.notificationAlert(options);  
    if (options.type === 'success') {
      setTimeout(() => {
        history.push("/admin/stands");
      }, 2000)
    }
  }

  React.useEffect(() => {
    getEnterprises()
  }, [])

    return (
        <>
        <div className="content"  style={{ width: "100%"}}>
          <div className="rna-container">
            <NotificationAlert ref={notificationAlertRef} />
          </div>
              <Card>
                <CardBody>
                    <Row>
                        <Col sm="5">
                        <FormGroup>
                            <Label for="name">Nome</Label>
                            <Input 
                            name="name"
                            placeholder="Informe seu nome..."
                            id="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            />
                        </FormGroup>
                        </Col>
                        <Col sm="5">
                        <FormGroup>
                            <Label for="enterprises">Empreendimento</Label>
                            <Input 
                            name="enterprises"
                            id="enterprises"
                            type="select"
                            value={enterprise}
                            onChange={(e) => setEnterprise(e.target.value)}
                            >
                                <option>
                                    Empreendimento
                                </option>
                                {
                                    enterprises && enterprises.map(({name, _id}, index) => (
                                    <option key={index} value={_id}>
                                        {name}
                                    </option>
                                    ))
                                }
                            </Input>
                        </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    <Button className="btn btn-fill" onClick={() => onSubmit()}>Salvar</Button>
                </CardFooter>
              </Card>
        </div>
      </>
    )
}

export default CreateStand;