import React from "react"
import { DragDropContext } from "react-beautiful-dnd"
import NotificationAlert from "react-notification-alert";
import api from "api";
import { useHistory } from "react-router-dom";
import { ModalScheduling } from "../ModalScheduling"
import { useAuth } from "context/AuthContext";
import { StatusColumn } from "./StatusColumn";

const PanelCardContainer = ({ data, isLoading, queryParams, filterParams }) => {
    const [columns, setColumns] = React.useState([])  
    const [isOpenModal, setIsOpenModal] = React.useState(false)
    const [title, setTitle] = React.useState("")
    const [clientId, setClientId] = React.useState("")
    const [ sourceStatus, setSourceStatus] = React.useState({
        status: "",
        item: "",
        destination: "",
    })

    const { user } = useAuth();
    const notificationAlertRef = React.useRef(null);

    const history = useHistory();

    const moveTheCardPermission = (role) => {
        let options = {};
        const Roles = {
            ADMIN: true,
            VP_COMMERCIAL: true,
            COMMERCIAL_DIRECTOR: true,
            MANAGER_POS: true,
            default: false,
        }
        
        if (!Roles[role]) {
            options = {
                place: "tr",
                message: `Você não possui permissão de alteraraçao de status para essa etapa!`, 
                type: "danger",
                icon: "tim-icons icon-bell-55",
                autoDismiss: 7
            };
            notificationAlertRef.current.notificationAlert(options);
            return;
        }

        return Roles[role]
    }

    const returnCardOnSourcePosition  = async (item, status, destination) => {

        var copyColumns = JSON.parse(JSON.stringify(columns))
        const sourceColumn = copyColumns.find((column) => column.status === status)
        sourceColumn.cards.unshift(item)


        const destinationColumnItems = copyColumns.find(column => column.status === destination)
        destinationColumnItems.cards = destinationColumnItems.cards.filter(card => card.business._id !== item.business._id)

        setColumns(copyColumns)

        await api.patch(`/business/${item.business.client._id}`, {
            status
        })

    }

    const getSourceColumn = (result) => {
        let sourceIndex;
        let sourceColumnItems = []
        for (const i in columns) {
            if (columns[i].status === result.source.droppableId) {
                sourceColumnItems = columns[i].cards
                sourceIndex = i
                setSourceStatus(prev => ({
                    ...prev,
                    status: columns[i].status
                }))
            }
        }

        return {
            sourceIndex,
            sourceColumnItems
        }
    }

    const getDestinationColumn = (result) => {
        let destinationIndex;
        let destinationColumnItems = []
        for (const i in columns) {
            if (columns[i].status === result.destination.droppableId) {
                destinationColumnItems = columns[i].cards
                destinationIndex = i
                setSourceStatus(prev => ({
                    ...prev,
                    destination: columns[i].status
                }))
            }
        }

        return {
            destinationIndex,
            destinationColumnItems
        }
    }

    const removeActualItem = (sourceColumnItems, result) => sourceColumnItems.filter((item) => item.business._id === result.draggableId)
    
    const addItemOnNewColumn = (destinationColumnItems, destinationIndex, itemRemoved) => destinationColumnItems.splice(destinationIndex, 0, itemRemoved[0])
    const removeItemOnLastColumn = (sourceColumnItems, itemRemoved) => {
        const indexOfItemRemoved = sourceColumnItems.indexOf(itemRemoved[0])
        sourceColumnItems.splice(indexOfItemRemoved, 1)
    }
    const actionAfterDragByDestinationIndex = (destinationIndex, itemRemoved) => {
        const Actions = {
            1: () => {
                setTitle("Previsão Agendamento")
                setIsOpenModal(true)
            },
            2: () => {
                const hasPermission = moveTheCardPermission(user.role.name, title);
                
                if (!!hasPermission === true) {
                    setTitle("Agendamento Efetivo")
                    setIsOpenModal(true)
                    return;
                }

                return false

            },
            3: () => {
                const hasPermission = moveTheCardPermission(user.role.name, title);

                if (!!hasPermission === true) {
                    setTitle("Outros Atendimentos")
                    setIsOpenModal(true)
                    return
                }

                return false
            },
            5: () => {
                setTimeout(() => {
                    history.push(`/admin/customerManagement/${itemRemoved[0].business.client._id}`);
                }, 1000)
            },
            4: () => {},
            0: () => {},
        }

        return Actions[destinationIndex]()

    }

    const populateInMemoryColumns = (sourceIndex, sourceColumnItems, destinationIndex, destinationColumnItems, itemRemoved) => {
        const totalVgvSource = columns[sourceIndex].vgv - itemRemoved[0].business.client.averageTicket
        const totalVgvDestination = columns[destinationIndex].vgv + itemRemoved[0].business.client.averageTicket

        let inMemoryColumns = JSON.parse(JSON.stringify(columns))
        inMemoryColumns[sourceIndex].cards = sourceColumnItems
        inMemoryColumns[sourceIndex].vgvFormatted = totalVgvSource.toLocaleString('pt-BR', {
            style: "currency",
            currency: 'BRL'
        })
        inMemoryColumns[sourceIndex].vgv = totalVgvSource

        inMemoryColumns[destinationIndex].cards = destinationColumnItems
        inMemoryColumns[destinationIndex].vgvFormatted = totalVgvDestination.toLocaleString('pt-BR', {
            style: "currency",
            currency: 'BRL'
        })
        inMemoryColumns[destinationIndex].vgv = totalVgvDestination

        return inMemoryColumns
    }

    const onDragEnd = async (result) => {

        const { sourceColumnItems, sourceIndex } = getSourceColumn(result)
        const { destinationIndex, destinationColumnItems } = getDestinationColumn(result)

        if (sourceIndex === destinationIndex) {
            return ;
        }
         
        const itemRemoved = removeActualItem(sourceColumnItems, result)
        setSourceStatus({
            status: result.source.droppableId,
            item: itemRemoved[0],
            destination: result.destination.droppableId,
        })
        const isMoved = actionAfterDragByDestinationIndex(destinationIndex, itemRemoved)
        
        if (isMoved === false) {
            return;
        }
        addItemOnNewColumn(destinationColumnItems, destinationIndex, itemRemoved)
        
        setClientId(itemRemoved[0].business.client._id)
        
        removeItemOnLastColumn(sourceColumnItems, itemRemoved)

       const inMemoryColumns = populateInMemoryColumns(sourceIndex, sourceColumnItems, destinationIndex, destinationColumnItems, itemRemoved)

        await api.patch(`/business/${itemRemoved[0].business.client._id}`, {
            status: result.destination.droppableId
        })

        setColumns(inMemoryColumns)
    }
    
    React.useEffect(() => {
        if (data) {
            setColumns(data)
        }
    }, [data])

    return (
        <div>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                { 
                    isLoading && 
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <h2>Carregando dados do painel...</h2>
                        </div>
                }    
                <div className="scroll" style={{ display: "flex", overflowX: 'auto' }}>
                    {
                        columns && columns.map((column) => (
                            <StatusColumn key={column?.status} column={column} queryParams={queryParams} filterParams={filterParams} isLoading={isLoading} />
                        ))
                    }
                </div>
            </DragDropContext>
            <ModalScheduling
                isOpen={isOpenModal}
                title={title}
                setToggleModal={() => setIsOpenModal(!isOpenModal)}
                returnCard={() => returnCardOnSourcePosition(sourceStatus.item, sourceStatus.status, sourceStatus.destination)}
                clientId={clientId}
            />
        </div>
    )
}

export default PanelCardContainer
