import { Modal, ModalBody } from "reactstrap"
import { UploadClientDocument } from "./UploadClientDocument"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import React from "react";

export const ModalUpload = (props) => {

    const { isOpen, setToggleModal, clientId, addHistoricalCard } = props;
   return (
        <Modal isOpen={isOpen} modalClassName="modal-black" size="lg">
            <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={setToggleModal}>
                <i className="tim-icons icon-simple-remove" ></i>
            </button>
            </div>
            <ModalBody>
                <UploadClientDocument clientId={clientId} addHistoricalCard={addHistoricalCard}/>
                <Link to={`/admin/documents/clients/${clientId}`}>Ver todos documentos do cliente</Link>
            </ModalBody>
        </Modal>    
   )
}