import React from "react";
import { ModalDeleteItem } from "views/components/ModalDeleteItem"
import { IconData } from "./IconData";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ModalBusiness } from "./ModalBusiness";
import { ModalUpload } from "./ModalUpload";
import { useRemoveClient } from "../hooks/useRemoveClient";

export const IconsSection = ({ addHistoricalCard }) => {
    const [toggleModalRemove, setToggleModalRemove] = React.useState(false);
    const [toggleModalBusiness, setToggleModalBusiness] = React.useState(false);
    const [toggleModalUpload, setToggleModalUpload] = React.useState(false);

    const history = useHistory();


    const { removeData } = useRemoveClient();

    const { clientId } = useParams()

    const deleteClient = async () => {
        const status = await removeData(clientId)
        if (status === 200) {
            setToggleModalRemove(false)
            setTimeout(() => history.push("/admin/businessPanel"), 1500)
        }
    }

    const iconsData = [
        {
            bgIcon: "rgb(253, 132, 121)",
            icon: "fa-regular fa-copy tooltip-icon-1",
            className: "icon-component-1",
            children: "Anexar Arquivo",
            setToggleModal: () => setToggleModalUpload(true),
        },
        {
            bgIcon: "#9F0601",
            icon: "fa-solid fa-trash tooltip-icon-2",
            className: "icon-component-2",
            children: "Descartar Cliente",
            setToggleModal: () => setToggleModalRemove(true),
        },
        {
            bgIcon: "#304069",
            icon: "fa-solid fa-handshake tooltip-icon-3",
            className: "icon-component-3",
            children: "Fechar Negócio",
            setToggleModal: () => setToggleModalBusiness(true),
        },
    ]

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}
            >
                {
                    iconsData.map((data, index) => (
                        <IconData
                            key={index}
                            bgIcon={data.bgIcon}
                            children={data.children}
                            icon={data.icon}
                            className={data.className}
                            setToggleModal={data.setToggleModal}
                        />
                    ))               
                }
            </div>
            <ModalDeleteItem
                title="Deseja realmente excluir este cliente?"
                isOpen={toggleModalRemove}
                setToggleModal={() => setToggleModalRemove(false)}
                removeData={deleteClient}
            />
            <ModalBusiness
                isOpen={toggleModalBusiness}
                setToggleModal={() => setToggleModalBusiness(false)}
                addHistoricalCard={addHistoricalCard}
            />
            <ModalUpload
                isOpen={toggleModalUpload}
                setToggleModal={() => setToggleModalUpload(false)}
                clientId={clientId}
                addHistoricalCard={addHistoricalCard}
            />
        </>
    )
}