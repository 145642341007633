import React from "react";
import ReactWizard from "react-bootstrap-wizard";
import { Col } from "reactstrap";

import api from "../../../../api/index";
import { useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import EditTower from "./EditTower.js";
import EditUnit from "./EditUnit.js";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min.js";
import EditEnterprise from "./Edit.js";

const steps = [
  {
    stepName: "Editar",
    stepIcon: "fa fa-building",
    component: EditEnterprise
  },
  {
    stepName: "Torres",
    stepIcon: "fa fa-building-user",
    component: EditTower,
  },
  {
    stepName: "Unidades",
    stepIcon: "fa fa-building-circle-check",
    component: EditUnit
  },
];

const WizardEditEnterprise = () => {
    const { params } = useRouteMatch()
    const notificationAlertRef = React.useRef(null);
    const history = useHistory();

    const finishButtonClick = async (allStates) => {
        const payload = {
        ...allStates.Editar,
        ...allStates.Torres,
        ...allStates.Unidades
        }
        let options = {};
        const { data, status } = await api.patch(`/enterprises/${params.enterpriseId}`, payload);
        if(status !== 201) {
        options = {
            place: "tr",
            message: data,
            type: "danger",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
    }
  
    options = {
        place: "tr",
        message: "Empreendimento registrado com sucesso!",
        type: "success",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7
    };
  
    notificationAlertRef.current.notificationAlert(options);  
    setTimeout(() => {
      history.push("/admin/enterprises");
    }, 3000)
  };
  return (
    <>
      <div className="content">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Col className="mr-auto ml-auto" md="8">
          <ReactWizard
            steps={steps}
            navSteps
            validate
            title="Editar Empreendimento"
            description="Estas informações nos permitirá saber mais sobre o negócio."
            headerTextCenter
            finishButtonClasses="btn-wd btn-info"
            nextButtonClasses="btn-wd btn-info"
            previousButtonClasses="btn-wd"
            nextButtonText="Próximo"
            previousButtonText="Anterior"
            finishButtonText="Finalizar"
            progressbar
            color="red"
            finishButtonClick={finishButtonClick}

          />
        </Col>
      </div>
    </>
  );
};

export default WizardEditEnterprise;
