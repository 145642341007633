import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

async function refreshToken() {
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      const header = {
        'Content-Type': 'application/json',
      };
      const parameters = {
        method: 'POST',
        headers: header,
      };
      const body = { refreshToken };

      const { data } = await api.post('/auth/refresh', body, parameters);
      return data;
    } catch (e) {
      throw new Error(e.getMessage());
    }

}
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if(originalRequest.url != "/auth/login" && error.response) {
      if (error.response?.status === 401 &&  !originalRequest._retry) {
        originalRequest._retry = true;
       try {
        const response = await refreshToken();
        api.defaults.headers.common['Authorization'] = 'Bearer ' + response.token;
        localStorage.setItem('token', response.token);
        localStorage.setItem('refreshToken', response.refreshToken);
        localStorage.setItem('currentUser', JSON.stringify(response.user));
        return api(originalRequest);
       } catch (error) {
          localStorage.removeItem('token')
          localStorage.removeItem('refreshToken')
          localStorage.removeItem('currentUser')
       }

      }
    } 
    return Promise.reject(error);
  },
);

export default api;