import React from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import api from "api";

export const ConfirmMail = () => {
    const [ email, setEmail] = React.useState("")

    const notificationAlertRef = React.useRef(null);

    const history = useHistory();

    const onSendMail = async () => {
      let options = {};
      try {
        const { status } = await api.post(
          "/auth/forget-password",
          {
            email,
          }
        );

        if (status === 201) {
          options = {
            place: "tr",
            message: "Email enviado com sucesso!",
            type: "success",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
          };
        }
      } catch (err) {
        options = {
          place: "tr",
          message: err.response.data.message,
          type: "danger",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7,
        };
      }

      notificationAlertRef.current.notificationAlert(options);
      if (options.type === "success") {
        setTimeout(() => {
          history.push("/auth/login");
        }, 2000);
      }
    };
    
    return (
      <div className="content">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form">
              <Card className="card-login card-white" style={{ padding: 10 }}>
                <CardHeader className="position-relative">
                  <img
                    alt="..."
                    src={require("assets/img/performance_logo.png")}
                  />
                  <CardTitle tag="h1"> </CardTitle>
                </CardHeader>
                <CardBody>
                  <Label>Informe seu email</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className="mb-3"
                    color="danger"
                    size="lg"
                    onClick={onSendMail}
                  >
                    Enviar
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    );
}