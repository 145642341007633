import Users from "views/pages/Users";
import CreateUser from "views/pages/Users/CreateUser";
import Enterprises from "views/pages/Enterprises";
import Partners from "views/pages/Partners";
import Stands from "views/pages/Stands";
import CreateStand from "views/pages/Stands/Create";


export const operationDirectorRoutes = [
    {
        collapse: true,
        name: "Usuários",
        icon: "tim-icons icon-single-02",
        state: "pagesCollapse",
        views: [
          {
            path: "/users",
            name: "Listar usuários",
            mini: "U",
            component: Users,
            layout: "/admin"
          },
        ] 
      },
      {
        path: "/user/new",
        name: "Criar Usuários",
        component: CreateUser,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/new",
        name: "Criar Usuários",
        component: CreateUser,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/partners",
        name: "Parceiros",
        component: Partners,
        icon: "fa fa-briefcase",
        layout: "/admin",
      },
      {
        path: "/enterprises",
        name: "Empreendimentos",
        icon: "fa fa-building",
        component: Enterprises,
        layout: "/admin",
      },
      {
        path: "/stands",
        name: "Stands",
        icon: "tim-icons icon-badge",
        component: Stands,
        layout: "/admin"
      },
      {
        path: "/stand/new",
        name: "Criar Stand",
        component: CreateStand,
        layout: "/admin",
        invisible: true
      },
]