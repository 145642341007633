/*eslint-disable*/
import React, { useReducer } from "react";
import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination,
  useResizeColumns,
} from "react-table";
import classnames from "classnames";
import { matchSorter } from "match-sorter";
import Select from "react-select";
import { FormGroup, Input, Row, Col } from "reactstrap";

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <FormGroup>
      <Input
        type="email"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Pesquisar ${count} registros...`}
      />
    </FormGroup>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data, paginationOptions }) {
  const [numberOfRows, setNumberOfRows] = React.useState(10);
  const [pageSelect, handlePageSelect] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true)

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    visibleColumns,
    previousPage,
    nextPage,
    pageOptions,
    pageCount,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageSize: 10, pageIndex: 0 },
    },
    useResizeColumns,
    useFilters, // useFilters!
    useSortBy,
    usePagination
  );

  React.useEffect(() => {
    setTimeout(() => {
      if (data.length >= 0) setIsLoading(false)
    }, 2000)
  }, [data])

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  let pageSelectData = Array.apply(
    null,
    Array(pageOptions.length)
  ).map(function () { });
  let numberOfRowsData = [5, 10, 20, 25, 50, 100];
  return (
    <>
      <div className="ReactTable -striped -highlight">
        {
          !isLoading &&data.length === 0 && (
            <div style={{ margin: '0 auto', padding: '30px 0', color: 'white'}}>Nenhum registro encontrado</div>
          )
        }
        {
          isLoading && (<div style={{ margin: '0 auto', padding: '30px 0', color: 'white'}}>Carregando Dados da tabela...</div>)
        }
        {
          !isLoading && data.length > 0 && (
            <table {...getTableProps()} className="rt-table">
              <thead className="rt-thead -header">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                    {headerGroup.headers.map((column, key) => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        className={classnames("rt-th rt-resizable-header", {
                          "-cursor-pointer": headerGroup.headers.length - 1 !== key,
                          "-sort-asc": column.isSorted && !column.isSortedDesc,
                          "-sort-desc": column.isSorted && column.isSortedDesc,
                        })}
                      >
                        <div className="rt-resizable-header-content">
                          {column.render("Header")}
                        </div>

                        {/* Render the columns filter UI */}
                        <div>
                          {headerGroup.headers.length - 1 === key
                            ? null
                            : column.canFilter
                              ? column.render("Filter")
                              : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="rt-tbody">
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={classnames(
                        "rt-tr",
                        { " -odd": i % 2 === 0 },
                        { " -even": i % 2 === 1 }
                      )}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()} className={classnames("rt-td", { "text-center": cell.column.alignCenter == true })}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )
        }
        <div className="pagination-bottom">
          <div className="-pagination">
            <div className="-previous">
              <button
                type="button"
                onClick={paginationOptions ? () => {
                  setIsLoading(true)
                  paginationOptions.onGoToPreviousPage()
                  setTimeout(() => {
                    setIsLoading(false)
                  }, 1300)
                } : (() => {
                  setIsLoading(true)
                  previousPage()
                  setTimeout(() => {
                    setIsLoading(false)
                  }, 1300)
                })}
                disabled={paginationOptions ? !paginationOptions.canGoToPreviousPage : !canPreviousPage}
                className="-btn"
              >
                Anterior
              </button>
            </div>
            <div className="-center flex-nowrap">

            </div>
            <div className="-next">
              <button
                type="button"
                onClick={paginationOptions ? () => {
                  setIsLoading(true)
                  paginationOptions.onGoToNextPage()
                  setTimeout(() => {
                    setIsLoading(false)
                  }, 1300)
                } : (() => {
                  setIsLoading(true)
                  nextPage()
                  setTimeout(() => {
                    setIsLoading(false)
                  }, 1300)
                })}
                disabled={paginationOptions ? !paginationOptions.canGoToNextPage : !canNextPage}
                className="-btn"
              >
                Próximo
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Table;
