import React from "react";
import ReactWizard from "react-bootstrap-wizard";

import { Col } from "reactstrap";
import RealEstate from "../../../../assets/img/icons/imobiliaria-rent.svg";
import RealEstateHouse from "../../../../assets/img/icons/house.svg";

import TypeSelection from '../TypeSelection.js';
import api from "../../../../api/index";
import { useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import CreateClient from "./CreateClient.js";

const options = [
    {
      key: "stand",
      title: "Stand",
      img: <img src={RealEstate}  className="img-select" alt="Imobiliaria" />,
    },
    {
      key: "enterprise",
      title: "Empreendimento",
      img: <img src={RealEstateHouse}  className="img-select" alt="House" />
    }
  ];

const WizardClient = () => {
  const [isDisabled, setIsDisabled] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const history = useHistory();

  const steps = [
    {
      stepName: "Tipo",
      stepIcon: "tim-icons icon-settings-gear-63",
      component: TypeSelection,
      stepProps: {
        options,
      }
    },
    {
      stepName: "Cadastrar",
      stepIcon: "tim-icons icon-single-02",
      component: CreateClient
    },
  ];

  const finishButtonClick = async (allStates) => {
    const payload = {
      ...allStates.Tipo,
      ...allStates.Cadastrar
    }
    let options = {};
   try {
    setIsDisabled(true)
    await api.post(`/clients`, payload);

    options = {
      place: "tr",
      message: "Cliente registrado com sucesso!",
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
   } catch (error) {
    options = {
      place: "tr",
      message: error.response.data.errors[0],
      type: "danger",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
   } finally {
    setTimeout(() => {
      setIsDisabled(false)
    }, 2000)
   }
  
    notificationAlertRef.current.notificationAlert(options); 
     
    if (options.type === "success") {
      setTimeout(() => {
        history.push("/admin/clients");
      }, 3000)
    }
  };
  return (
    <>
      <div className="content">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Col className="mr-auto ml-auto" md="8">
          <ReactWizard
            steps={steps}
            navSteps
            title="Criar Cliente"
            description="Estas informações nos permitirá saber mais sobre você."
            headerTextCenter
            finishButtonClasses={`btn-wd btn-info ${isDisabled ? "disabled" : ""}`}
            nextButtonClasses="btn-wd btn-info"
            previousButtonClasses="btn-wd"
            nextButtonText="Próximo"
            previousButtonText="Anterior"
            finishButtonText={isDisabled ? "Carregando..." : "Finalizar"}
            progressbar
            color="red"
            finishButtonClick={finishButtonClick}
          />
        </Col>
      </div>
    </>
  );
};

export default WizardClient;
