import { useAuth } from "context/AuthContext"

export const HasPermission = (permission, othersConditions) => {
    const { user } = useAuth();

    if (othersConditions) {
        const condition = othersConditions;
        if (user.role.name === "VP_COMMERCIAL") {
            return condition.vpCommercial
        }

        if (user.role.name === "MANAGER_POS") {
            return condition.managerPos
        }
    }

    return  user.role.name === "ADMIN" || user.role.permissions.includes(`performance.${permission}`);
} 