import api from "../../api";
import React, { useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label
} from "reactstrap";
import moment from "moment";
import Datetime from "react-datetime";
import cep from "cep-promise";
import ImageUpload from "components/CustomUpload/ImageUpload";
import { useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { tz } from 'moment-timezone';

const User = () => {
  const [avatarProfile, setAvatarProfile] = React.useState("");
  const [avatarUrl, setAvatarUrl] = React.useState("");
  const [name, setName] = React.useState("");
  const [role, setRole] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [documentNumber, setDocumentNumber] = React.useState("");
  const [dateBirth, setDateBirth] = React.useState("");
  const [schooling, setSchooling] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [neighborhood, setNeighborhood] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [identify, setIdentify] = React.useState("");

  const notificationAlertRef = React.useRef(null);
  const history = useHistory();

  const myUploader = (data) => {
    setAvatarUrl(data.Location);
    setAvatarProfile(data.Location);
  }

  async function handleFindAdrress(number) {
    if(number.length > 7) {
      try {
        let { street, neighborhood, city, state, cep: numberCep } = await cep(number);
        setZipCode(numberCep);
        setStreet(street);
        setNeighborhood(neighborhood);
        setCity(city);
        setState(state);
      } catch(error) {
        if (error instanceof Error) {
          console.log(error)
        }
      }
    }
  }


  const getData = async () => {
    const { data: {user} } = await api.get(`/profile`);
    const tzDateBirthFormat = moment(user.dateBirth)
    const tzDateBirth = tzDateBirthFormat.tz('America/Brazil').add(3, 'h')
    setAvatarProfile(user.avatarUrl);
    setName(user.name)
    setRole(user.role._id);
    setEmail(user.email);
    setDateBirth(tzDateBirth);
    setZipCode(user.zipCode);
    setStreet(user.street);
    setNeighborhood(neighborhood);
    setCity(user.city);
    setPhoneNumber(user.phoneNumber);
    setState(user.state);
    setNumber(user.number);
    setNeighborhood(user.neighborhood);
    setDocumentNumber(user.documentNumber);
    setSchooling(user.schooling)
    setIdentify(user._id);
  };

  const handleSubmit = async () => {
    const { data, status  } = await api.patch(`/users/${identify}`, {
      name,
      email,
      documentNumber,
      phoneNumber,
      dateBirth,
      schooling,
      zipCode,
      street,
      number,
      neighborhood,
      state,
      city,
      avatarUrl
    });

    let options = {};

    if(status !== 201) {
      options = {
        place: "tr",
        message: data,
        type: "danger",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7
      };
    }

    options = {
      place: "tr",
      message: "Usuário modificado com sucesso!",
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };

    notificationAlertRef.current.notificationAlert(options);  
    setTimeout(() => {
      history.push("/admin/users");
    }, 2000)
  }

  useEffect(() => {
    getData()
  }, []);
  
  return (
    <>
      <div className="content">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Editar Perfil</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                      <Label for="name">Nome</Label>
                      <Input 
                        name="name"
                        placeholder="Informe seu nome..."
                        id="name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="6">
                      <FormGroup>
                      <Label for="email">E-mail</Label>
                      <Input 
                        name="email"
                        placeholder="Informe seu e-mail..."
                        id="email"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <Label for="document-number">CPF</Label>
                        <Input 
                          name="documentNumber"
                          placeholder="Informe o CPF..."
                          id="document-number"
                          type="text" 
                          value={documentNumber}
                          onChange={(e) => setDocumentNumber(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                      <Label for="date-birth">Data de nascimento</Label>
                      <Datetime
                        timeFormat={false}
                        locale="pt-br"
                        dateFormat="DD/MM/YYYY"
                        input={true}
                        value={dateBirth}
                        id="date-birth"
                        inputProps={{placeholder:"Clique para informar sua data de nascimento..."}}
                        onChange={(date) => setDateBirth(moment(date._d).format("YYYY-MM-DD"))}
                      />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                      <Label for="escolaridade">Escolaridade</Label>
                      <Input 
                        name="schooling"
                        id="escolaridade"
                        placeholder="Escolaridade"
                        type="select"
                        value={schooling}
                        onChange={(e) => setSchooling(e.target.value)}
                      >
                        <option>
                          Selecione a escolaridade...
                        </option>
                        <option value={"CompleteHighSchool"}>  
                          Ensino Médio Completo
                        </option>
                        <option value={"IncompleteHighSchool"}>
                          Ensino Médio Incompleto
                        </option>
                        <option value={"CompleteHigherEducation"}>
                          Ensino Superior Completo
                        </option>
                        <option value={"IncompleteHigherEducation"}>
                          Ensino Superior Incompleto
                        </option>
                        <option value={"CompletePrimaryEducation"}>
                          Ensino Fundamental Completo
                        </option>
                        <option value={"IncompletePrimaryEducation"}>
                          Ensino Fundamental Incompleto
                        </option>
                      </Input>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label for="zip-code">CEP</Label>
                        <Input 
                          name="zipCode"
                          placeholder="Informe o CEP..."
                          id="zip-code"
                          type="text"
                          value={zipCode}
                          onChange={(e) => handleFindAdrress(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="5">
                    <FormGroup>
                      <Label for="street">Rua</Label>
                      <Input 
                        name="street"
                        placeholder="Informe a Rua..."
                        id="street"
                        value={street}
                        type="text"
                        onChange={(e) => setStreet(e.target.value)}
                      />
                    </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="3">
                    <FormGroup>
                      <Label for="number">Número</Label>
                      <Input 
                        name="number"
                        placeholder="Informe o Número..."
                        type="text"
                        value={number}
                        id="number"
                        onChange={(e) => setNumber(e.target.value)}
                      />
                    </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                    <FormGroup>
                      <Label for="neighborhood">Bairro</Label>
                      <Input 
                        name="neighborhood"
                        placeholder="Informe o Bairro..."
                        id="neighborhood"
                        type="text"
                        value={neighborhood}
                        onChange={(e) => setNeighborhood(e.target.value)}
                      />
                    </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label for="telephone">Telefone</Label>
                        <Input 
                          name="phoneNumber"
                          placeholder="Informe o Telefone..."
                          id="telephone"
                          value={phoneNumber}
                          type="text"
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label for="city">Cidade</Label>
                        <Input 
                          name="city"
                          placeholder="Informe a Cidade..."
                          id="city"
                          type="text"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label for="state">Estado</Label>
                        <Input 
                          name="state"
                          placeholder="Informe o Estado..."
                          id="state"
                          type="text"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="text-center">
                  <Col sm="12">
                    <ImageUpload 
                      avatar={true}   
                      file={avatarProfile}   
                      changeBtnClasses="btn-simple"
                      addBtnClasses="btn-simple"
                      removeBtnClasses="btn-simple"
                      uploadHandler={myUploader}
                    />
                  </Col>
                </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" onClick={() => handleSubmit()}>
                  Salvar
                </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar"
                      src={avatarProfile ? avatarProfile : require("assets/img/default-avatar.png")}
                    />
                    <h5 className="title">{name}</h5>
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default User;
