import React from "react";
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap"
import { TableHeader } from "views/components/TableHeader";
import ReactTable from "components/ReactTable/ReactTable.js";
import { FilterTable } from "views/components/FilterTables";
import { useForm } from "react-hook-form";
import { filterByUrlParams } from "variables/filter-by-url-params";
import { useBusinessClose } from "../CustomerManagement/hooks/useCloseBusiness";

export const BusinessClosed = () => {

    const { businessClosed, getBusinessClosed } = useBusinessClose();

    const { handleSubmit, register, setValue, getValues } = useForm();

    const onSubmit = async (e) => {
        const params = filterByUrlParams(e);

        getBusinessClosed(params)
    }

    const onChangeText = (e, field) => {
        setValue(field, `*${e.target.value}`)
        const params = getValues();
        if (e.target.value.length > 2 || e.target.value.length === 0) {
            onSubmit(params)
        }
    }


    return (
        <>
            <div className="content">
                <Row className="mt-5">
                    <Col xs={12} md={12}>
                        <FilterTable onSubmit={handleSubmit((e) => onSubmit(e))}>
                            <FormGroup>
                                <Row>
                                    <Col sm="4">
                                        <Label for="name">Nome</Label>
                                        <Input
                                            type="text"
                                            name="name"
                                            placeholder="Nome"
                                            id="name"
                                            {...register('name')}
                                            onChange={(e, _) => onChangeText(e, 'name')}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </FilterTable>
                        <Card>
                            <TableHeader
                                title="Negócios Fechados"
                            />
                            <CardBody>
                                {
                                    businessClosed && (
                                        <ReactTable
                                            data={businessClosed}
                                            filterable
                                            columns={[
                                                {
                                                    Header: "Nome",
                                                    Cell: data => {
                                                        return (
                                                            <p>{data.row.original.client.name}</p>
                                                        )
                                                    }
                                                },
                                                {
                                                    Header: "Empreendimento",
                                                    Cell: data => {
                                                        return (
                                                            <p>{data.row.original.enterprise.name}</p>
                                                        )
                                                    }
                                                },
                                                {
                                                    Header: "Valor",
                                                    Cell: data => {
                                                        const valueOnCent = data.row.original.closedValue;
                                                        const valueOnBRL = valueOnCent / 100;

                                                        const valorFormatado = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valueOnBRL);
                                                        return (
                                                            <p>{valorFormatado}</p>
                                                        )
                                                    }
                                                },
                                                {
                                                    Header: "Ações",
                                                    sortable: false,
                                                    filterable: false,
                                                    Cell: data => (
                                                        <div className="actions-right">

                                                        </div>
                                                    )
                                                }
                                            ]}
                                            defaultPageSize={10}
                                            className="-striped -highlight"
                                        />
                                    )
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}