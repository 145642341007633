import React from "react"

export const useGlobalComponentState = () => {
    const [ isHistory, setIsHistory ] = React.useState(true)
    const [ isActivity, setIsActivity ] = React.useState(false)
    

    const onChangeHistory = () => {
        if (isHistory) return;

        setIsHistory(!isHistory)
        setIsActivity(false)
    }

    const onChangeActivity = () => {
        if (isActivity) return;
        
        setIsActivity(!isActivity)
        setIsHistory(false)

    }

    return {
        isHistory,
        isActivity,
        onChangeHistory,
        onChangeActivity
    }
}