export const translateStatus = (value) => {
    switch (value) {
        case "Prospection":
            return "Prospecção";
        case "Scheduling Prevision":
            return "Previsão Agendamento";
        case "Effective Scheduling":
            return "Agendamento Efetivo";
        case "Others Services":
            return "Outros Atendimentos";
        case "Treatment":
            return "Tratativa";
        case "Proposal":
            return "Proposta";
        default:
            return ""
    }
}