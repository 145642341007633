import React from "react"
import { BarChart } from "@mui/x-charts-pro"
import { Button, Card, CardFooter, CardHeader } from "reactstrap"
import { HasPermission } from "variables/permissions"
import { CountCard } from "./CountCard"
import { ContactSupport, HowToReg, Person, PersonOff } from "@mui/icons-material"
import { UseScreenWidth } from "variables/screen-width"
import { Captions } from "./Captions"
import { translateStatus } from "variables/translateStatus"



export const BarCharts = (props) => {
  
    const {
      onOpenReportModal, 
      statistics,
      captions
    } = props

    const counters = [
      {
        title: 'Clientes Ativos',
        value: statistics?.dashboard?.clientsActives?.total || 0,
        icon: <HowToReg htmlColor="#2083F7" />
      },
      {
        title: 'Clientes em Risco',
        value: 3,
        icon: <ContactSupport htmlColor="#2083F7" />
      },
      {
        title: 'A Vencer em 24 horas',
        value: 2,
        icon: <PersonOff htmlColor="#2083F7" />
      },
      {
        title: 'Corretores',
        value: statistics?.dashboard?.BROKER?.total || 0,
        icon: <Person htmlColor="#2083F7" />
      },
      {
        title: 'Gerentes',
        value: statistics?.dashboard?.MANAGER?.total || 0,
        icon: <Person htmlColor="#2083F7" />
      },
      {
        title: 'Diretores Gerais',
        value: statistics?.dashboard?.GENERAL_DIRECTOR?.total || 0,
        icon: <Person htmlColor="#2083F7" />
      },
      {
        title: 'Diretores de Vendas',
        value: statistics?.dashboard?.SALES_DIRECTOR?.total || 0,
        icon: <Person htmlColor="#2083F7" />
      },
      {
        title: 'Diretores de Marketing',
        value: statistics?.dashboard?.MARKETING_DIRECTOR?.total || 0,
        icon: <Person htmlColor="#2083F7" />
      },
      {
        title: 'Diretores de Operações',
        value: statistics?.dashboard?.OPERATION_DIRECTOR?.total || 0,
        icon: <Person htmlColor="#2083F7" />
      },
      {
        title: 'Diretores Comerciais',
        value: statistics?.dashboard?.COMMERCIAL_DIRECTOR?.total || 0,
        icon: <Person htmlColor="#2083F7" />
      },
      {
        title: 'Incorporadores',
        value: statistics?.dashboard?.incorporators?.total || 0,
        icon: <Person htmlColor="#2083F7" />
      },
      {
        title: 'Imobiliárias',
        value: statistics?.dashboard?.realStates?.total || 0,
        icon: <Person htmlColor="#2083F7" />
      },
      {
        title: 'Usuario Operações',
        value: statistics?.dashboard?.OPERATION?.total || 0,
        icon: <Person htmlColor="#2083F7" />
      },
      {
        title: 'Usuario Atendimento',
        value: statistics?.dashboard?.INCORPORATION_SERVICE?.total || 0,
        icon: <Person htmlColor="#2083F7" />
      },
      {
        title: 'VP Comerciais',
        value: statistics?.dashboard?.VP_COMMERCIAL?.total || 0,
        icon: <Person htmlColor="#2083F7" />
      },
      {
        title: 'VP Negócios',
        value: statistics?.dashboard?.VP_BUSINESS?.total || 0,
        icon: <Person htmlColor="#2083F7" />
      },
      {
        title: 'Gestores de PDV',
        value: statistics?.dashboard?.MANAGER_POS?.total || 0,
        icon: <Person htmlColor="#2083F7" />
      },
      {
        title: 'Empreendimentos',
        value: statistics?.dashboard?.enterprises?.total || 0,
        icon: <Person htmlColor="#2083F7" />
      },
      {
        title: 'Usuarios Marketing',
        value: statistics?.dashboard?.MARKETING?.total || 0,
        icon: <Person htmlColor="#2083F7" />
      },
      {
        title: 'Coordenadores',
        value: statistics?.dashboard?.COORDINATOR_PRODUCTS?.total || 0,
        icon: <Person htmlColor="#2083F7" />
      },
  ]
  
    const orderStatus = () => {
      const status = new Map([
        ["Prospection"],
        ["Scheduling Prevision"],
        ["Effective Scheduling"],
        ["Others Services"],
        ["Treatment"],
        ["Proposal"], 
      ])

      statistics?.chart?.forEach(data => {
        if (data.status) {
          status.set(data.status, translateStatus(data.status))
        }
      })

      const statusArray = Array.from(status).map(([_, value]) => value)
      
      return statusArray.filter(status => status !== undefined)
    }
 
    const { screenWidth } = UseScreenWidth()
    
    return (
        <Card style={{ padding: 20, minHeight: '60dvh'}}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 12}}>
              {
                HasPermission('report.filter.view') && (
                  <Button onClick={onOpenReportModal}>Gerar Relatorio</Button>
                )
              }
            </div>
          <CardHeader  style={{ display: 'flex', justifyContent: screenWidth > 1280  ? "space-between" : "center", alignItems: 'center', columnGap: 20, rowGap: 20,  flexWrap: 'wrap', marginBottom: 35}}>
            {counters.map((data, index) => <CountCard key={index} title={data.title} value={data.value} icon={data.icon} />)}
          </CardHeader> 
          <Captions captions={captions} />
          <BarChart 
            className="bar-charts"
            slotProps={{
              noDataOverlay: {
                message: "Não há dados para serem exibidos",
                fill: '#fff',
                  color: '#fff'
              },
              loadingOverlay: {
                message: "Carregando dados do dashboard...",   
                fill: '#fff',
                  color: '#fff',
                          
              }
            }}    
            xAxis={[
              {
                data: orderStatus(),
                tickLabelStyle: {
                  fill: '#fff',
                  color: '#fff',
                  fontSize: 10,
                  wordBreak: "break-word",
                  letterSpacing: 1,
                  fontWeight: 'normal',
                  display: screenWidth > 1050 ? 'block' : 'none',
                },
                scaleType: 'band',
                categoryGapRatio: 0.8,
                color: "#2083F7",
              },
            ]}
            yAxis={[
              {
                tickLabelStyle: {
                  fill: '#fff',
                  color: '#fff'
                },
                scaleType: 'linear'
              }
            ]}
            series={[
              {
                data: statistics.chart ? statistics.chart.map(data => data.total) : [],
                highlightScope: {
                  highlight: 'item',
                },
              },
            ]}
            height={300}
            colors={['#E66AD2']}
        />
      </Card>
    )
}