import { Droppable } from "react-beautiful-dnd"
import { Card } from "reactstrap"
import PanelCardHeader from "./PanelCardHeader"
import PanelCardBody from "./PanelCardBody"
import React from "react"

export const StatusColumn = ({column, queryParams, filterParams, isLoading}) => {
    const [ vgv, setVgv ] = React.useState(0)
    const [ cardsLength, setCardsLength ] = React.useState(0)
    
    const translateColumnName = (value) => {

        const Status = {
            Prospection: "Prospecção",
            "Scheduling Prevision": "Previsão de Agendamento",
            "Effective Scheduling": "Agendamento Efetivo",
            "Others Services": "Outros Atendimentos",
            Treatment: "Trativa",
            Proposal: "Proposta",
            default: null
        }

        return Status[value] || Status.default
    }

    React.useEffect(() => {
        setVgv(column.vgv)
        setCardsLength(column.cards.length)
    }, [column.vgv, column.cards])

    return(
       <>
            {
               !isLoading && (
                    <Droppable
                        droppableId={column?.status}
                        direction="vertical"
                        key={column?.status}
                    >
                        {(provided) => (
                            <Card className="mx-2" style={{ minWidth: 200 }}>
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    <PanelCardHeader
                                        step={translateColumnName(column?.status)}
                                        quantity={cardsLength}
                                        value={vgv}
                                    />
                                    <PanelCardBody 
                                        data={column?.cards} 
                                        queryParams={queryParams} 
                                        filterParams={filterParams} 
                                        setVgv={setVgv} 
                                        setCardsLength={setCardsLength}
                                    />
                                    {provided.placeholder}
                                </div>
                            </Card>
                        )}
                    </Droppable>
                )
            }
       </>
    )
}