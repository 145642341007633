import React from "react";

export const ButtonComponent = (props) => {
    const { value, active, onChange } = props;
    
    const [ isActive, setIsActive ] = React.useState(false)

    React.useEffect(() => {
        setIsActive(active)
    }, [active])

    return (
        <button
            style={{
                maxWidth: 250, 
                width: "100%",
                padding: "12px 8px",
                borderRadius: 30,
                backgroundColor: isActive ? "#D84FD3" : "#191F31",
                color: isActive ? "#fff" : "#6A6E7A",
                border: "none",
                marginBottom: 8,
                fontWeight: "bold"
            }}
            onClick={onChange}
        >
            {value}
        </button>
    )
}