import { Button, Modal, ModalBody, ModalFooter } from "reactstrap"

export const ModalDeleteItem = (props) => {

    const { 
        isOpen,
        title,
        setToggleModal,
        removeData
     } = props;

    return (
        <Modal isOpen={isOpen} modalClassName="modal-black">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={setToggleModal}>
            <i className="tim-icons icon-simple-remove"></i>
          </button>
          <h5 className="modal-title">{title}</h5>
        </div>
        <ModalBody>
            <p>Atenção! Essa ação é irrevessivel.</p>
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={setToggleModal}>
                Não
            </Button>
            <Button color="primary" onClick={removeData}>
                Sim
            </Button>
        </ModalFooter>
      </Modal>
    )
}