export const filterByUrlParams = (data, parentKey = "") => {
  const params = Object.keys(data)
    .map((key) => {
      const value = data[key];
      const newKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof value === "object" && value !== null) {
        return filterByUrlParams(value, newKey);
      }

      if (value != null) {
        return [encodeURIComponent(newKey), encodeURIComponent(value)].join(
          "="
        );
      }

      return null;
    })
    .filter(Boolean)
    .join("&");

  return params;

}; 
  
